import api from "../../api";

const calendars = (params) => api.get("/calendars", { params });

export const updateCalendar = (id, params) =>
  api.put("/calendars/" + id, params);

export const schedule = (params) => api.post("/calendars", params);

export default calendars;
