import {
  Teams,
  ActiveLog,
  DashboardNew,
  RealTimeChatNew,
  ChatRecordsNew,
  ReportsNew,
  AgentNew,
  TelephonePlus
} from '../assets/icons/jsxIcons/index';

export const sidebarData = [
  {
    title: 'Dashboard',
    route: 'DASHBOARD',
    Icon: DashboardNew,
    index: 1
  },
  {
    title: 'Real Time Chat',
    route: 'REAL_TIME_CHAT',
    Icon: RealTimeChatNew,
    index: 2
  },
  {
    title: 'Call Requests',
    route: 'CALL_REQUESTS',
    Icon: TelephonePlus,
    index: 3
  },
  {
    title: ' Chat Records',
    route: 'CHAT_RECORDS',
    Icon: ChatRecordsNew,
    index: 4
  },
  {
    title: 'Reports',
    route: 'REPORTS',
    Icon: ReportsNew,
    index: 5
  },
  {
    title: 'Agents',
    route: 'AGENTS',
    Icon: AgentNew,
    index: 6
  },
  {
    title: 'Teams',
    route: 'TEAM',
    Icon: Teams,
    index: 7
  },
  {
    title: 'Activity Logs',
    route: 'ACTIVITY_LOGS',
    Icon: ActiveLog,
    index: 8
  }
];
