import React, { useEffect, useContext, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import AppRoute from './AppRoute';
import { AuthContext } from '../contexts/auth';
import Dashboard from './Dashboard';
import RealTimeChat from './RealTimeChat';
import ChatRecord from './ChatRecord';
import RealTimeVisitors from './RealTimeVisitors';
import Reports from './Reports';
import Agents from './Agents';
import Triggers from './Triggers';
import Settings from './Settings';
import Team from './Team';
import Login from './Login';
import ChatRecords from './ChatRecords';
import ActivityLog from './ActivityLog';
import Profile from './Profile';
import PublicChat from './PublicChat';
import PublicChatWidget from './PublicChatWidget';
import routes from '../constants/routes';
import * as jwt from '../utils/jwt';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import ResetPassword from '../ResetPassword/ResetPassword';
import Calendar from './Calendar';
import { useSocket } from '../utils/hooks';
import { gapi } from 'gapi-script';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function App() {
  const { authDispatch } = useContext(AuthContext);
  const { socket } = useSocket();

  const token = useMemo(() => localStorage.getItem('token'), []);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId,
        scope: ''
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  useEffect(() => {
    const decoded = jwt.decode(token);

    if (token) {
      authDispatch({
        type: 'SET_LOGIN',
        payload: decoded
      });
    }
  }, [token]);

  useEffect(() => {
    if (token && socket.connected) {
      const user = JSON.parse(localStorage.getItem('profile'));

      user && socket.emit('init', { user: user?.user?.id || user?.user });
    }
  }, [socket.connected, token]);

  return (
    <>
      <Switch>
        <AppRoute exact path={routes.REAL_TIME_CHAT} component={RealTimeChat} />
        <AppRoute exact path={routes.DASHBOARD} component={Dashboard} />
        <AppRoute exact path={routes.CHAT_RECORDS} component={ChatRecords} />
        <AppRoute exact path={routes.REAL_TIME_VISITORS} component={RealTimeVisitors} />
        <AppRoute exact path={routes.REPORTS} component={Reports} />
        <AppRoute exact path={routes.AGENTS} component={Agents} />
        <AppRoute exact path={routes.TRIGGERS} component={Triggers} />
        <AppRoute exact path={routes.SETTINGS} component={Settings} />
        <AppRoute exact path={routes.ACTIVITY_LOGS} component={ActivityLog} />
        <AppRoute exact path={routes.TEAM} component={Team} />
        <AppRoute exact path={routes.CHAT_RECORD} component={ChatRecord} />
        <AppRoute noHeader exact path={routes.PROFILE} component={Profile} />
        <AppRoute exact path={routes.CALL_REQUESTS} component={Calendar} />
        <Route exact path={routes.LOGIN} component={Login} />
        <Route exact path={routes.PUBLIC_CHAT} component={PublicChat} />
        <Route exact path={routes.PUBLIC_CHAT_WIDGET} component={PublicChatWidget} />
        <Route exact path={routes.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route exact path={routes.RESET_PASSWORD} component={ResetPassword} />
        <Redirect to={routes.DASHBOARD} />
      </Switch>
    </>
  );
}

export default App;
