import {
  ActiveLog,
  AgentNew,
  DashboardNew,
  Teams,
  Settings,
  RealTimeChatNew,
  TelephonePlus,
  ChatRecordsNew,
  ReportsNew
} from '../assets/icons/jsxIcons/index';

export const superAdminData = [
  {
    title: 'Dashboard',
    route: 'DASHBOARD',
    Icon: DashboardNew
  },
  {
    title: 'Real Time Chat',
    route: 'REAL_TIME_CHAT',
    Icon: RealTimeChatNew,
  },
  {
    title: 'Call Requests',
    route: 'CALL_REQUESTS',
    Icon: TelephonePlus,
  },
  {
    title: ' Chat Records',
    route: 'CHAT_RECORDS',
    Icon: ChatRecordsNew,
  },
  {
    title: 'Reports',
    route: 'REPORTS',
    Icon: ReportsNew,
  },
  {
    title: 'Agents',
    route: 'AGENTS',
    Icon: AgentNew
  },
  {
    title: 'Teams',
    route: 'TEAM',
    Icon: Teams
  },

  {
    title: 'Activity Logs',
    route: 'ACTIVITY_LOGS',
    Icon: ActiveLog
  },
  {
    title: 'Settings',
    route: 'SETTINGS',
    Icon: Settings
  }
];
