import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import logo from '../assets/images/logo.png';
import './forgotPassword.css';
import routes from '../constants/routes';
import BackIcon from '../assets/icons/BackIcon';
import { toast } from 'react-toastify';
import { forgotPasswordReset } from '../utils/api/passwordReset';

const ForgotPassword = () => {
  const [sentEmail, setSentEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const forgotPassword = async (e) => {
    e.preventDefault();
    if (email === '') {
      return toast.error(`Email can't be empty`, { autoClose: 2000 });
    } else setLoading(true);
    try {
      await forgotPasswordReset({ email });

      setLoading(false);
      setSentEmail(true);
      setEmail('');

      toast.success('Email for forgot password reset has been sent', {
        autoClose: 2000
      });
    } catch (error) {
      toast.error('An error occurred', { autoClose: 2000 });
      setLoading(false);
    }
  };

  return (
    <div className='main-div'>
      <div className='position-relative card forgot-page py-4 px-5' style={{ borderRadius: '8px' }}>
        <span className='position-absolute top-0 start-0 login-link ' style={{ width: '25px' }}>
          <Link to={routes.LOGIN}>
            <BackIcon />
          </Link>
        </span>
        {sentEmail ? (
          <>
            <div className='text-center mt-5'>
              <img src={logo} alt='logo' width='250px' />
              <h4
                className='mx-3 text-center'
                style={{
                  color: '#042F47',
                  fontWeight: 'bold',
                  marginBottom: '2rem'
                }}>
                Success!!
              </h4>
              <p className='text-left'>
                A password reset link was successfully sent to your inbox. Please check your inbox
                or spam mail to retrieve the reset link.
              </p>
            </div>
            <span className='p-2 login-link text-left '>
              Didn't received Email?
              <span className='ml-2 font-weight-bold resend' onClick={() => setSentEmail(false)}>
                Resend
              </span>
            </span>
          </>
        ) : (
          <form onSubmit={forgotPassword}>
            <div className='mb-2 text-center'>
              <img src={logo} alt='logo' width='250px' />
              <h4
                className='mx-3'
                style={{
                  color: '#042F47',
                  fontWeight: 'bold',
                  marginBottom: '2rem'
                }}>
                Forgot your password?
              </h4>
              <p className='text-left '>
                Enter your registered email address below to receive password reset instructions.
              </p>
            </div>
            <div className='form-group'>
              <label htmlFor='email'>Enter your email</label>
              <input
                type='email'
                className='form-control'
                id='email'
                aria-describedby='emailHelp'
                placeholder='Enter email'
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <span className='p-2 mt-3 login-link '>
              Remember the password?
              <Link className='ml-2 font-weight-bold' to={routes.LOGIN}>
                Login
              </Link>
            </span>
            <div className='d-flex mt-5 align-items-center justify-content-center'>
              <button
                disabled={loading}
                type='submit'
                className='d-flex justify-content-center align-items-center btn forgot-btn-color p-2'>
                {loading ? <Spinner color='light' size='sm' /> : ''}
                <span className='ml-1'>Reset Password</span>
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
export default ForgotPassword;
