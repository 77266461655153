import React, { useMemo, useState } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import './style.css';
import routes from '../../constants/routes';
import { sidebarData } from '../../constants/sidebarData';
import { superAdminData } from '../../constants/superAdmin';
import { AgentData } from '../../constants/agentData';
import WHITE_Logo from '../../assets/images/WHITE__Logo.png';
import { useUser } from '../../utils/hooks/user';

const SideBar = ({ sideState, toggleSideBar, ...props }) => {
  const location = useLocation();
  const [hovered, setHovered] = useState(-1);
  const profileData = useUser();
  const roles = profileData?.roles;

  const sidebars = useMemo(() => {
    const roleObj = {};
    roles?.map((val) => (roleObj[val] = true));

    return roleObj['super-admin']
      ? superAdminData
      : roleObj.admin
      ? sidebarData
      : roleObj.user
      ? AgentData
      : [];
  }, [roles]);

  return (
    <div className='sidebar' style={{ width: sideState ? 'auto' : '215px', zIndex: 200 }}>
      {sideState ? (
        ''
      ) : (
        <div className=''>
          <a href='/' rel='noreferrer'>
            <img
              src={WHITE_Logo}
              alt='logo'
              width='120px'
              className='position-absolute'
              style={{ top: 12, left: 27 }}
            />
          </a>
        </div>
      )}
      <button
        className='sidebar-toggle'
        style={{ right: sideState ? '30%' : '10%' }}
        onClick={toggleSideBar}>
        <i className='ion-android-menu' />
      </button>

      <div className='sidebar-header' style={{ padding: sideState ? '0px 0px' : '0px' }}></div>

      <div className='sidebar-menu' style={{ margin: sideState && '1rem' }}>
        <ul>
          {sidebars.map(({ Icon, route, title }, index) => (
            <li
              className={`sidebar-item ${
                location.pathname === routes[route] ? 'sidebar-active' : ''
              }`}
              onMouseOver={() => setHovered(index)}
              onMouseLeave={() => setHovered(-1)}
              key={title}>
              <Link
                to={routes[route]}
                className='sidebar-link'
                style={{ paddingLeft: sideState ? '15px' : '20px' }}>
                <Icon />
                {sideState ? hovered === index && <div className='hovered'>{title}</div> : ''}
                <span
                  style={{ marginLeft: '10px' }}
                  className={classnames({ 'd-none': sideState })}>
                  {title}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default withRouter(SideBar);
