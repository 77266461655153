import { createContext } from 'react';
import { AuthProvider } from './auth';
import { UAParser } from 'ua-parser-js';

const ContextProvider = ({ children }) => {
  return <AuthProvider>{children}</AuthProvider>;
};

const parser = new UAParser();

const deviceContext = createContext(parser.getDevice());

const UnSupportedMobile = () => (
  <div className='d-flex flex-column justify-content-center align-items-center w-100 vh-100 p-2'>
    <img className='m-3' width='100rem' height='100rem' alt='sawport-logo' src='/favicon.ico' />
    <h2 className='my-2 text-center font-weight-bold'>Mobile browsers are not supported!</h2>
    <h3 className='my-2 text-center'>Please use desktop a desktop browser to access this site.</h3>
  </div>
);

export const DeviceProvider = ({ children }) => {
  const device = parser.getDevice();

  return (
    <deviceContext.Provider value={device}>
      {device.type === 'mobile' ? <UnSupportedMobile /> : children}
    </deviceContext.Provider>
  );
};

export default ContextProvider;
