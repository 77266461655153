import { DeveloperBoardOutlined, LockOutlined, WidgetsOutlined } from '@material-ui/icons';

export const SecurityPageData = [
  {
    title: 'Widget',
    Icon: WidgetsOutlined
  },
  {
    title: 'Security',
    Icon: LockOutlined
  },
  {
    title: 'SDK',
    Icon: DeveloperBoardOutlined
  }
];
