import React from 'react';
import useState from 'use-react-state';
import CodeBlockModal from './CodeBlockModal';
import TabWrapper from '../TabWrapper';
import MobileCodeBlockModal from './mobileCodeBlockModal';

export default function Sdk({ company }) {
  const [{ dialog, mobileDialog }, setState] = useState({
    dialog: false,
    company,
    mobileDialog: false
  });

  return (
    <TabWrapper tabName='SDK'>
      <p className='pt-4 pl-5' style={{ fontSize: '17px' }}>
        Here is a set of script templates to be generate for the widget
      </p>
      <div className='pt-4 pl-5 pb-5 sdk-wrapper'>
        <p className='pt-4 font-weight-bold'>Online Banking SDK</p>
        <button onClick={() => setState({ dialog: true })} className='btn btn-primary'>
          Open to copy SDK
        </button>
        {dialog && (
          <CodeBlockModal
            company={company}
            closeDialog={() => setState({ dialog: false })}
            dialog={dialog}
          />
        )}
        <p className='pt-4 mt-2 font-weight-bold'>Mobile Banking SDK(Android and IOS)</p>
        <button onClick={() => setState({ mobileDialog: true })} className='btn btn-primary'>
          Open to copy SDK
        </button>
        {mobileDialog && (
          <MobileCodeBlockModal
            company={company}
            closeMobileDialog={() => setState({ mobileDialog: false })}
            mobileDialog={mobileDialog}
          />
        )}
      </div>
    </TabWrapper>
  );
}
