import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import useMergeState from "use-react-state";
import useEventListener from "../react-listeners";
import { get } from "lodash";
import { useSocket as useBaseSocket } from "socket.io-react-hook";
const { REACT_APP_SOCKET_URL } = process.env;

export const useScroll = ({ onEndReached, onBeginReached } = {}) => {
  const ref = useRef();
  const values = useRef({
    isAtBottom: false,
    isStillAtBottom: false,
    isAtTop: false,
    isStillAtTop: false,
  });

  useEffect(() => {
    const conputeEnd = (event) => {
      const element = event.target;

      const offset = 5;
      values.current.isAtBottom =
        element.scrollTop >
        element.scrollHeight - element.offsetHeight - offset;

      if (values.current.isAtBottom && values.current.isStillAtBottom) {
        return;
      }

      if (values.current.isAtBottom && !values.current.isStillAtBottom) {
        values.current.isStillAtBottom = true;
        onEndReached && onEndReached(event);
      } else {
        values.current.isStillAtBottom = false;
      }
    };

    const conputeTop = (event) => {
      const element = event.target;

      const offset = 5;
      values.current.isAtTop = element.scrollTop + offset === 0 + offset;

      if (values.current.isAtTop && values.current.isStillAtTop) {
        return;
      }

      if (values.current.isAtTop && !values.current.isStillAtTop) {
        values.current.isStillAtTop = true;
        onBeginReached && onBeginReached(event);
      } else {
        values.current.isStillAtTop = false;
      }
    };

    ref.current.onscroll = (event) => {
      conputeEnd(event);
      conputeTop(event);
    };
  }, [ref, onEndReached, onBeginReached]);

  const scrollTo = useCallback((to) => {
    ref.current.scrollTo = to;
  }, []);

  const scrollToBottom = useCallback((to) => {
    ref.current.scrollTop = ref.current.scrollHeight;
  }, []);

  return { ref, scrollTo, scrollToBottom };
};

export const useSocket = () => useBaseSocket(REACT_APP_SOCKET_URL);

export const useSocketListeners = ({
  listeners = {},
  removeListeners = {},
}) => {
  const { socket } = useSocket();

  useEffect(() => {
    if (socket.connected) {
      const names = Object.keys(listeners);

      if (names?.length) {
        const callbacks = {};

        names.map((name) => {
          const callback = (...payloads) => {
            const listener = listeners[name];
            return listener && listener(...payloads);
          };

          socket.on(name, callback);
          return (callbacks[name] = callback);
        });

        return () =>
          names.map((name) => {
            socket.removeListener(name, callbacks[name]);
            return removeListeners[name] && removeListeners[name]();
          });
      }
    }
  }, [socket.connected, listeners]);
};

const _setData = (
  data,
  { next, data: _data = {}, infinite = true, dataName, prependNextData } = {}
) =>
  next
    ? infinite
      ? {
          ...data,
          [dataName]: prependNextData
            ? [...data[dataName], ...(_data[dataName] || [])]
            : [...(_data[dataName] || []), ...data[dataName]],
        }
      : data
    : data;

const useBaseRequest = ({
  setState,
  asyncRequest,
  params = [],
  state,
  stateRef,
  dep = [],
  loadOnMount = true,
  infinite = true,
  dataPoint = "data",
  dataName = "data",
  emits = [],
  listeners = {},
  removeListeners = {},
  eventParams = {},
  setData = _setData,
  onSuccess,
}) => {
  const emitter = useEventListener(
    {
      listeners,
      removeListeners,
      params: { setState, stateRef, ...eventParams },
    },
    []
  );

  const request = useCallback(
    async (...p) => {
      try {
        setState({ isLoading: true });
        const data = await asyncRequest(...p);

        emits.map((event) => emitter.emit(event, data));

        onSuccess && onSuccess(get(data, dataPoint));

        setState(({ data: _data, ...s }) => ({
          ...s,
          isLoading: false,
          data: setData(get(data, dataPoint), {
            ...(p?.[1] || {}),
            data: _data,
            infinite,
            dataName,
          }),
        }));
      } catch (e) {
        setState({ isLoading: false });
        console.log({ e });
      }
    },
    [asyncRequest, emits, infinite, dataPoint, dataName]
  );

  const refresh = useCallback(
    (_params) => request(...(_params || params)),
    [params]
  );

  useLayoutEffect(() => {
    if (loadOnMount) {
      request(...params);
    }
  }, [loadOnMount, state?.timestamp, ...dep]);

  return { setState, state, request, emitter, refresh, stateRef };
};

const defaultRequestState = {
  timestamp: new Date().getTime(),
  data: { page: 1, total: 0, limit: 10 },
};

export const useRequest = ({ asyncRequest, params, ...props }, dep) => {
  const [state, setState, stateRef] = useMergeState(defaultRequestState);

  const rest = useBaseRequest({
    setState,
    asyncRequest,
    params,
    state,
    stateRef,
    dep,
    ...props,
  });

  const next = useCallback(
    (_params = {}, { prependNextData } = {}) => {
      const { data: { nextPage, limit } = {} } = stateRef.current;
      const canNext = !!nextPage;

      if (canNext || _params.page) {
        const page = _params.page || nextPage;
        rest.request(
          { page, ...params?.[0], limit, ..._params },
          { next: true, prependNextData }
        );
      }
    },
    [params, stateRef]
  );

  return { setState, next, ...rest };
};
