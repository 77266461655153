import React, { memo, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import SideBar from '../SideBar';
import MainContent from '../common/MainContent';
import routes from '../../constants/routes';

const AppRoute = ({ component: Component, noHeader, ...rest }) => {
  const [sideState, setSideState] = useState(false);
  const toggleSideBar = () => setSideState(!sideState);
  let token = localStorage.getItem('token');

  if (!token) {
    return <Redirect to={routes.LOGIN} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <div
          style={{
            height: '100vh'
          }}>
          <SideBar toggleSideBar={toggleSideBar} sideState={sideState} />
          <div
            className='main-content'
            style={{
              marginLeft: sideState ? '40px' : '200px',
              maxWidth: sideState ? '1876px' : '1800px',
              height: '100%'
            }}>
            <MainContent noHeader sideState={sideState} {...props} />
            <div
              style={{
                marginLeft: sideState ? '35px' : '35px',
                marginRight: '20px',
                marginTop: '8rem'
              }}>
              <Component {...props} />
            </div>
          </div>
        </div>
      )}
    />
  );
};
export default memo(AppRoute);
