import React from 'react';
import './style.css';

export default function QueryWrapper({ children, title, subHeading, classNames }) {
  return (
    <div className={`${classNames} wrapper`}>
      <div className='title-wrapper'>
        <p className='title-heading'>{title}</p>
        <p className='sub-heading'>{subHeading}</p>
        <hr />
      </div>
      {children}
    </div>
  );
}
