import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Search, Copy, Download } from '../../assets/icons/jsxIcons';
import { Col, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import Avatar from 'react-avatar';
import { useRequest } from '../../utils/hooks';
import { getActivityLogs } from '../../utils/api/activityLog';
import DataTable from 'react-data-table-component';
import { capitalizeFirstLetter, copyTextToClipboard, downloadCSV } from '../../utils/file';
import { toast } from 'react-toastify';
import './style.css';
import api from '../../api';

const ActivityLog = () => {
  const [searchText, setSearchText] = useState('');
  const [perPage, setPerpage] = useState(15);

  // onClick handler function for the copy button
  const handleCopyClick = (text) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(text)
      .then(() => {
        toast.success('IP Address copied to clipboard', {
          autoClose: 2000
        });
      })
      .catch((err) => {
        toast.error('Error occured, try again', { autoClose: 2000 });
      });
  };

  const handleCsvDownloadClick = async (event) => {
    try {
      const response = await api.get('activity-logs/csv');
      if (response.status === 200) {
        downloadCSV(response?.data?.csv, 'Activity Logs');
      }
    } catch (error) {
      toast.error('Unable to retrieve records', { autoClose: 2000 });
    }
  };

  const {
    next,
    state: {
      data: { data, total },
      isLoading
    }
  } = useRequest(
    {
      asyncRequest: getActivityLogs,
      params: [
        {
          limit: perPage,
          order: { createdAt: 'DESC' },
          searchText
        }
      ],
      infinite: false,
      name: 'activity-logs'
    },
    [searchText?.length, perPage]
  );

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        field: 'name',
        selector: ({ agent = {} }) => (
          <div className='d-flex align-items-center'>
            <Avatar size='30' src={agent?.profile_picture} round={true} />
            <p className='pl-4 mb-0'>
              {agent?.first_name} {agent?.last_name}
            </p>
          </div>
        )
      },
      {
        name: 'Action',
        selector: ({ description }) => {
          return (
            <p
              style={{
                color:
                  description === 'Agent Sign In'
                    ? 'green'
                    : description === 'User Logout'
                    ? 'red'
                    : ''
              }}>
              {capitalizeFirstLetter(description)}
            </p>
          );
        }
      },
      {
        name: 'Reason',
        selector: ({ reason }) => {
          return (
            <>
              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => (
                  <Tooltip id={'button-tooltip'} {...props}>
                    {reason || '---'}
                  </Tooltip>
                )}>
                <p className='flow'>{reason || '---'}</p>
              </OverlayTrigger>
            </>
          );
        }
      },
      {
        name: 'IP Address',
        selector: ({ ip_address }) => {
          if (ip_address?.includes('::ffff:')) {
            return ip_address?.slice(7);
          } else ip_address?.includes('::1');

          return (
            <>
              <div className='d-flex'>
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id={'button-tooltip'} {...props}>
                      {ip_address || '---'}
                    </Tooltip>
                  )}>
                  <p className='flow'>{ip_address || '---'}</p>
                </OverlayTrigger>
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id={'button-tooltip'} {...props}>
                      copy
                    </Tooltip>
                  )}>
                  <Copy type='button' onClick={() => handleCopyClick(ip_address)}>
                    Copy
                  </Copy>
                </OverlayTrigger>
              </div>
            </>
          );
        }
      },
      {
        name: 'Access time',
        selector: ({ createdAt }) => moment(createdAt).fromNow()
      }
    ],
    []
  );

  const onChangePage = useCallback((page) => next({ page }), [next]);

  return (
    <>
      <div className='card mt-4 p-4 overflow-auto table-responsive-sm'>
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <div className='card-title pl-3 h4 mb-0 font-weight-bold text-nowrap'>Activity Log</div>
          <Col className='d-flex' md={4}>
            <InputGroup className='mr-3'>
              <Input
                placeholder='Search'
                className='border-right-0'
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
              />
              <InputGroupAddon addonType='append'>
                <InputGroupText className='bg-white border-left-0'>
                  <Search width={16.77} />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <Download onClick={handleCsvDownloadClick} style={{ cursor: 'pointer' }} />
          </Col>
        </div>
        <div className='mt-3'>
          <DataTable
            progressPending={isLoading}
            columns={columns}
            data={data}
            pagination
            paginationServer
            highlightOnHover
            paginationTotalRows={total}
            paginationPerPage={perPage}
            onChangePage={onChangePage}
            onChangeRowsPerPage={(e) => setPerpage(e)}
            progressComponent={() => (
              <SkeletonTheme color='#eee'>
                <Skeleton width={700} height={10} count={5} className='ml-2' />
              </SkeletonTheme>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default ActivityLog;
