import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import React from 'react';
import './style.css';

export default function SummaryCard({ title, Logo, currentMonthData = 0, previousMonthData = 0 }) {
  // convert strings to numbers for mathematical operations
  currentMonthData = Number(currentMonthData);
  previousMonthData = Number(previousMonthData);

  return (
    <div className='col-2 d-flex flex-column report-top'>
      <h4 className='pt-2 title'>{title}</h4>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='logo-container'>
          <img src={Logo} alt='status logo' />
        </div>
        <p className='content'>{currentMonthData}</p>
      </div>
      <div
        className='comparison-text'
        style={{
          color:
            previousMonthData === 0
              ? '#2C9D3E'
              : currentMonthData > previousMonthData && previousMonthData > 0
              ? '#2C9D3E'
              : currentMonthData < previousMonthData
              ? '#FF0000'
              : ''
        }}>
        {currentMonthData > previousMonthData ? (
          <ArrowUpward />
        ) : currentMonthData < previousMonthData ? (
          <ArrowDownward />
        ) : (
          ''
        )}{' '}
        {previousMonthData === 0
          ? `+ ${currentMonthData}`
          : currentMonthData > previousMonthData && previousMonthData > 0
          ? `+ ${currentMonthData - previousMonthData}`
          : currentMonthData < previousMonthData
          ? `- ${Math.abs(currentMonthData - previousMonthData)}`
          : 0}{' '}
        from last month
      </div>
    </div>
  );
}
