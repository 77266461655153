import moment from 'moment';
import axios from 'axios';
import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import calendar, { updateCalendar } from '../../utils/api/calendar';
import DataTable from 'react-data-table-component';
import {
  CalendarAlt,
  CheckCircle,
  DotMenu,
  Exclamation,
  XCircle,
  Search
} from '../../assets/icons/jsxIcons';
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  Spinner,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { toast } from 'react-toastify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useRequest } from '../../utils/hooks';
import { useEmitter } from '../../utils/react-listeners';
import './style.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { capitalizeFirstLetter } from '../../utils/file';
import MaskCredential from '../common/MaskCredential';
import '../ActivityLog/style.css';

const source_url = window.location.href;

const Calendar = memo(() => {
  const {
    next,
    state: { data: { data = [], page = 0, total } = {}, isLoading },
    refresh
  } = useRequest(
    {
      asyncRequest: calendar,
      infinite: false,
      params: [
        {
          limit: 10,
          status: 'pending',
          order: { scheduledAt: 'ASC' }
        }
      ],
      listeners: {
        'calendars.reload': ({ stateRef }) => next({ page: stateRef.current.data.currentPage })
      },
      name: 'calendars'
    },
    []
  );

  const columns = useMemo(
    () => [
      {
        name: '#',
        selector: (_, i) => i + 1 * (page || 1),
        grow: 0.1
      },
      {
        name: 'Name',
        grow: 1,
        selector: ({ customer: { first_name = '', last_name = '' } = {} }) =>
          `${first_name} ${last_name}`
      },
      {
        name: 'Phone No.',
        grow: 1,
        selector: ({ customer: { credentials: { mobile } = {} } = {} }) => mobile
      },
      {
        name: 'Account No.',
        grow: 1,
        selector: ({ customer: { credentials: { account } = {} } = {} }) => (
          <MaskCredential
            credential={account}
            maskChar={'*'}
            unmaskedLength={4}
            maskFromStart={true}
            iconWidth={20.65}
          />
        )
      },
      {
        name: 'Date & Time',
        grow: 1,
        selector: ({ scheduledAt, id }) => <ScheduleAt scheduledAt={scheduledAt} id={id} />
      },
      {
        name: 'Status',
        grow: 1,
        selector: ({ status }) => <Status status={status} />
      },
      {
        name: 'Reason for Call',
        grow: 1,
        selector: ({ reason, id }) => <Reason id={id} reason={reason} />
      },
      {
        grow: 0.3,
        name: 'Action',
        selector: ({ id }) => <Action id={id} />
      }
    ],
    [page]
  );

  const onChangePage = useCallback((page) => next({ page }), [next]);

  const customStyles = useMemo(
    () => ({
      headRow: {
        style: {
          backgroundColor: '#F5F6F9'
        }
      },
      rows: {
        style: {
          height: 50,
          borderColor: '#F3F3F3',
          borderRadius: 5
        }
      },
      cells: {
        style: {}
      }
    }),
    []
  );

  return (
    <>
      <div className='card mt-4 p-4 overflow-auto table-responsive-sm'>
        <div className='d-flex justify-content-between align-items-between mb-2'>
          <div className='card-title pl-3 h4 mb-0 font-weight-bold text-nowrap'>
            Callback Requests
          </div>
          <Col className='d-flex' md={4}>
            <TimeDrop refresh={refresh} />
            <InputGroup style={{ marginRight: '40px' }}>
              <Input placeholder='Search' className='border-right-0' />
              <InputGroupAddon addonType='append' style={{ height: '29px' }}>
                <InputGroupText className='bg-white border-left-0'>
                  <Search width={16.77} />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </div>
        <div className='mt-3'>
          <DataTable
            progressPending={isLoading}
            customStyles={customStyles}
            columns={columns}
            data={data}
            pagination
            paginationServer
            highlightOnHover
            paginationTotalRows={total}
            onChangePage={onChangePage}
            progressComponent={() => (
              <SkeletonTheme color='#eee'>
                <Skeleton width={700} height={10} count={5} className='ml-2' />
              </SkeletonTheme>
            )}
          />
        </div>
      </div>
    </>
  );
});

const ScheduleAt = ({ scheduledAt, id }) => {
  const scheduledDate = moment(scheduledAt);
  const isBefore = scheduledDate.isBefore();
  const fromNow = scheduledDate.fromNow();
  const comingDate = scheduledDate.calendar(null, {
    sameElse: 'DD/MM/YYYY HH:mm',
    nextWeek: 'dddd HH:mm'
  });

  return (
    <OverlayTrigger
      placement='top'
      delay={{ show: 250, hide: 400 }}
      overlay={(props) => (
        <Tooltip id={'button-tooltip' + id} {...props}>
          {isBefore ? fromNow : comingDate}
        </Tooltip>
      )}>
      <p>{isBefore ? fromNow : comingDate}</p>
    </OverlayTrigger>
  );
};

const Reason = ({ reason, id }) => (
  <OverlayTrigger
    placement='top'
    delay={{ show: 250, hide: 400 }}
    overlay={(props) => (
      <Tooltip id={'button-tooltip' + id} {...props}>
        {reason}
      </Tooltip>
    )}>
    <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{reason}</p>
  </OverlayTrigger>
);

const Status = ({ status }) => {
  const Icon = status === 'accepted' ? CheckCircle : status === 'declined' ? XCircle : Exclamation;

  return (
    <>
      <span className='mr-2'>
        <Icon width={20} />
      </span>
      {capitalizeFirstLetter(status)}
    </>
  );
};

const TimeDrop = memo(({ refresh }) => {
  const options = [
    { value: undefined, label: 'None' },
    { value: 'day', label: 'Day' },
    { value: 'week', label: 'Week' },
    { value: 'month', label: 'Month' },
    { value: 'year', label: 'Year' }
  ];

  const filter = useCallback(({ value: period }) => refresh([{ period }]), [refresh]);

  return (
    <InputGroup className='mr-2'>
      <Button color='#fff' className='bg-white'>
        <CalendarAlt />
      </Button>
      <Select
        styles={{
          control: (provided, state) => ({ ...provided, borderWidth: 0 })
        }}
        onChange={filter}
        options={options}
      />
    </InputGroup>
  );
});

const Action = ({ id }) => {
  const [opened, setOpened] = useState();
  const [{ ip }, setState] = useState({
    ip: undefined
  });
  const [modal, setModal] = useState(false);

  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then((data) => {
        const { ip } = data.data;

        setState({
          ip
        });
      })
      .catch((error) => console.log('error => ', error));
  }, []);

  const formToggle = () => {
    setModal(!modal);
  };

  const emitter = useEmitter();

  const toggle = useCallback(() => setOpened((prevState) => !prevState), [setOpened]);

  const edit = useCallback(async (id, update) => {
    try {
      setOpened(false);
      setModal(false);
      if (update.status === 'declined') {
        return formToggle();
      } else {
        await updateCalendar(id, update);
      }
      emitter.emit('calendars.reload');
    } catch (error) {
      console.log(error);
    }
  }, []);

  const accept = useCallback(() => edit(id, { status: 'accepted' }), [edit]);
  const reject = useCallback(() => edit(id, { status: 'declined' }), [edit]);

  return (
    <>
      <Dropdown isOpen={opened} toggle={toggle}>
        <DropdownToggle
          style={{
            backgroundColor: 'transparent',
            borderWidth: 0,
            color: '#000'
          }}>
          <DotMenu fill='#000' height={18} />
        </DropdownToggle>
        <DropdownMenu container='body'>
          <DropdownItem onClick={accept}>
            <CheckCircle width={20} /> Accept
          </DropdownItem>
          <DropdownItem onClick={reject}>
            <XCircle width={20} stroke='red' /> Decline
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <DeclineReasonForm modal={modal} formToggle={formToggle} id={id} ip={ip} />
    </>
  );
};

const DeclineReasonForm = ({ formToggle, modal, id, ip }) => {
  const [reason, setReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onChangeInput = (event) => {
    setReason(event.target.value);
  };

  const handleInputValidation = () => {
    const reasonValidation = {
      reason: 'Reason is Required',
      status: false
    };

    const passed = {
      status: true
    };

    return reason === '' ? reasonValidation : passed;
  };

  const emitter = useEmitter();

  const handleEnterKeypress = (e) => {
    if (e.key === 'Enter') {
      sendMessage(e);
    }
  };

  const sendMessage = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    const validate = handleInputValidation();

    if (validate?.status) {
      try {
        await updateCalendar(id, {
          status: 'declined',
          reason: reason,
          ip,
          source_url
        });

        toast.success('Call Request Declined successfully', {
          autoClose: 2000
        });

        formToggle();
        emitter.emit('calendars.reload');
      } catch (err) {
        toast.error('An error occurred', { autoClose: 2000 });
        setIsLoading(false);
      }
    } else {
      toast.error(validate.message, { autoClose: 2000 });
    }
  };

  const onSubmit = async (event) => {
    return sendMessage(event);
  };

  return (
    <form id='decline' onSubmit={onSubmit}>
      <Modal
        style={{ maxWidth: '600px', width: '100%' }}
        size='lg'
        isOpen={modal}
        toggle={formToggle}>
        <ModalHeader toggle={formToggle}></ModalHeader>
        <h4 className='text-center pb-3'>Reason For Call Decline</h4>
        <ModalBody className='p-4'>
          <div className='col-12'>
            <FormGroup>
              <Input
                onChange={onChangeInput}
                onKeyDown={handleEnterKeypress}
                type='textarea'
                name='reason'
                value={reason}
                resizable='true'
                placeholder='Reason for Call Request Decline'
              />
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter
          style={{
            marginBottom: 20
          }}>
          <button
            type='submit'
            form='decline'
            style={{
              width: '200px',
              backgroundColor: '#43425D',
              borderRadius: '8px'
            }}
            className='btn text-white'>
            {isLoading ? <Spinner color='light' size='sm' /> : ''}
            Submit
          </button>
        </ModalFooter>
      </Modal>
    </form>
  );
};

export default Calendar;
