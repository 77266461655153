import React from 'react';
import TabWrapper from '../TabWrapper';
import { Construction } from '../../../assets/icons/jsxIcons';
import './style.css';

export default function SecuritySettings() {
  return (
    <TabWrapper tabName='Security'>
      <div className='security-wrapper'>
        <Construction /> <p>Coming soon...</p>
      </div>
    </TabWrapper>
  );
}
