import React, { memo, useCallback, useMemo, useRef, useEffect } from 'react';
import {
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner
} from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { agentIcons } from '../../assets/icons';
import avatarLogo from '../../assets/images/default_picture.jpg';
import Avatar from 'react-avatar';
import './style.css';
import { useRequest } from '../../utils/hooks';
import { agents, createAgent, deleteAgent, updateAgent, viewAgent } from '../../utils/api/user';
import DataTable from 'react-data-table-component';
import useState from 'use-react-state';
import { useUser } from '../../utils/hooks/user';
import { getTeams } from '../../utils/api/team';
import Select from 'react-select';
import { CheckCircle, DotMenu, XCircle } from '../../assets/icons/jsxIcons';

const source_url = window.location.href;

const Agents = () => {
  const [{ editModal, modal, actionOpened, ip }, setState] = useState({
    modal: false,
    editModal: false,
    actionOpened: null,
    ip: undefined
  });

  const [editAgentModal, setEditAgentModal] = useState(false);
  const [agentID, setAgentID] = useState(null);

  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then((data) => {
        const { ip } = data.data;

        setState({
          ip
        });
      })
      .catch((error) => console.log('error => ', error));
  }, []);

  const setActionOpened = (actionOpened) => setState({ actionOpened });

  const toggle = () =>
    setState((s) => {
      s.modal = !s.modal;
    });

  const toggleEdit = async (item) => {
    if (item.id) {
      setEditAgentModal(true);
      setAgentID(item.id);
    } else {
      setEditAgentModal(false);
    }
  };

  const {
    state: { data: { data = [] } = {}, isLoading, total },
    refresh,
    next
  } = useRequest({
    asyncRequest: agents,
    params: [{ limit: 100 }],
    onError: (e) => {
      toast.error(`couldn't get Agents: ${e.message}`, { autoClose: 2000 });
    }
  });

  const {
    state: { data: { data: teams = [] } = {} }
  } = useRequest({
    asyncRequest: getTeams,
    params: [{ limit: 100 }],
    onError: (e) => {
      toast.error(`couldn't get Teams: ${e.message}`, { autoClose: 2000 });
    }
  });

  const handleDelete = (id) => {
    confirmAlert({
      title: 'Confirmation!',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => confirmChangeStatus(id)
        },
        {
          label: 'No'
        }
      ]
    });
  };

  const confirmChangeStatus = async (id) => {
    try {
      const formData = new FormData();

      formData.append('ip', ip);
      formData.append('source_url', source_url);

      await deleteAgent(id, formData);
      refresh();
      toast.success('Agent deleted successfully', { autoClose: 2000 });
    } catch (error) {
      toast.error('Error deleting agent: ' + error.message, {
        autoClose: 2000
      });
    }
  };

  const columns = useMemo(
    () => [
      // {
      //   name: "#",
      //   selector: (_, i) => i + 1,
      //   grow: 0.1,
      // },
      {
        name: 'Name',
        field: 'profile.name',
        grow: 1.5,
        selector: (rowData) => {
          return (
            <div className='d-flex align-items-center'>
              <Avatar size='30' src={rowData?.profile_picture} round={true} />
              <p className='pl-3 mb-0'>{`${rowData?.first_name} ${
                rowData?.last_name !== undefined && rowData?.last_name
              }`}</p>
            </div>
          );
        }
      },
      {
        name: 'Email',
        selector: ({ email }) => email,
        grow: 1.7
      },
      {
        name: 'Team',
        selector: ({ team }) => <p className='p-0 m-0'>{team?.name}</p>,
        grow: 1.5
      },
      {
        name: 'Title',
        selector: ({ title }) => <p className='p-0 m-0'>{title}</p>,
        grow: 1.5
      },
      {
        name: 'Date Created',
        field: 'activity.date_established',
        selector: ({ createdAt }) => moment(createdAt).format('MMM D, YYYY'),
        grow: 1.5
      },
      {
        name: 'Last Active',
        selector: (rowData) => moment(rowData?.last_logon).fromNow(),
        grow: 1.5
      },
      {
        name: 'Actions',
        grow: 0.1,
        selector: (data) => (
          <>
            <div className='menu-cover position-relative d-flex justify-content-center align-items-center'>
              <Dropdown
                isOpen={actionOpened === data.id}
                toggle={() =>
                  actionOpened === data.id ? setActionOpened(null) : setActionOpened(data.id)
                }>
                <DropdownToggle
                  style={{
                    backgroundColor: 'transparent',
                    borderWidth: 0,
                    color: '#000'
                  }}>
                  <DotMenu fill='#000' height={18} />
                </DropdownToggle>
                <DropdownMenu container='body'>
                  <DropdownItem onClick={() => toggleEdit(data)}>
                    <CheckCircle width={20} /> Edit
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      handleDelete(data.id);
                      setActionOpened(null);
                    }}>
                    <XCircle width={20} stroke='red' /> Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </>
        )
      }
    ],
    [actionOpened]
  );

  const customStyles = useMemo(
    () => ({
      headRow: {
        style: {
          backgroundColor: '#F5F6F9',
          fontSize: '14px'
        }
      },
      rows: {
        style: {
          height: 50,
          borderColor: '#F3F3F3',
          borderRadius: 5
        }
      },
      cells: {
        style: {}
      }
    }),
    []
  );

  const onChangePage = useCallback((page) => next({ page }), [next]);

  return (
    <>
      <div className='Agents card mt-4 p-4 overflow-auto table-responsive-sm'>
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <div className='card-title pl-3 h4 mb-0 font-weight-bold text-nowrap'>Agents</div>
          <div className='d-flex justify-content-between align-items-center'>
            <button type='button' onClick={toggle} className='btn add-btn ml-2 text-nowrap'>
              Add Agent
            </button>
          </div>
        </div>
        {isLoading ? (
          <SkeletonTheme color='#eee'>
            <Skeleton width={700} height={10} count={5} className='ml-2' />
          </SkeletonTheme>
        ) : (
          <div className='mt-3'>
            <DataTable
              customStyles={customStyles}
              progressPending={isLoading}
              columns={columns}
              data={data}
              pagination
              paginationServer
              highlightOnHover
              paginationTotalRows={total}
              onPageChange={onChangePage}
            />
          </div>
        )}
      </div>
      {modal && (
        <AddAgentModal
          {...{
            modal,
            toggle,
            teams,
            setModal: toggle,
            refresh,
            edit: editModal
          }}
        />
      )}
      {editAgentModal && (
        <EditAgentModal
          {...{
            editModal: editAgentModal,
            toggleEdit,
            teams,
            refresh,
            setEditModal: setEditAgentModal,
            agentId: agentID
          }}
        />
      )}
    </>
  );
};

const AddAgentModal = memo(function ({ modal, toggle, teams, setModal, refresh }) {
  const [state, setState] = useState({
    file: null,
    first_name: '',
    last_name: '',
    email: '',
    title: '',
    gender: '',
    phone: '',
    team: teams?.[0]?.id,
    roles: []
  });

  const [{ ip }, setIpState] = useState({
    ip: undefined
  });

  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then((data) => {
        const { ip } = data.data;

        setIpState({
          ip
        });
      })
      .catch((error) => console.log('error => ', error));
  }, []);

  const companyId = useUser(({ company } = {}) => company?.id || company);

  const uploadAvatar = async (event) => {
    if (event?.target?.files[0]) {
      setState({
        avatar: URL.createObjectURL(event?.target?.files[0]),
        file: event?.target?.files[0]
      });
    }
  };

  const handleInputChange = ({ target: { value, name } }) => setState({ [name]: value });

  const onChangeOptions = (name, values) => {
    setState({
      [name]: (values?.map && values.map(({ value }) => value)) || [values.value]
    });
  };

  const onChangeLetters = ({ target: { value, name } }) => {
    const regexPattern = /^[A-Za-z ]+$/;
    if (value === '' || regexPattern.test(value)) setState({ [name]: value });
  };

  const onChangeNumbers = ({ target: { value, name } }) => {
    const regexPattern = /^[0-9\b]+$/;
    if (value === '' || regexPattern.test(value)) setState({ [name]: value });
  };

  const handleValidation = () => {
    const nameValidation = {
      message: 'First and Last Names are Required',
      status: false
    };
    const emailValidation = {
      message: 'Email is required',
      status: false
    };
    const teamValidation = {
      message: 'team is required',
      status: false
    };
    const titleValidation = {
      message: 'Job Title is required',
      status: false
    };
    const genderValidation = {
      message: 'Gender is required',
      status: false
    };
    const roleValidation = {
      message: 'Role is required',
      status: false
    };
    const profilePictureValidation = {
      message: 'Profile Picture is required',
      status: false
    };
    const phoneMobileValidation = {
      message: 'Phone is required',
      status: false
    };
    const passed = {
      status: true
    };
    return state.first_name !== '' && state.last_name !== ''
      ? state.email === ''
        ? emailValidation
        : state.title === ''
        ? titleValidation
        : state.phone === '' || state.phone === undefined
        ? phoneMobileValidation
        : state.gender === '' || state.gender === undefined
        ? genderValidation
        : !state.roles.length
        ? roleValidation
        : state.team === null || state.team === undefined
        ? teamValidation
        : state.file === null
        ? profilePictureValidation
        : passed
      : nameValidation;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validation = handleValidation();
    if (validation.status) {
      setState({ isLoading: true });
      const formData = new FormData();
      ['first_name', 'last_name', 'email', 'gender', 'team', 'phone', 'title'].map((name) =>
        formData.append(name, state[name])
      );
      formData.append('profile_picture', state.file);
      formData.append('company', companyId);
      formData.append('ip', ip);
      formData.append('source_url', source_url);
      state.roles.map((role) => formData.append('roles[]', role));

      if (state.password !== state.confirmPassword) {
        return toast.error(`Passwords does not match`, { autoClose: 2000 });
      }
      try {
        const { data } = await createAgent(formData);
        refresh();
        setModal(false);
        toast.success(data?.message, { autoClose: 2000 });
        setState({
          isLoading: false,
          file: null
        });
      } catch (err) {
        console.log('error', err);
        toast.error('Error! Please try again', { autoClose: 2000 });
        setState({ isLoading: false });
      }
    } else {
      toast.error(validation.message, { autoClose: 2000 });
    }
  };

  return (
    <Modal style={{ maxWidth: '600px', width: '100%' }} size='lg' isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <h4 className='text-center pb-3'>Add New Agent</h4>
      <AgentForm
        {...{
          onSubmit: handleSubmit,
          uploadedFile: state.avatar ? state.avatar : avatarLogo,
          uploadAvatar,
          onChangeOptions,
          onChangeInput: handleInputChange,
          onChangeLetters,
          onChangeNumbers,
          data: state,
          teams
        }}
        id='add-agent'
      />
      <ModalFooter style={{ alignSelf: 'flexStart', marginBottom: 20 }}>
        <button
          type='submit'
          form='add-agent'
          style={{
            width: '150px',
            backgroundColor: '#43425D',
            borderRadius: '8px'
          }}
          className='btn text-white p-2'>
          {state.isLoading && <Spinner color='light' size='sm' />}
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
});

const EditAgentModal = memo(function ({
  editModal,
  toggleEdit,
  teams,
  refresh,
  setEditModal,
  agentId
}) {
  const agentRef = useRef();

  const [{ ip }, setIpState] = useState({
    ip: undefined
  });

  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then((data) => {
        const { ip } = data.data;

        setIpState({
          ip
        });
      })
      .catch((error) => console.log('error => ', error));
  }, [ip]);

  const {
    state: { data = {}, isLoading, uploadedFile, file },
    setState
  } = useRequest({
    asyncRequest: viewAgent,
    params: [agentId],
    loadOnMount: !!agentId,
    onSuccess: (data) => (agentRef.current = data)
  });

  const onChangeInput = ({ target: { value, name } }) =>
    setState((s) => {
      s.data[name] = value;
    });

  const onChangeOptions = (name, values) => {
    setState((s) => {
      s.data[name] = (values.map && values.map(({ value }) => value)) || [values.value];
    });
  };

  const onChangeLetters = ({ target: { value, name } }) => {
    const regexPattern = /^[A-Za-z ]+$/;
    if (value === '' || regexPattern.test(value))
      setState((s) => {
        s.data[name] = value;
      });
  };

  const onChangeNumbers = ({ target: { value, name } }) => {
    const regexPattern = /^[0-9\b]+$/;
    if (value === '' || regexPattern.test(value))
      setState((s) => {
        s.data[name] = value;
      });
  };

  const uploadAvatar = async (event) =>
    event?.target?.files[0] &&
    setState({
      uploadedFile: URL.createObjectURL(event.target.files[0]),
      file: event.target?.files[0]
    });

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setState({ isLoading: true });
    const validate = handleEditValidation();

    if (validate?.status) {
      const formData = new FormData();

      ['first_name', 'last_name', 'email', 'password', 'gender', 'phone', 'title'].map(
        (key) => agentRef.current[key] !== data[key] && formData.append(key, data[key])
      );

      typeof data?.roles !== data.roles &&
        data.roles.map((role) => formData.append('roles[]', role));
      typeof data?.team === 'string' && formData.append('team', data.team);
      typeof file === 'object' && formData.append('profile_picture', file);
      formData.append('ip', ip);
      formData.append('source_url', source_url);

      try {
        await updateAgent(agentId, formData);
        refresh();
        setState({ isLoading: false });
        setEditModal(false);
        toast.success('Agent updated successfully', { autoClose: 2000 });
      } catch (err) {
        console.log('error', err);
        toast.error(err?.response?.data?.message, { autoClose: 2000 });
        setState({ isLoading: false });
        setEditModal(false);
      }
    } else {
      toast.error(validate.message, { autoClose: 2000 });
    }
  };

  const handleEditValidation = () => {
    const nameValidation = {
      message: 'Name is Required',
      status: false
    };
    const emailValidation = {
      message: 'email is required',
      status: false
    };
    const phoneMobileValidation = {
      message: 'Phone is required',
      status: false
    };
    const passwordValidation = {
      message: `'password can't be empty is and required'`,
      status: false
    };
    const confirmPasswordValidation = {
      message: "password can't be empty and is required",
      status: false
    };
    const teamValidation = {
      message: 'team is required',
      status: false
    };
    const titleValidation = {
      message: 'Job Title is required',
      status: false
    };
    const genderValidation = {
      message: 'Gender is required',
      status: false
    };
    const roleValidation = {
      message: 'Role is required',
      status: false
    };
    const passed = {
      status: true
    };
    return data.name !== ''
      ? data.email === ''
        ? emailValidation
        : data.phoneMobile === ''
        ? phoneMobileValidation
        : data.password === ''
        ? passwordValidation
        : data.confirmPassword === ''
        ? confirmPasswordValidation
        : data.title === ''
        ? titleValidation
        : data.team === ''
        ? teamValidation
        : data.gender === '' || data.gender === undefined
        ? genderValidation
        : !data.roles.length
        ? roleValidation
        : passed
      : nameValidation;
  };

  return (
    <Modal
      style={{ maxWidth: '600px', width: '100%' }}
      size='lg'
      isOpen={editModal}
      toggle={toggleEdit}>
      <ModalHeader toggle={toggleEdit}></ModalHeader>
      <h4 className='text-center pb-3'>Edit Agent</h4>
      <AgentForm
        {...{
          onSubmit: handleEditSubmit,
          uploadedFile,
          uploadAvatar,
          onChangeOptions,
          onChangeInput,
          onChangeLetters,
          onChangeNumbers,
          data,
          teams,
          formTitle: 'Edit Agent'
        }}
        id='edit-agent'
      />
      <ModalFooter
        style={{
          marginBottom: 20
        }}>
        <button
          type='submit'
          form='edit-agent'
          style={{
            width: '200px',
            backgroundColor: '#43425D',
            borderRadius: '8px'
          }}
          className='btn text-white'>
          {isLoading ? <Spinner color='light' size='sm' /> : ''}
          Update Agent
        </button>
      </ModalFooter>
    </Modal>
  );
});

const AgentForm = memo(function ({
  onSubmit,
  uploadedFile,
  uploadAvatar,
  onChangeOptions,
  onChangeInput,
  onChangeLetters,
  onChangeNumbers,
  data,
  teams,
  id,
  formTitle
}) {
  return (
    <form id={id} onSubmit={onSubmit}>
      <ModalBody className='p-4'>
        <>
          <div className='row mt-1'>
            <div className='mb-2' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <label htmlFor='upload-avatar'>
                <div
                  style={{
                    borderRadius: '50%',
                    cursor: 'pointer',
                    display: 'block',
                    overflow: 'hidden',
                    position: 'relative'
                  }}>
                  <img
                    src={uploadedFile ? uploadedFile : data.profile_picture}
                    alt='Avatar'
                    className='avatar'
                    style={{ width: 100, height: 100 }}
                  />
                  <span
                    style={{
                      position: 'absolute',
                      bottom: '0px',
                      textAlign: 'center',
                      top: 'auto',
                      height: '25px',
                      width: '100%',
                      backgroundColor: 'black',
                      opacity: '0.3',
                      display: 'block'
                    }}>
                    <img src={agentIcons['noun_Camera_1740400.svg'].default} alt='' />
                  </span>
                </div>
              </label>
              <input type='file' id='upload-avatar' onChange={uploadAvatar} className='d-none' />
            </div>
            <div className='container agent-form px-5 py-0'>
              <div className='row'>
                <div className='col-6'>
                  <FormGroup>
                    <Input
                      onChange={onChangeLetters}
                      type='text'
                      value={data.first_name}
                      name='first_name'
                      placeholder='First Name'
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      onChange={onChangeLetters}
                      type='text'
                      value={data.last_name}
                      name='last_name'
                      placeholder='Last Name'
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      onChange={onChangeNumbers}
                      type='text'
                      value={data.phone}
                      name='phone'
                      placeholder='Mobile Phone'
                    />
                  </FormGroup>
                  {formTitle ? (
                    <>
                      <FormGroup>
                        <Input
                          className='mb-2'
                          onChange={onChangeInput}
                          value={data.password}
                          type='password'
                          name='password'
                          placeholder='Password'
                          autoComplete='new-password'
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          onChange={onChangeInput}
                          value={data.confirmPassword}
                          type='password'
                          name='confirmPassword'
                          placeholder='Confirm Password'
                          autoComplete='new-password'
                        />
                      </FormGroup>
                    </>
                  ) : (
                    ''
                  )}
                  <FormGroup>
                    <Input
                      onChange={onChangeInput}
                      value={data.location}
                      type='location'
                      name='location'
                      placeholder='Location'
                    />
                  </FormGroup>
                </div>
                <div className='col-6'>
                  <FormGroup>
                    <Input
                      onChange={onChangeLetters}
                      value={data.title}
                      type='text'
                      name='title'
                      placeholder='Job Title'
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type='select'
                      name='gender'
                      onChange={onChangeInput}
                      placeholder='Gender'
                      value={data.gender}>
                      <option disabled value={''}>
                        Gender
                      </option>
                      <option value='female'>Female</option>
                      <option value='male'>Male</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      onChange={onChangeInput}
                      value={data.email}
                      type='email'
                      name='email'
                      placeholder='Email'
                    />
                  </FormGroup>
                  <Roles {...{ onChangeOptions, value: data['roles'] }} />
                  <FormGroup>
                    <Input type='select' name='team' onChange={onChangeInput} value={data.team?.id}>
                      <option disabled>Team</option>
                      {teams?.map((item) => (
                        <option key={item.team_id} value={item.team_id}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className='col'></div>
          </div>
        </>
      </ModalBody>
    </form>
  );
});

const Roles = memo(function ({ onChangeOptions, value }) {
  const roles = [
    { value: 'super-admin', label: 'Super Admin' },
    { value: 'admin', label: 'Admin' },
    { value: 'user', label: 'User' }
  ];

  const handleChange = useCallback(
    (value) => {
      onChangeOptions('roles', value);
    },
    [onChangeOptions]
  );

  const formattedValue = useMemo(
    () => value?.map((value) => ({ value, label: value.split('-').join(' ') })),
    [value]
  );

  return (
    <FormGroup>
      <Select value={formattedValue} placeholder='Roles' onChange={handleChange} options={roles} />
    </FormGroup>
  );
});

export default Agents;
