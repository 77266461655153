import { padStart } from 'lodash';
import moment from 'moment';
import React, { memo } from 'react';
import { Badge, Col, Row } from 'reactstrap';
import { Interval } from '../common/Time';
import { Text } from '../theme';

const ConnectionUser = memo(({ item, i, click, status, read }) => {
  const isWaiting = status === 'waiting';

  return (
    <div
      role='button'
      className='card card-w no-card-border my-2'
      onClick={() => {
        read(i, item);
        click(item);
      }}>
      <div className='card-head'>
        <div className='card-body card-body-cus px-3 py-2'>
          <div className='d-flex align-items-center mb-2'>
            <div className={isWaiting ? 'agent-waiting-indicate' : 'agent-connected-indicate'} />
            <h6 className='mb-0'>
              {item?.customer?.first_name} {item?.customer?.last_name}
            </h6>
            <span className={`${isWaiting ? 'agent-waiting-txt' : 'agent-connected-txt'} ml-auto`}>
              <span>{isWaiting ? 'Waiting' : 'Connected'}</span>
              {isWaiting && (
                <Interval
                  interval={1000}
                  Render={() => {
                    const d = moment.duration(moment().diff(item.createdAt));
                    return (
                      <span style={{ fontSize: 11 }} className='text-secondary text-sm text-center'>
                        {padStart(d._data.hours, 2, '0')}:{padStart(d._data.minutes, 2, '0')}
                        <span style={{ fontSize: 9 }}>:{padStart(d._data.seconds, 2, '0')}</span>
                      </span>
                    );
                  }}
                />
              )}
            </span>
          </div>
        </div>
      </div>

      <div className='card-content'>
        <div className='card-body card-body-cus'>
          <Col>
            <Row className='agent-row justify-content-between'>
              <Col>
                <Row className='align-items-center'>
                  <Text className='mr-2'>Email</Text>
                  <Text className='pb-1 text-muted'>{item?.customer?.email}</Text>
                </Row>
              </Col>
              {!isWaiting && !!item.conversation?.unread_count && (
                <Badge
                  style={{ width: 20, height: 20 }}
                  color='primary'
                  className='rounded-circle d-flex align-items-center justify-content-center'>
                  {item.conversation?.unread_count}
                </Badge>
              )}
            </Row>
          </Col>
          <Col>
            <Row className='agent-row'>
              <Text className='mr-2'>Account No.</Text>
              <Text className=''>{item?.customer?.credentials?.account}</Text>
            </Row>
          </Col>
        </div>
      </div>
    </div>
  );
});

export default ConnectionUser;
