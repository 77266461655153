import { ChevronRight } from '@material-ui/icons';
import React from 'react';
import '../style.css';

export default function SettingsSideBar({ sidebars, activeTab, selectTab }) {
  return (
    <>
      <div className='setting-sidebar-menu'>
        <div className='nav-wrapper'>
          <ul className='ml-2 sidebar-list'>
            {sidebars.map(({ title, Icon }, key) => (
              <>
                <div className='nav-list' key={key}>
                  <li
                    onClick={() => selectTab(key)}
                    className='d-flex align-items-center nav-item'
                    style={{ color: key === activeTab ? '#083047b4' : 'black' }}>
                    <Icon style={{ marginRight: '10px', height: '25', width: '25' }} /> {title}
                  </li>
                  <ChevronRight
                    style={{
                      color: key === activeTab ? '#083047b4' : 'black',
                      marginTop: '10px',
                      fontWeight: 'bold',
                      height: '25',
                      width: '25'
                    }}
                  />
                </div>
                <hr />
              </>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}