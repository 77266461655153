import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './style.css';
import WidgetSetting from './widgetSetting';
import Sdk from './SDK';
import SecuritySettings from './Security';
import SettingsWrapper from './Parent';
import { SecurityPageData } from '../../constants/settingData';
import { viewCompany } from '../../utils/api/companies';
import useState from 'use-react-state';
import Loader from '../Loader';
import { useUser } from '../../utils/hooks/user';
import { toast } from 'react-toastify';

const Setting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState({});

  const companyId = useUser(({ company } = {}) => company?.id || company);

  const getCompanyDetails = async () => {
    try {
      setIsLoading(true);

      const response = await viewCompany(companyId);

      if (response.status === 200) {
        setCompany(response?.data?.company);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error('Error while feching company details', { autoClose: 2000 });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  return (
    <>
      <div className='mt-4 p-4 overflow-auto setting-wrapper'>
        <div className='card-title pl-3 h4 mb-0 font-weight-bold text-nowrap'>Settings</div>
        {isLoading ? (
          <Loader />
        ) : (
          <SettingsWrapper nav={SecurityPageData}>
            <WidgetSetting companyData={company} />
            <SecuritySettings />
            <Sdk company={company} />
          </SettingsWrapper>
        )}
      </div>
    </>
  );
};

export default withRouter(Setting);
