import React from "react";
import "./style.css";
import MuiTable from "../CustomTable/MuiTable";

const RealTimeVisitors = () => {
  const columns = [
    {
      title: "#",
      field: "",
      align: "center",
      render: (rowData, index) => rowData.tableData.id + 1,
      cellStyle: {
        width: 10,
        maxWidth: 10,
      },
      headerStyle: {
        width: 10,
        maxWidth: 10,
      },
    },
    { title: "Country", field: "Country" },
    { title: "city", field: "city" },
    { title: "Connected Since", field: "Connected Since" },
    { title: "IP Address", field: "IP Address" },
    { title: "Landing Page", field: "Landing Page" },
  ];
  const columns2 = [
    {
      title: "#",
      field: "",
      align: "center",
      render: (rowData, index) => rowData.tableData.id + 1,
      cellStyle: {
        width: 10,
        maxWidth: 10,
      },
      headerStyle: {
        width: 10,
        maxWidth: 10,
      },
    },
    { title: "URL", field: "URL" },
    { title: "Page Views", field: "Page Views" },
  ];
  const tableData = [
    {
      Country: "pakistan",
      city: "Lhr",
      "Connected Since": "2-2-2021",
      "IP Address": "192.67.55.99",
      "Landing Page": "www.you.com",
    },
  ];
  const tableData2 = [
    {
      URL: "www.u.com",
      "Page Views": 100,
    },
  ];
  return (
    <>
      <div className=' d-flex justify-content-between flex-wrap align-items-center mt-4 px-2 '>
        <div
          className=' card d-flex flex-row justify-content-between align-items-center col-sm-12 col-md mr-md-3 '
          style={{
            width: "48%",
            minWidth: "300px",
            borderRadius: "8px",
            padding: "2%",
          }}
        >
          <div className='d-flex flex-column'>
            <div className='mb-2'>
              <h4 style={{ color: "#9CACB5" }}>Right Now</h4>
            </div>
            <div>
              <h1 style={{ fontWeight: "bolder" }}>Active Visitors</h1>
            </div>
          </div>
          <div>
            <h1 style={{ fontWeight: 900, fontSize: "55px" }}>99</h1>
          </div>
        </div>

        <div
          className=' card d-flex flex-row justify-content-between align-items-center col-6'
          style={{
            width: "48%",
            minWidth: "300px",
            borderRadius: "8px",
            padding: "2%",
          }}
        >
          <div className='d-flex flex-column'>
            <div className='mb-2'>
              <h4 style={{ color: "#9CACB5" }}>Conversion</h4>
            </div>
            <div>
              <h1 style={{ fontWeight: "bolder" }}>Total visitors</h1>
            </div>
          </div>
          <div>
            <h1 style={{ fontWeight: 900, fontSize: "55px" }}>728</h1>
          </div>
        </div>
      </div>
      <div className='card mt-4 p-4 overflow-auto table-responsive-sm'>
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <div className='card-title h4 pl-3 mb-0 font-weight-bold'>
            Real Time Activity
          </div>
          {/*<div className="d-flex justify-content-between align-items-center">*/}
          {/*    <nav className="navbar navbar-light" style={{ width: "210px", paddingRight: "1px" }}>*/}
          {/*        <form className="form-inline position-relative">*/}

          {/*            <input className="form-control mr-sm-2 w-100" type="search" placeholder="Search"*/}
          {/*                aria-label="Search" />*/}
          {/*            <i className="fa fa-search position-absolute" style={{right: "25px"}}/>*/}
          {/*        </form>*/}
          {/*    </nav>*/}
          {/*    <nav className="navbar navbar-light pr-0" style={{ width: "130px", paddingLeft: "1px" }}>*/}
          {/*        <form className="form-inline position-relative">*/}
          {/*            <i className="fa fa-sliders position-absolute" style={{right: "15px"}} aria-hidden="true"/>*/}
          {/*            <input className="form-control w-100 " type="search" placeholder="Filter"*/}
          {/*                aria-label="Search" />*/}
          {/*        </form>*/}
          {/*    </nav>*/}
          {/*</div>*/}
        </div>
        <div className='mt-3'>
          <MuiTable columns={columns} data={tableData} title={"All Records"} />
        </div>
      </div>

      <div>
        <div className='card mt-4 p-4 overflow-auto table-responsive-sm'>
          <div className='d-flex justify-content-between align-items-center mb-2'>
            <div className='card-title h4 pl-3 mb-0 font-weight-bold'>
              Page Views
            </div>
            {/*                    <div className="d-flex justify-content-between align-items-center">*/}
            {/*                        <nav className="navbar navbar-light" style={{width: "210px", paddingRight: "1px"}}>*/}
            {/*                            <form className="form-inline position-relative">*/}

            {/*                                <input className="form-control mr-sm-2 w-100" type="search" placeholder="Search"*/}
            {/*                                    aria-label="Search" />*/}
            {/*                                <i className="fa fa-search position-absolute" style={{right: "25px"}}/>*/}
            {/*                            </form>*/}
            {/*                        </nav>*/}
            {/*                        <nav className="navbar navbar-light pr-0" style={{width: "130px", paddingLeft: "1px"}}>*/}
            {/*                            <form className="form-inline position-relative">*/}
            {/*                                <i className="fa fa-sliders position-absolute" style={{right: "15px"}}*/}
            {/*aria-hidden="true"/>*/}
            {/*                                <input className="form-control w-100 " type="search" placeholder="Filter"*/}
            {/*                                    aria-label="Search" />*/}
            {/*                            </form>*/}
            {/*                        </nav>*/}
            {/*                    </div>*/}
          </div>
          <div className='mt-3'>
            <MuiTable
              columns={columns2}
              data={tableData2}
              title={"All Records"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RealTimeVisitors;
