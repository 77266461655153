import React, { forwardRef, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import ConnectedUserWidget from './ConnectedUserWidget';
import Agents from './Agents';
import ConnectionUser from './ConnectionUser';
import ChatSection from './ChatSection';
import LiveVideo from './LiveVideo';
import './style.css';
import { useUser } from '../../utils/hooks/user';
import RequestCalendar from './RequestCalendar';
import { useRequest, useSocketListeners } from '../../utils/hooks';
import waits, { updateWait } from '../../utils/api/wait';
import { useEmitter } from '../../utils/react-listeners';
import useState from 'use-react-state';
import { readConversation } from '../../utils/api/message';
import { toast } from 'react-toastify';
import { Button, Row } from 'reactstrap';

const RealtimeChat = () => {
  const user = useUser();
  const [waitingCount, setWaitingCount] = useState(0);
  const [connectedCount, setConnectedCount] = useState(0);
  const [{ conversation_id, waitId, customer, startTime, isEndChatOpen }, setState] = useState({});
  const videoRef = useRef();
  const connectedRef = useRef();
  const company = user.company;

  useSocketListeners(
    useMemo(
      () => ({
        listeners: {
          'Message.created': ({ message }) => {
            if (message.user.id !== user.user.id) {
              const msgConvId = message.conversation.id || message.conversation;
              if (conversation_id === msgConvId) {
                connectedRef.current.readItem({ conversation: { id: msgConvId } });
              } else {
                connectedRef.current.setState((s) => {
                  s.data.data.map((d) => {
                    if (d.conversation.id === msgConvId) {
                      d.conversation.unread_count = d.conversation.unread_count + 1;
                    }
                    return d;
                  });
                });
              }
            }
          },
          'chat.ended': ({ waitId, customer }) => {
            toast(
              <>
                <h4 className='font-weight-bolder'>
                  {customer?.first_name} {customer?.last_name} Ended the chat
                </h4>
                <Row className='align-items-center justify-content-end'>
                  <Button
                    onClick={() => {
                      const list = connectedRef.current?.stateRef.current?.data?.data?.find(
                        ({ id }) => id === waitId
                      );
                      if (list) {
                        connectedRef.current?.click(list, true);
                      }
                    }}>
                    End engagement now
                  </Button>
                </Row>
              </>,
              {
                position: 'top-right',
                autoClose: false
              }
            );
          }
        }
      }),
      [user?.user?.id, conversation_id, connectedRef]
    )
  );

  useEffect(
    () => isEndChatOpen && setTimeout(() => setState({ isEndChatOpen: false }, 3000)),
    [isEndChatOpen]
  );

  return (
    <div>
      <div className='card-title pl-0 h3 mb-0 font-weight-bold text-nowrap'>Real Time Chat</div>
      <div className='d-flex flex-row justify-content-between align-items-center mt-3  '>
        <div className='col-6 px-0 mr-2'>
          <ConnectedUserWidget
            data={{
              status: 'Connected Users',
              count: connectedCount
            }}
          />
        </div>
        <div className='col-6 px-0 mr-2'>
          <ConnectedUserWidget data={{ status: 'Waiting Users', count: waitingCount }} />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-md-3'>
          <Agents hideTransfer waitId={waitId} setSession={setState} />
          <div style={styles.connections} className='flex-column'>
            <RenderConections
              company={company}
              user_id={user?.id}
              setCount={setConnectedCount}
              status='connected'
              setSession={setState}
              ref={connectedRef}
            />
            <RenderConections
              company={company}
              user_id={user?.id}
              setCount={setWaitingCount}
              status='waiting'
              setSession={setState}
            />
          </div>
        </div>
        <div className='col-md-5'>
          {conversation_id && (
            <ChatSection
              waitId={waitId}
              otherUser={customer}
              conversation_id={conversation_id}
              setSession={setState}
              startTime={startTime}
              videoRef={videoRef}
              isEndChatOpen={isEndChatOpen}
              key={conversation_id}
            />
          )}
        </div>
        <div className='col-md-4'>
          {conversation_id && <LiveVideo conversation_id={conversation_id} ref={videoRef} />}
          <RequestCalendar click={setState} />
        </div>
      </div>
    </div>
  );
};

const RenderConections = memo(
  forwardRef(({ company, status, setSession, setCount, user_id }, ref) => {
    const isConnected = status === 'connected';

    const {
      refresh,
      setState,
      state: { data: { data = [], total } } = {},
      stateRef
    } = useRequest(
      {
        asyncRequest: waits,
        params: [
          {
            company: company?.id || company,
            status,
            limit: 10,
            orderBy: isConnected ? 'updatedAt' : 'createdAt',
            order: !isConnected ? 'ASC' : 'DESC',
            connector: isConnected ? user_id : undefined
          }
        ],
        listeners: {
          'waits.refresh': () => refresh(),
          'waits.connected.refresh': () => isConnected && refresh()
        }
      },
      [isConnected, user_id]
    );

    useEffect(() => total !== undefined && setCount && setCount(total), [total]);

    const emitter = useEmitter();

    const click = useCallback(async (item, isEndChatOpen) => {
      try {
        setSession({
          waitId: item.id,
          conversation_id: item.conversation?.id || item.conversation,
          customer: item.customer,
          startTime: status === 'connected' ? item.updatedAt : new Date(),
          isEndChatOpen
        });

        if (status === 'connected') return;

        await updateWait(item.id, { status: 'connected' });
        emitter.emit('waits.refresh');
      } catch (error) {
        console.log({ error });
      }
    }, []);

    const listeners = useMemo(
      () =>
        isConnected
          ? {}
          : {
              'WaitList.created': ({ waitList }) => {
                setState(({ data } = {}) => {
                  data.data.push(waitList);
                  data.total = data.total + 1;
                });
              },
              'WaitList.connected': ({ waitList }) =>
                setState((s) => {
                  const index = s.data?.data?.findIndex(({ id }) => id === waitList.id);
                  if (index > -1) {
                    s.data.data.splice(index, 1);
                    s.data.total = s.data.total - 1;
                  }
                })
            },
      [data, isConnected]
    );

    const read = useCallback(async (i, item) => {
      try {
        setState((s) => {
          s.data.data[i].conversation.unread_count = 0;
        });
        await readConversation(item.conversation?.id || item.conversation);
      } catch (error) {
        setState((s) => {
          s.data.data[i].conversation.unread_count = item.conversation.unread_count;
        });
      }
    }, []);

    const readItem = useCallback(
      (item) => {
        const index = data.findIndex(
          ({ conversation }) => conversation.id === item.conversation.id
        );
        console.log({ item, index, data });
        index > -1 && read(index, item);
      },
      [data, read]
    );

    if (ref) {
      !ref.current && (ref.current = {});
      const props = { stateRef, setState, readItem, click };

      Object.keys(props).map((p) => (ref.current[p] = props[p]));
    }

    useSocketListeners({
      listeners
    });

    const Data = useCallback(
      () =>
        data.map((item, i) => (
          <ConnectionUser
            i={i}
            item={item}
            status={status}
            key={item.id}
            click={click}
            read={read}
          />
        )),
      [data, status, click, read]
    );

    return (
      <div style={styles.connection} className='my-3 scroller'>
        <Data />
      </div>
    );
  })
);

const styles = {
  connection: {
    maxHeight: '210px'
  },
  connections: {}
};

export default RealtimeChat;
