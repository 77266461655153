import React, { memo, useEffect } from 'react';
import {
  Spinner,
  Form,
  FormGroup,
  Label as BaseLabel,
  Input as BaseInput,
  Row,
  Col
} from 'reactstrap';
import api from '../../api';
import './style.css';
import { toast } from 'react-toastify';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { agentIcons } from '../../assets/icons';
import { updateAgent, viewAgent } from '../../utils/api/user';
import { useUser } from '../../utils/hooks/user';
import useState from 'use-react-state';
import { getTeams } from '../../utils/api/team';

const initialState = {
  password: '',
  confirmPassword: '',
  name: '',
  lastname: '',
  email: '',
  location: '',
  team: '',
  title: '',
  phoneMobile: '',
  genderSelected: 'male',
  profilePicture: null,
  isLoading: false
};

const Profile = () => {
  const profileData = useUser();
  const [{ agent, confirmPassword, isLoading, teams, uploadedFile }, setState] = useState({
    agent: profileData
  });

  const handleInputChange = ({ target: { value, name } }) =>
    setState((s) => {
      s.agent[name] = value;
    });

  const uploadAvatar = async (event) =>
    setState((s) => {
      s.agent.profile_picture = event.target.files[0];
      s.uploadedFile = URL.createObjectURL(event.target.files[0]);
    });

  const getProfileData = async () => {
    setState({ isLoading: true });

    const { data } = await viewAgent(profileData.id);
    setState({
      agent: data,
      isLoading: false
    });
  };

  const getAllTeams = async () => {
    try {
      const { data } = await getTeams();
      setState({ teams: data.data });
    } catch (error) {
      toast.error(`couldn't get Teams: ${error.message}`, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    getAllTeams();
    getProfileData();
  }, []);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setState({ isLoading: true });

      const formData = new FormData();
      ['first_name', 'last_name', 'gender', 'email', 'phone', 'location', 'title'].map((key) =>
        formData.append(key, agent[key])
      );

      typeof agent?.profile_picture === 'object' &&
        formData.append('profile_picture', agent.profile_picture);

      typeof agent?.team === 'string' && formData.append('team', agent.team);

      const { data } = await updateAgent(profileData.id, formData);

      toast.success('Profile Updated Successfully', { autoClose: 2000 });

      localStorage.setItem('profile', JSON.stringify(data));

      setState({ files: null, isLoading: false });
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 2000
      });
      setState({ isLoading: false });
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();
    setState({ isLoading: true });

    let response = await api.post('/password/update', {
      password: agent.password,
      confirmPassword: confirmPassword
    });

    if (response.status === 200) {
      setState(initialState);
      await getProfileData();
      toast.success(response.data?.message, {
        autoClose: 2000
      });
      localStorage.removeItem('token');
      window.location.reload();
    } else {
      setState(initialState);
      toast.error(response.data?.message, { autoClose: 2000 });
    }
  };

  return (
    <>
      <div className='profile-container mt-0'>
        <div className='container profile-header' style={{ width: '90%' }}>
          <h3>
            Profile Information <span> {'>'} Edit Profile</span>
          </h3>
        </div>
        {isLoading ? (
          <SkeletonTheme color='#eee'>
            <Skeleton width={700} height={10} count={5} className='ml-2' />
          </SkeletonTheme>
        ) : (
          <div className='container profile-details' style={{ width: '90%' }}>
            <Form onSubmit={handleSubmit}>
              <Row className='py-5 pl-5'>
                <Col md={6} className='d-flex flex-column align-items-start justify-content-center'>
                  <div className='mb-4 ml-auto mr-auto iconImage' style={{ paddingBottom: '3rem' }}>
                    <label htmlFor='upload-avatar'>
                      <span
                        style={{
                          borderRadius: '50%',
                          cursor: 'pointer',
                          display: 'block',
                          overflow: 'hidden',
                          position: 'relative'
                        }}>
                        <img
                          src={uploadedFile || agent?.profile_picture}
                          alt='Avatar'
                          className='avatar'
                        />
                        <span
                          style={{
                            position: 'absolute',
                            bottom: '0px',
                            textAlign: 'center',
                            top: 'auto',
                            height: '25px',
                            width: '100%',
                            backgroundColor: 'black',
                            opacity: '0.3',
                            display: 'block'
                          }}>
                          <img alt='' src={agentIcons['noun_Camera_1740400.svg'].default} />
                        </span>
                      </span>
                    </label>
                    <input
                      type='file'
                      id='upload-avatar'
                      onChange={uploadAvatar}
                      className='d-none'
                    />
                  </div>

                  <div className='d-flex w-100 justify-content-between my-2'>
                    <FormGroup className='w-75 font-weight-bold'>
                      <Label className='label-title' for='first_name'>
                        First Name
                      </Label>
                      <Input
                        name='first_name'
                        value={agent.first_name}
                        onChange={handleInputChange}
                        placeholder='Enter name'
                        className='textbox'
                      />
                    </FormGroup>
                    <FormGroup className='w-75 ml-4 font-weight-bold'>
                      <Label className='label-title' for='last_name'>
                        Last Name
                      </Label>
                      <Input
                        name='last_name'
                        value={agent.last_name}
                        onChange={handleInputChange}
                        placeholder='Enter last name'
                        className='textbox'
                      />
                    </FormGroup>
                  </div>
                  <FormGroup className='w-75 mt-4 font-weight-bold'>
                    <Label className='label-title' for='email'>
                      Email
                    </Label>
                    <Input
                      name='email'
                      onChange={handleInputChange}
                      placeholder='Enter email'
                      value={agent.email}
                      className='textbox'
                    />
                  </FormGroup>
                  {/*Gender Section*/}
                  <FormGroup
                    className='d-flex justify-content-between align-items-center my-2 font-weight-bold'
                    check
                    inline>
                    <Label className='mr-4 mb-0 label-title'>Gender:</Label>
                    <Input
                      style={{ width: 15, height: 15 }}
                      type='radio'
                      value='male'
                      onChange={handleInputChange}
                      name='gender'
                      checked={agent.gender === 'male'}
                      required
                    />{' '}
                    Male
                    <Input
                      style={{ marginLeft: '15px', width: 15, height: 15 }}
                      type='radio'
                      value='female'
                      onChange={handleInputChange}
                      name='gender'
                      checked={agent.gender === 'female'}
                      required
                    />{' '}
                    Female
                  </FormGroup>

                  {/* </Form> */}
                </Col>
                <Col md={6} className='pt-5'>
                  <FormGroup className='my-4 font-weight-bold w-75'>
                    <Label className='label-title' for='exampleEmail'>
                      Location
                    </Label>
                    <Input
                      name='location'
                      onChange={handleInputChange}
                      placeholder='Enter location'
                      value={agent.location}
                      className='textbox'
                    />
                  </FormGroup>

                  <FormGroup className='font-weight-bold w-75'>
                    <Label className='label-title' for='phone'>
                      Phone Number
                    </Label>
                    <Input
                      name='phone'
                      onChange={handleInputChange}
                      value={agent.phone}
                      placeholder='Enter phone number'
                      className='textbox'
                    />
                  </FormGroup>
                  <FormGroup className='my-4 font-weight-bold w-75'>
                    <Label className='label-title' for='team'>
                      Team
                    </Label>
                    <Input
                      name='team'
                      type='select'
                      onChange={handleInputChange}
                      value={agent.team?.id}
                      defaultValue={agent.team?.id}
                      className='textbox'>
                      <option disabled>Team</option>
                      {teams?.map((item) => (
                        <option key={item.team_id} value={item.team_id}>
                          {item?.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup className='my-4 font-weight-bold w-75'>
                    <Label className='label-title' for='title'>
                      Job Title
                    </Label>
                    <Input
                      onChange={handleInputChange}
                      name='title'
                      placeholder='Enter Job Title'
                      value={agent.title}
                      className='textbox'
                    />
                  </FormGroup>
                </Col>
                <div className='form-group d-flex justify-content-center w-100 mt-5 text-center my-2'>
                  <button
                    disabled={isLoading}
                    type='submit'
                    style={{
                      width: 333,
                      height: 40,
                      backgroundColor: '#083147',
                      borderRadius: 10,
                      fontWeight: 600
                    }}
                    className='btn text-white mr-3'>
                    {isLoading ? <Spinner color='light' size='sm' /> : ''} UPDATE PROFILE
                  </button>
                </div>
              </Row>
            </Form>
          </div>
        )}
        <div className='container profile-header mt-4' style={{ width: '90%' }}>
          <h3>
            Profile Information <span> {'>'} Change Password</span>
          </h3>
        </div>
        <div className='container profile-details' style={{ width: '90%' }}>
          <Form onSubmit={changePassword}>
            <div className='row py-5 pl-4'>
              <div className='col-6 d-flex align-items-start justify-content-start'>
                <FormGroup className='w-75 font-weight-bold'>
                  <Label className='label-title' for='exampleEmail'>
                    New Password
                  </Label>
                  <Input
                    name='password'
                    type='password'
                    value={agent.password}
                    onChange={handleInputChange}
                    id='exampleFormControlInput1'
                    placeholder='Password'
                    className='textbox'
                  />
                </FormGroup>
              </div>
              <div className='col-5 d-flex align-items-start justify-content-start'>
                <FormGroup className='w-100 font-weight-bold'>
                  <Label className='label-title' for='exampleEmail'>
                    Confirm New Password
                  </Label>
                  <Input
                    name='confirmPassword'
                    type='password'
                    value={confirmPassword}
                    onChange={handleInputChange}
                    id='exampleFormControlInput1'
                    placeholder='Confirm Password'
                    className='textbox'
                  />
                </FormGroup>
              </div>
              <div className='form-group d-flex justify-content-center w-100 mt-5 text-center my-2'>
                <button
                  disabled={isLoading}
                  type='submit'
                  style={{
                    width: 333,
                    height: 40,
                    backgroundColor: '#083147',
                    borderRadius: 10,
                    fontWeight: 600
                  }}
                  className='btn text-white mr-3'>
                  CHANGE PASSWORD
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

const Input = memo(({ className = '', style, ...p }) => (
  <BaseInput
    {...p}
    style={{
      borderWidth: 1,
      borderColor: '#000',
      fontWeight: 300,
      fontSize: 18,
      height: 35,
      ...style
    }}
    className={className + ' border-3'}
  />
));

const Label = memo(({ className = '', style, children, ...p }) => (
  <BaseLabel {...p} style={{ fontWeight: 400, fontSize: 18, ...style }} className={className + ' '}>
    {children}
  </BaseLabel>
));

export default Profile;
