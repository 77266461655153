const theme = {
  colors: {
    primary: "#083147",
    secondary: "#FF2D55",
    grey: "#828282",
    light_grey: "#F8F9FA",
    red: "#083147",
    muted: "#F0F3F8",
  },
};

export default theme;
