import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './reactQuery';
import ContextProvider, { DeviceProvider } from './contexts';
import './assets/css/ionicons.min.css';
import './index.sass';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoProvider } from 'socket.io-react-hook';
import ErrorHandler from './components/ErrorHandler';
import { ErrorBoundary } from 'react-error-boundary';

ReactDOM.render(
  <React.StrictMode>
    <DeviceProvider>
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={ErrorHandler}>
          <QueryClientProvider client={queryClient}>
            <ContextProvider>
              <IoProvider>
                <ToastContainer />
                <App />
              </IoProvider>
            </ContextProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </DeviceProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
