import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { CopyBlock, atomOneDark } from 'react-code-blocks';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const MobileCodeBlockModal = ({ closeMobileDialog, mobileDialog, company }) => {
  return (
    <div style={{ fontSize: '22px' }}>
      <Dialog
        maxWidth='md'
        onClose={closeMobileDialog}
        aria-labelledby='customized-dialog-title'
        open={mobileDialog}>
        <DialogTitle id='customized-dialog-title' onClose={closeMobileDialog}>
          {company.name} SDK Script
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom style={{ marginBottom: '15px' }} color='primary' variant='h6'>
            Import Widget from npm package
          </Typography>
          <Typography gutterBottom>
            <h5>
              <CopyBlock
                text={`import {Widget, setConfig} from '@sawport/react-native-sdk';

setConfig({host: '${process.env.REACT_APP_API_URL}', baseURL: '${process.env.REACT_APP_API_URL}' });

function App() {
  return <Widget projectId='${company.id}' style={styles.widget} />;
}`}
                language='html'
                theme={atomOneDark}
                wrapLines
                codeBlock
              />
            </h5>
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default MobileCodeBlockModal;
