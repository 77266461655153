import React, { useState } from 'react';
import SecuritySideBar from '../SettingSideBar';
import Tab from '../Tab';
import '../style.css';

const SettingsWrapper = ({ children, nav }) => {
  const [active, setActive] = useState(0);

  return (
    <div className='d-flex mt-4 main-wrapper'>
      <SecuritySideBar sidebars={nav} activeTab={active} selectTab={setActive} />
      {React.Children.map(children, (child, ind) => (
        <Tab itemKey={ind} isActive={ind === active}>
          {child}
        </Tab>
      ))}
    </div>
  );
};

export default SettingsWrapper;
