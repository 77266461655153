import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { CopyBlock, atomOneDark, dracula } from 'react-code-blocks';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const CodeBlockModal = ({ closeDialog, dialog, company }) => {
  return (
    <div style={{ fontSize: '22px' }}>
      <Dialog
        maxWidth='md'
        onClose={closeDialog}
        aria-labelledby='customized-dialog-title'
        open={dialog}>
        <DialogTitle id='customized-dialog-title' onClose={closeDialog}>
          {company.name} SDK Script
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom style={{ marginBottom: '15px' }} color='primary' variant='h6'>
            Please paste this CSS link to the HTML header
          </Typography>
          <Typography gutterBottom>
            <h5>
              <CopyBlock
                text={`<link href="https://unpkg.com/@sawport/react-sdk/dist/style.css" rel="stylesheet" />`}
                language='html'
                theme={dracula}
                wrapLines
                codeBlock
              />
            </h5>
          </Typography>
          <Typography gutterBottom style={{ marginBottom: '15px' }} color='primary' variant='h6'>
            Please add div to HTML body that holds the sawport widget
          </Typography>
          <Typography gutterBottom>
            <h5>
              <CopyBlock
                text={`<div id="sawport-widget"></div>`}
                language='html'
                theme={dracula}
                wrapLines
                codeBlock
              />
            </h5>
          </Typography>
          <Typography gutterBottom style={{ marginBottom: '15px' }} color='primary' variant='h6'>
            Please add script link to HTML body that holds the sawport widget
          </Typography>
          <Typography gutterBottom>
            <h5>
              <CopyBlock
                text={`<script>function docReady(fn) {
        ['complete', 'interactive'].includes(document.readyState)
          ? setTimeout(fn, 1)
          : document.addEventListener('DOMContentLoaded', fn);
      }

    docReady(() => sawportSDK.mountWidget({baseURL: '${process.env.REACT_APP_BASE_URL}', apiURL: '${process.env.REACT_APP_API_URL}', projectId: '${company.id}' }, 'sawport-widget'))
              </script>`}
                language='html'
                theme={atomOneDark}
                wrapLines
                codeBlock
              />
            </h5>
          </Typography>
          <Typography gutterBottom style={{ marginBottom: '15px' }} color='primary' variant='h6'>
            Please add div to HTML body that holds the sawport widget
          </Typography>
          <Typography gutterBottom>
            <h5>
              <CopyBlock
                text={`<script>function docReady(fn) {
        ['complete', 'interactive'].includes(document.readyState)
          ? setTimeout(fn, 1)
          : document.addEventListener('DOMContentLoaded', fn);
      }

    docReady(() => sawportSDK.mountWidget({baseURL: '${process.env.REACT_APP_BASE_URL}', apiURL: '${process.env.REACT_APP_API_URL}', projectId: '${company.id}' }, 'sawport-widget'))
              </script>`}
                language='html'
                theme={atomOneDark}
                wrapLines
                codeBlock
              />
            </h5>
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default CodeBlockModal;
