import api from "../../api";

export const getTeams = (params) => api.get("teams", { params });

export const viewTeam = (id, params) => api.get("teams/" + id, { params });

export const updateTeam = (id, params) => api.put("teams/" + id, params);

export const createTeam = (params) => api.post("teams", params);

export const deleteTeam = (id, params) => api.delete("teams/" + id, params);
