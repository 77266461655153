const agentIcons = {};
const coloredIcons = {};
const whiteIcons = {};
const greyIcons = {};

const agentR = require.context('./agent', false, /\.(svg)$/);
agentR.keys().map((item, index) => agentIcons[item.replace('./', '')] = agentR(item));

const coloredR = require.context('./colored', false, /\.(svg)$/);
coloredR.keys().map((item, index) => coloredIcons[item.replace('./', '')] = coloredR(item));

const whiteR = require.context('./white', false, /\.(svg)$/);
whiteR.keys().map((item, index) => whiteIcons[item.replace('./', '')] = whiteR(item));

const greyR = require.context('./grey', false, /\.(svg)$/);
greyR.keys().map((item, index) => greyIcons[item.replace('./', '')] = greyR(item));

export {
    agentIcons,
    coloredIcons,
    whiteIcons,
    greyIcons
};