const ROUTES = {
  DASHBOARD: '/',
  ADMIN: 'admin',
  REAL_TIME_CHAT: '/realtime-chat',
  OFLINE_CHAT: '/offline-chat',
  CHAT_RECORDS: '/chat-records',
  REAL_TIME_VISITORS: '/realtime-visitors',
  REPORTS: '/reports',
  AGENTS: '/agents',
  TRIGGERS: '/triggers',
  LOGIN: '/auth/login',
  CHAT_RECORD: '/chat-history/:conversationId/:customerId/:agentId',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  ACTIVITY_LOGS: '/activity-logs',
  TEAM: '/team',
  CALL_REQUESTS: '/call-requests',
  PUBLIC_CHAT: '/public-chat',
  PUBLIC_CHAT_WIDGET: '/public-chat/widget',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:id/:token',
  WIDGET_SETTINGS: '/widget_settings',
  SECURITY: '/security-settings',
  SDK: '/sdk'
};

export default Object.freeze(ROUTES);
