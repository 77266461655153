export const groupMessagesByDate = (dataArray = []) => {
  const groupedData = dataArray.reduce((map, message) => {
    const date = message?.createdAt.split('T')[0];

    if (!map.has(date)) {
      map.set(date, [message]);
    } else {
      const newMessages = map.get(date);
      newMessages.unshift(message);
      map.set(date, newMessages);
    }

    return map;
  }, new Map());

  const entries = groupedData.entries();
  const groupedMessageArrays = [];

  for (const [key, messageGroup] of entries) {
    groupedMessageArrays.unshift({ date: key, messages: messageGroup });
  }

  return groupedMessageArrays;
};

export const capitalizeFirstLetter = (string) => {
  let arrayOfSplitString = string.split(' ');
  let result = [];
  for (let index = 0; index < arrayOfSplitString.length; index++) {
    result.push(
      arrayOfSplitString[index].charAt(0).toUpperCase() + arrayOfSplitString[index].substring(1)
    );
  }
  return result.join(' ');
};

export const copyTextToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
};

export const downloadCSV = (data, filename) => {
  const link = document.createElement('a');
  if (data == null) return;

  filename = `${filename}.csv`;

  if (!data.match(/^data:text\/csv/i)) {
    data = `data:text/csv;charset=utf-8,${data}`;
  }

  link.setAttribute('href', encodeURI(data));
  link.setAttribute('download', filename);
  link.click();
};

export const parseIPv6ToString = (ipv6string) => {
  const convertedIPv6String = ipv6string.toString();

  // Initialize
  const arrayOfIpv6String = [];
  for (let index = 0; index < 8; index++) arrayOfIpv6String[index] = 0;

  // Check for trivial IPs
  if (convertedIPv6String === '::') return arrayOfIpv6String;

  // Parse
  const parse = convertedIPv6String.split(':');
  let stringLength = parse.length;
  if (stringLength > 8) stringLength = 8;
  let j = 0;
  let index = 0;
  for (index = 0; index < stringLength; index++) {
    // This is a "::", switch to end-run mode
    if (index && stringLength[index] === '') {
      j = 9 - stringLength + index;
      continue;
    }
    arrayOfIpv6String[j] = parseInt(`0x0${parse[index]}`);
    j++;
  }

  return arrayOfIpv6String;
};

export const convertIPv6AddresstoIPv4 = (ip6Address) => {
  const parsedString = parseIPv6ToString(ip6Address);

  const ip4Address = `${parsedString[6] >> 8}.${parsedString[6] & 0xff}.${parsedString[7] >> 8}.${
    parsedString[7] & 0xff
  }`;

  if (ip4Address === '0.0.0.0') {
    return ip6Address; // For UI purposes
  }

  return ip4Address;
};
