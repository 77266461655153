import { useCallback, useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import useState from 'use-react-state';
import { useSocket, useSocketListeners } from '.';
import useEventListener from '../react-listeners';
import iceServers from '../../constants/iceServers';
import callignSound from '../../assets/audio/calling.mp3';

const peerConnectionConfig = {
  offerToReceiveAudio: true,
  offerToReceiveVideo: true,
  iceServers
};

export default function useVideoCalling({ userId, conversation_id }) {
  const { RTCPeerConnection, RTCSessionDescription, RTCIceCandidate } = window;
  const [
    { isIncoming, isOutgoing, muted, screenShared, isCalling, getCalled, videoOff, sound },
    setState,
    stateRef
  ] = useState({
    isIncoming: false,
    isOutgoing: false,
    muted: false,
    screenShared: false,
    isCalling: false,
    getCalled: false,
    videoOff: false,
    sound: new Audio()
  });

  const ref = useRef({
    localStream: null,
    screenStream: null,
    peerConnection: new RTCPeerConnection(peerConnectionConfig),
    timeout: null,
    data: null,
    isInit: false,
    localVideoTrack: null,
    connected: false,
    localICECandidates: []
  });

  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);

  const { socket } = useSocket();

  const playSound = useCallback(
    (src, callOn) => {
      sound.src = src;
      callOn && callOn(sound);
      sound.play();
    },
    [sound]
  );

  const pauseSound = useCallback(() => sound.pause(), [sound]);
  const resumeSound = useCallback(() => sound.resume(), [sound]);
  const stopSound = useCallback(() => {
    sound.pause();
    sound.srcObject = null;
  }, [sound]);

  const getMediaStream = useCallback(
    () =>
      navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      }),
    []
  );

  const addStreamToVideo = useCallback(
    (stream) => {
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
        localVideoRef.current.volume = 0;
      }
      ref.current.isInit = true;
    },
    [ref, localVideoRef]
  );

  const initMedia = useCallback(async () => {
    if (ref.current.isInit) {
      return ref.current.localStream;
    }

    const stream = await getMediaStream();
    ref.current.localStream = stream;

    addStreamToVideo(stream);
    return stream;
  }, [ref, getMediaStream, addStreamToVideo]);

  const callUser = useCallback(
    async (otherUserId) => {
      await initMedia();

      setState({ isOutgoing: true, callUserId: otherUserId });

      socket.emit('call-token');
    },
    [socket.connected, initMedia]
  );

  const setBandwidth = useCallback(async () => {
    if ('RTCRtpSender' in window && 'setParameters' in window.RTCRtpSender.prototype) {
      const sender = ref.current.peerConnection.getSenders()[0];
      const parameters = sender.getParameters();
      if (!parameters.encodings) {
        parameters.encodings = [{}];
      }
      parameters.encodings[0].maxBitrate = 200 * 1000;

      try {
        await sender.setParameters(parameters);
      } catch (error) {
        console.error(error);
      }
    }
  }, [ref]);

  const callCleanup = useCallback(
    (setUp) => {
      if (ref.current.localStream) {
        const { peerConnection } = ref.current;
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture();
        }
        peerConnection.close();
        peerConnection.onicecandidate = null;
        peerConnection.ontrack = null;
        peerConnection.onaddstream = null;

        localVideoRef.current && (localVideoRef.current.srcObject = null);
        remoteVideoRef.current && (remoteVideoRef.current.srcObject = null);
        ref.current.localStream.getTracks().forEach((track) => track.stop());

        if (setUp) {
          ref.current.peerConnection = new RTCPeerConnection(peerConnectionConfig);
        }
      }
    },
    [ref, localVideoRef, remoteVideoRef]
  );

  const clearCalls = (append = {}) => {
    ref.current.data = null;
    setState({
      isIncoming: false,
      isOutgoing: false,
      getCalled: false,
      isCalling: false,
      muted: false,
      screenShared: false,
      videoOff: false,
      ...append
    });
    stopSound();
  };

  const initPeer = useCallback(
    ({ response }) => {
      ref.current.peerConnection = new RTCPeerConnection({
        iceServers: response.iceServers,
        offerToReceiveAudio: true,
        offerToReceiveVideo: true
      });

      ref.current.peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          if (ref.current.connected) {
            socket.emit('candidate', {
              candidate: event.candidate,
              to: ref.current.data?.from,
              from: userId
            });
          } else {
            ref.current.localICECandidates.push(event.candidate);
          }
        }
      };

      ref.current.localStream && ref.current.peerConnection.addStream(ref.current.localStream);

      ref.current.peerConnection.onconnectionstatechange = async () => {
        const connection = ref.current.peerConnection;
        switch (connection.connectionState) {
          case 'connected':
            ref.current.connected = true;
            ref.current.localVideoTrack = ref.current.localStream.getVideoTracks()[0];

            const { peerConnection, localStream, localVideoTrack } = ref.current;

            if (localVideoTrack) {
              localStream.addTrack(localVideoTrack);

              const sender = peerConnection
                .getSenders()
                .find((s) => s.track.kind === localVideoTrack.kind);

              sender.replaceTrack(localVideoTrack);
            }

            ref.current.localICECandidates.forEach((candidate) => {
              socket.emit('candidate', {
                candidate,
                to: ref.current.data?.from,
                from: userId
              });
            });
            ref.current.localICECandidates = [];
            break;
          case 'failed':
            const offer = await connection.createOffer({
              iceRestart: true,
              offerToReceiveVideo: true
            });
            connection.setLocalDescription(new RTCSessionDescription(offer));
            // caluser with desc
            break;
          default:
            break;
        }
      };

      ref.current.peerConnection.onaddstream = function ({ stream }) {
        if (remoteVideoRef.current && stream && ref.current.remoteStream !== stream) {
          ref.current.remoteStream = stream;
          remoteVideoRef.current.srcObject = stream;
        }
      };

      ref.current.peerConnection.ontrack = function ({ streams: [stream, ...streams], track }) {
        if (ref.current.expectScreenShare) {
          ref.current.remoteStream.addTrack(track);
          ref.current.expectScreenShare = false;
          return;
        }

        if (remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = stream;
        }
      };
    },
    [ref, socket.connected, userId, remoteVideoRef]
  );

  const answerIncoming = useCallback(
    async (isIncoming = false) => {
      stopSound();
      const data = ref.current.data;
      ref.current.connected = true;

      if (isIncoming) {
        const stream = await initMedia();

        initPeer({ response: data.response });

        stream.getTracks().forEach((track) => {
          ref.current.peerConnection.addTrack(track, stream);
        });

        setState({ isIncoming: false, isCalling: true, getCalled: true });
      } else {
        setState({ isCalling: true, getCalled: true });
      }

      await ref.current.peerConnection.setRemoteDescription(new RTCSessionDescription(data.offer));
      const answer = await ref.current.peerConnection.createAnswer();
      await ref.current.peerConnection.setLocalDescription(answer);

      socket.emit('answer-incoming-call', {
        answer,
        to: data.from,
        from: data.to,
        conversation_id
      });
    },
    [socket.connected, conversation_id, stateRef, ref, initMedia, initPeer, stopSound]
  );

  const sendOffer = useCallback(
    async ({ response }) => {
      const offer = await ref.current.peerConnection.createOffer({
        offerToReceiveVideo: true
      });

      await ref.current.peerConnection.setLocalDescription(offer);

      socket.emit('outgoing-call', {
        response,
        offer,
        to: stateRef.current.callUserId,
        from: userId
      });
    },
    [ref, stateRef, socket.connected, userId]
  );

  const listeners = useMemo(() => {
    if (userId) {
      const incoming = async (data) => {
        ref.current.data = data;

        if (!stateRef.current.getCalled) {
          playSound(callignSound, (sound) => (sound.loop = true));
          setState({ isIncoming: true });
          return;
        }

        answerIncoming();
      };

      const answered = async (data) => {
        await ref.current.peerConnection.setRemoteDescription(
          new RTCSessionDescription(data.answer)
        );

        ref.current.data = data;
        ref.current.connected = true;

        ref.current.localICECandidates.forEach((candidate) => {
          socket.emit('candidate', {
            candidate,
            to: ref.current.data?.from,
            from: userId
          });
        });
        ref.current.localICECandidates = [];

        if (!stateRef.current.isCalling) {
          setBandwidth();

          setState({ isCalling: true, isOutgoing: false });
        } else {
          setState({ isOutgoing: false });
        }
      };

      const reject = () => {
        ref.current.isInit = false;
        clearCalls({ callUserId: undefined });
        callCleanup(true);
        toast.error('Call Rejected');
      };

      const ended = () => {
        ref.current.isInit = false;
        clearCalls({ callUserId: undefined });
        callCleanup(true);
      };

      const onExpectScreenShare = (data) => (ref.current.expectScreenShare = data.value);

      const candidate = ({ candidate }) => {
        candidate && ref.current.peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      };

      async function onToken(response) {
        initPeer({ response });

        sendOffer({ response });
      }

      return {
        'incoming-call': incoming,
        'outgoing-answered': answered,
        'outgoing-rejected': reject,
        'call-ended': ended,
        expectScreenShare: onExpectScreenShare,
        candidate,
        'call-token': onToken
      };
    }
  }, [
    userId,
    stateRef,
    answerIncoming,
    setBandwidth,
    initMedia,
    ref,
    initPeer,
    sendOffer,
    playSound
  ]);

  useSocketListeners({ listeners });

  useEffect(() => {
    return () => callCleanup();
  }, [callCleanup]);

  useEventListener(
    {
      listeners: {
        'calls.call.customer': (otherUserId) => callUser(otherUserId)
      }
    },
    [callUser]
  );

  useEffect(() => {
    if (isIncoming) {
      ref.current.timeout = setTimeout(() => clearCalls(), 30000);

      return () => clearTimeout(ref.current.timeout);
    } else {
      clearTimeout(ref.current.timeout);
    }
  }, [isIncoming, ref]);

  useEffect(() => {
    if (isOutgoing) {
      ref.current.timeout = setTimeout(() => clearCalls(), 30000);

      return () => clearTimeout(ref.current.timeout);
    } else {
      clearTimeout(ref.current.timeout);
    }
  }, [isOutgoing, ref]);

  const answerCall = useCallback(() => {
    answerIncoming(true);
  }, [answerIncoming]);

  const rejectCall = useCallback(() => {
    const data = ref.current.data;

    socket.emit('reject-incoming', {
      to: data.from,
      from: userId,
      conversation_id
    });
    clearCalls();
  }, [socket.connected, conversation_id, userId, ref]);

  const endCall = useCallback(() => {
    if (stateRef.current.isCalling) {
      const data = ref.current.data;

      ref.current.isInit = false;
      clearCalls({ callUserId: undefined });
      callCleanup(true);

      socket.emit('end-call', {
        to: data?.from,
        from: userId,
        conversation_id
      });
    }
  }, [socket.connected, conversation_id, userId, ref]);

  const mute = useCallback(() => {
    ref.current.localStream.getAudioTracks().forEach((track) => (track.enabled = muted));

    if (localVideoRef.current) {
      localVideoRef.current.muted = !muted;
      setState({ muted: !muted });
    }
  }, [muted, ref, localVideoRef]);

  async function startScreenSharing() {
    try {
      ref.current.screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          cursor: 'always'
        },
        audio: false
      });
    } catch (e) {
      toast.error('Could not share the screen, please check the error and try again. ' + e);
    }

    const { peerConnection, localStream, screenStream } = ref.current;

    if (screenStream) {
      setState({ screenShared: true });

      const screenVideoTrack = screenStream.getVideoTracks()[0];

      const sender = peerConnection
        .getSenders()
        .find((s) => s.track?.kind === screenVideoTrack.kind);
      if (sender) {
        ref.current.localVideoTrack = localStream.getVideoTracks()[0];

        ref.current.localVideoTrack.enabled = false;

        addStreamToVideo(screenStream);

        sender.replaceTrack(screenVideoTrack);
      } else {
        console.error('Track sender not found');
      }

      screenVideoTrack.addEventListener('ended', () => stopScreenSharing());
    }
  }

  function stopScreenSharing() {
    const { peerConnection, localStream, localVideoTrack, data, screenStream } = ref.current;
    screenStream.getVideoTracks().forEach((track) => track.stop());

    if (localVideoTrack) {
      localStream.addTrack(localVideoTrack);

      const sender = peerConnection
        .getSenders()
        .find((s) => s.track?.kind === localVideoTrack.kind);

      ref.current.localVideoTrack.enabled = true;
      addStreamToVideo(localStream);

      sender.replaceTrack(localVideoTrack);
    } else {
      socket.emit('expectScreenShare', {
        removeTrack: true,
        to: data.from,
        from: data.to
      });
    }
    // ref.current.screenStream = null;
    setState({ screenShared: false });
  }

  const fullScreen = useCallback(async () => {
    try {
      if (remoteVideoRef.current) {
        await remoteVideoRef.current.requestFullscreen();
      }
    } catch (err) {
      console.log('An error occurred, please try again later.');
    }
  }, [remoteVideoRef]);

  const toggleVideo = useCallback(() => {
    ref.current.localStream.getVideoTracks().forEach((track) => (track.enabled = videoOff));

    setState({ videoOff: !videoOff });

    toast.success(videoOff ? 'Camera has been turned on.' : 'Camera has been turned off.');
  }, [videoOff, ref]);

  useEffect(() => {
    const actions = document.querySelector('#video-call-actions');
    const vCont = document.querySelector('#video-call-container');

    const mouseover = () => (actions.style.display = 'flex');

    const mouseleave = () => (actions.style.display = 'none');

    vCont?.addEventListener('mouseover', mouseover);

    vCont?.addEventListener('mouseleave', mouseleave);

    return () => {
      vCont?.removeEventListener('mouseover', mouseover);
      vCont?.removeEventListener('mouseleave', mouseleave);
      stopSound();
    };
  }, []);

  return {
    RTCPeerConnection,
    RTCSessionDescription,
    isIncoming,
    ref,
    callUser,
    callCleanup,
    clearCalls,
    listeners,
    answerIncoming,
    answerCall,
    rejectCall,
    endCall,
    isOutgoing,
    mute,
    muted,
    fullScreen,
    startScreenSharing,
    stopScreenSharing,
    screenShared,
    isCalling,
    getCalled,
    videoOff,
    toggleVideo,
    localVideoRef,
    remoteVideoRef,
    playSound,
    pauseSound,
    resumeSound,
    stopSound
  };
}
