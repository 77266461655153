export const mask = (str = '', maskChar, unmaskedLength, maskFromStart = true) => {
  str = str.toString();
  const maskStart = maskFromStart ? 0 : Math.max(0, unmaskedLength);
  const maskEnd = maskFromStart ? Math.max(0, str.length - unmaskedLength) : str.length;
  return str
    ?.split('')
    .map((char, index) => {
      if (index >= maskStart && index < maskEnd) {
        return maskChar;
      } else {
        return char;
      }
    })
    .join('');
};

const exts = {
  gif: 'image',
  jpeg: 'image',
  jpg: 'image',
  png: 'image',
  tif: 'image',
  tiff: 'image',
  wbmp: 'image',
  ico: 'image',
  jng: 'image',
  bmp: 'image',
  svg: 'image',
  webp: 'image'
};

export const isFileType = (ext) => exts[ext];
