import React, { memo, forwardRef } from 'react';
import { useUser } from '../../utils/hooks/user';
import VideoChat, { IncomingVideoCallModal } from '../common/VideoChat';
import useVideoCalling from '../../utils/hooks/video';

const LiveVideo = memo(forwardRef(({ conversation_id }, parentRef) => {
  const userId = useUser(({ user } = {}) => user?.id || user);
  const {
    endCall,
    isIncoming,
    answerCall,
    rejectCall,
    ref,
    isOutgoing,
    muted,
    mute,
    fullScreen,
    startScreenSharing,
    stopScreenSharing,
    screenShared,
    isCalling,
    getCalled,
    videoOff,
    toggleVideo,
    remoteVideoRef,
    localVideoRef,
    playSound,
    pauseSound,
    resumeSound,
    stopSound,
  } = useVideoCalling({
    userId,
    conversation_id
  });

  if (parentRef) {
    if (!parentRef.current) {
      parentRef.current = {}
    }
    const props = {
      playSound, pauseSound, resumeSound, stopSound,
    }
    Object.keys(props).map((prop) => (parentRef.current[prop] = props[prop]))
  }

  return (
    <div>
      <VideoChat
        isVisible={isIncoming || isOutgoing || isCalling || getCalled}
        endCall={endCall}
        mute={mute}
        muted={muted}
        fullScreen={fullScreen}
        startScreenSharing={startScreenSharing}
        stopScreenSharing={stopScreenSharing}
        screenShared={screenShared}
        videoOff={videoOff}
        toggleVideo={toggleVideo}
        remoteVideoRef={remoteVideoRef}
        localVideoRef={localVideoRef}
      />
      <IncomingVideoCallModal
        isIncoming={isIncoming}
        answerCall={answerCall}
        rejectCall={rejectCall}
        videoRef={ref}
      />
    </div>
  );
}));

export default LiveVideo;
