import React from "react";

function ActiveLog() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            stroke='currentColor'
            width='22'
            height='22'
            viewBox='0 0 24 24'
        >
            <path
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    d='M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4'
    />
        </svg>
    );
}

export default ActiveLog;
