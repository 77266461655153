import React, { useCallback, useMemo, useState } from 'react';
import { Col, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Copy, Download, Search } from '../../assets/icons/jsxIcons';
import { Link } from 'react-router-dom';
import './style.css';
import moment from 'moment';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { RealTimeVistor } from '../../assets/icons/jsxIcons';
import DataTable from 'react-data-table-component';
import { useRequest } from '../../utils/hooks';
import { getChatRecords } from '../../utils/api/chatRecords';
import { copyTextToClipboard, downloadCSV } from '../../utils/file';
import { toast } from 'react-toastify';
import api from '../../api';

const ChatRecords = () => {
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [perPage, setPerpage] = useState(15);

  const handleCopyClick = (text) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(text)
      .then(() => {
        toast.success('Email copied to clipboard', {
          autoClose: 2000
        });
      })
      .catch((err) => {
        toast.error('Error occured, try again', { autoClose: 2000 });
      });
  };

  const handleCsvDownloadClick = async (event) => {
    try {
      const response = await api.get('conversations/csv');
      if (response.status === 200) {
        downloadCSV(response?.data?.csv, 'Chat Records');
      }
    } catch (error) {
      toast.error('Unable to retrieve records', { autoClose: 2000 });
    }
  };

  const {
    next,
    state: {
      data: { data: record, total },
      isLoading
    }
  } = useRequest(
    {
      asyncRequest: getChatRecords,
      params: [
        {
          limit: perPage
        }
      ],
      infinite: false
    },
    [perPage]
  );

  // const startInd = useMemo(
  //   () => currentPage * limit - limit,
  //   [currentPage, limit]
  // );
  const columns = useMemo(
    () => [
      // {
      //   name: '#',
      //   field: '',
      //   style: {
      //     paddingRight: '0px',
      //   },
      //   selector: (row) => {
      //     return (
      //       startInd + (record?.findIndex((item) => item.id === row.id) + 1)
      //     );
      //   },
      // },
      {
        name: 'Name',
        id: 'name',
        grow: 1.5,
        selector: (row) => {
          return (
            <>
              <p className='d-flex align-items-center justify-content-start mb-0'>
                {row.participants[0]?.user?.customer
                  ? `${row.participants[0]?.user?.customer?.first_name} ${' '} ${
                      row.participants[0]?.user?.customer?.last_name
                    }`
                  : row.participants[1]?.user?.customer
                  ? `${row.participants[1]?.user?.customer?.first_name} ${' '} ${
                      row.participants[1]?.user?.customer?.last_name
                    }`
                  : '-'}
              </p>
            </>
          );
        }
      },
      {
        name: 'Email',
        id: 'email',
        grow: 1.7,
        selector: (row) => {
          return (
            <>
              <div className='d-flex'>
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id={'button-tooltip'} {...props}>
                      {row.participants[0]?.user?.customer
                        ? `${row.participants[0]?.user?.customer?.email}`
                        : row.participants[1]?.user?.customer
                        ? `${row.participants[1]?.user?.customer?.email}`
                        : '-'}
                    </Tooltip>
                  )}>
                  <p
                    className=''
                    style={{
                      width: '100px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {row.participants[0]?.user?.customer
                      ? `${row.participants[0]?.user?.customer?.email}`
                      : row.participants[1]?.user?.customer
                      ? `${row.participants[1]?.user?.customer?.email}`
                      : '-'}
                  </p>
                </OverlayTrigger>
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id={'button-tooltip'} {...props}>
                      copy
                    </Tooltip>
                  )}>
                  <Copy
                    type='button'
                    onClick={() =>
                      handleCopyClick(
                        row.participants[0]?.user?.customer
                          ? `${row.participants[0]?.user?.customer?.email}`
                          : row.participants[1]?.user?.customer
                          ? `${row.participants[1]?.user?.customer?.email}`
                          : '-'
                      )
                    }>
                    Copy
                  </Copy>
                </OverlayTrigger>
              </div>
            </>
          );
        }
      },
      {
        name: 'Agent Served',
        id: 'agent',
        grow: 1.7,
        selector: (row) => {
          return (
            <>
              <p className='d-flex align-items-center justify-content-start mb-0 pl-4'>
                {row.participants[1]?.user?.agent
                  ? `${row.participants[1]?.user?.agent?.first_name} ${row.participants[1]?.user?.agent?.last_name} `
                  : row.participants[0]?.user?.agent
                  ? `${row.participants[0]?.user?.agent?.first_name} ${row.participants[0]?.user?.agent?.last_name}`
                  : 'Unattended'}
              </p>
            </>
          );
        }
      },
      {
        name: 'Location',
        id: 'location',
        grow: 1.5,
        selector: (row) => {
          return (
            <>
              <p className='d-flex align-items-center justify-content-start mb-0 pl-4'>
                {row.participants[0]?.user?.customer
                  ? `${row.participants[0]?.user?.customer?.location}`
                  : row.participants[1]?.user?.customer
                  ? `${row.participants[1]?.user?.customer?.location}`
                  : '-'}
              </p>
            </>
          );
        }
      },
      {
        name: 'Chat Duration',
        id: 'duration',
        grow: 1.5,
        selector: (row) => {
          return (
            <p className='d-flex align-items-center justify-content-start mb-0 pl-3'>
              {row.duration_in_sec
                ? moment.utc(row.duration_in_sec * 1000).format('HH:mm')
                : 'Ongoing chat'}
            </p>
          );
        }
      },
      {
        name: 'Date',
        id: 'date',
        grow: 1.5,
        selector: (row) => {
          return (
            <p className='d-flex align-items-center justify-content-start mb-0'>
              {`${moment(row?.createdAt).format('MMM D, YYYY')}` || '-'}
            </p>
          );
        }
      },
      {
        name: 'Details',
        id: 'details',
        selector: (row) => {
          return (
            <Link
              to={`/chat-history/${row?.id}/${
                row.participants[1]?.user?.customer
                  ? `${row.participants[1]?.user?.customer?.id}`
                  : row.participants[0]?.user?.customer?.id
              }/${
                row.participants[1]?.user?.agent
                  ? `${row.participants[1]?.user?.agent?.id}`
                  : row.participants[0]?.user?.agent?.id
              }`}>
              <RealTimeVistor /> View
            </Link>
          );
        }
      }
    ],
    [record]
  );

  const customStyles = useMemo(
    () => ({
      headRow: {
        style: {
          backgroundColor: '#F5F6F9',
          fontSize: '14px'
        }
      },
      rows: {
        style: {
          height: 50,
          borderColor: '#F3F3F3',
          borderRadius: 5
        }
      },
      cells: {
        style: {}
      }
    }),
    []
  );

  const onChangePage = useCallback((page) => next({ page }), [next]);

  const searchHandler = (event) => {
    let filtered = record.filter((item) => {
      let result =
        item.participants[1]?.user?.customer?.first_name
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.participants[1]?.user?.customer?.last_name
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.participants[0]?.user?.customer?.first_name
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.participants[1]?.user?.customer?.last_name
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.participants[1]?.user?.customer?.location
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.participants[0]?.user?.customer?.location
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.participants[1]?.user?.agent?.first_name
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.participants[1]?.user?.agent?.last_name
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.participants[0]?.user?.agent?.first_name
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.participants[0]?.user?.agent?.last_name
          .toLowerCase()
          .includes(searchText.toLowerCase());
      return result;
    });

    setFilteredData(filtered);
    return filtered;
  };

  return (
    <>
      <div className='card mt-4 p-4 overflow-auto table-responsive-sm'>
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <div className='card-title pl-3 h4 mb-0 font-weight-bold text-nowrap'>Chat Records</div>
          <Col className='d-flex' md={4}>
            <InputGroup className='mr-3'>
              <Input
                placeholder='Search'
                className='border-right-0'
                value={searchText}
                onInput={(e) => {
                  setSearchText(e.target.value);
                }}
                onChange={searchHandler}
              />
              <InputGroupAddon addonType='append'>
                <InputGroupText className='bg-white border-left-0'>
                  <Search width={16.77} />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <Download onClick={handleCsvDownloadClick} />
          </Col>
        </div>
        <div className='mt-3'>
          <DataTable
            progressPending={isLoading}
            columns={columns}
            data={searchText === '' ? record : filteredData}
            pagination
            paginationServer
            customStyles={customStyles}
            highlightOnHover
            paginationTotalRows={total}
            paginationPerPage={perPage}
            onChangePage={onChangePage}
            onChangeRowsPerPage={(e) => setPerpage(e)}
            progressComponent={() => (
              <SkeletonTheme color='#eee'>
                <Skeleton width={700} height={10} count={5} className='ml-2' />
              </SkeletonTheme>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default ChatRecords;
