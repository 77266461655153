import React from 'react';
import { Spinner } from 'reactstrap';
import './style.css';

export default function Loader() {
  return (
    <div className='loading-container'>
      <div className='loader-wrapper'>
        <Spinner style={{ width: '50px', height: '50px' }} />
      </div>
    </div>
  );
}
