import React, { useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Eye, EyeOff } from '../../assets/icons/jsxIcons';
import { mask } from '../../utils';
import { Pressable } from '../theme';

const MaskCredential = ({ credential, maskChar, unmaskedLength, maskFromStart, iconWidth }) => {
  const [opened, setOpened] = useState();
  const Icon = opened ? EyeOff : Eye;
  const maskable = useMemo(
    () => (opened ? credential : mask(credential, maskChar, unmaskedLength, maskFromStart)),
    [opened]
  );

  return (
    <Col>
      <Row className='align-middle d-flex align-items-center'>
        {maskable}{' '}
        <Pressable className='p-1' onClick={() => setOpened((opened) => !opened)}>
          <Icon width={iconWidth} />
        </Pressable>
      </Row>
    </Col>
  );
};

export default MaskCredential;
