import React, { useEffect, useState, memo } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import api from '../../api';
import '../../assets/css/chatrecords.css';
import '../../assets/css/pagination.css';
import '../../assets/css/agents.css';
import '../../assets/css/table.css';
import countries from '../../assets/icons/colored/total-countries.svg';
import routes from '../../constants/routes';
import MuiTableDashboard from '../CustomTable/MuiTableDashboard';
import Avatar from 'react-avatar';
import './style.css';
import moment from 'moment';
import { Interval } from '../common/Time';
import { ClockRotateRight, OnlineUsers, Queue } from '../../assets/icons/jsxIcons';
import { padStart } from 'lodash';
import { useRequest, useSocketListeners } from '../../utils/hooks';
import waits from '../../utils/api/wait';

const Dashboard = () => {
  const [currentMonthRating, setCurrentMonthRating] = useState(null);
  const [lastMonthRating, setLastMonthRating] = useState(null);
  const [, setLastWeekRatingList] = useState(null);
  const [agentList, setAgentList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalWaiting, setTotalWaiting] = useState(0);

  const profile = JSON.parse(localStorage.getItem('profile'));
  const role = profile?.type;

  const connectedWaitingList = useQuery('connectedWaitingList', () =>
    api.get(`waits?limit=1&status=connected&orderBy=createdAt&order=DESC`)
  );

  const agentQuarry = useQuery('agentQuarry', () => api.get('/agents/engagements'));

  const agentsOnline = useQuery('agentsOnline', () => api.get('/agents/agents_count'));

  const chatCount = useQuery('chatCount', () => api.get('/messages/count'));

  const callsCount = useQuery('callsCount', () => api.get('live-videos'));

  const last7DaysRatings = useQuery('last7DaysRatings', () => api.get('/feedbacks/weekly_ratings'));
  const last7DaysRatingsData = last7DaysRatings?.data?.data.feedback;

  const teamRating = useQuery('teamRating', () => api.get('feedbacks/team_ratings'));

  const companiesList = useQuery('companiesList', () => api.get('companies'));

  const teamRatingsData = teamRating?.data?.data.feedback;

  const getCurrentRatings = async () => {
    setLoading(true);
    let response = await api.get('feedbacks/current_month_rating');
    if (response?.status === 200) {
      setCurrentMonthRating(response?.data?.thisMonthRating);
      setLoading(false);
    }
  };
  const getLastRatings = async () => {
    setLoading(true);
    let response = await api.get('feedbacks/last_month_rating');
    if (response?.status === 200) {
      setLastMonthRating(response?.data?.lastMonthRating);
      setLoading(false);
    }
  };

  const getLastWeekRatings = async () => {
    setLoading(true);
    let response = await api.get('feedbacks/weekly_ratings');
    if (response?.data?.status === 200) {
      setLastWeekRatingList(response?.data?.ratings);
      setLoading(false);
    }
  };
  const getAgentList = async () => {
    setLoading(true);
    let response = await api.get('agents');
    if (response?.data?.status) {
      setAgentList(response?.data?.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCurrentRatings();
    getLastRatings();
    getLastWeekRatings();
    getAgentList();
  }, []);
  const columns = [
    {
      title: 'Agents',
      icon: 'Avatar',
      field: 'profile.picture',
      render: (rowData) => {
        return (
          <div className='d-flex align-items-center'>
            <div className='mr-3'>
              <Avatar size='30' src={`${rowData?.profile_picture}`} round={true} />
            </div>
            <div className=''>
              <p className='m-0'>{`${rowData?.first_name} ${rowData?.last_name}`}</p>
            </div>
          </div>
        );
      },
      cellStyle: {
        padding: 0,
        paddingLeft: 15,
        width: '33.3%',
        maxWidth: '33.3%'
      },
      headerStyle: {
        paddingLeft: 18,
        width: '33.3%',
        maxWidth: '33.3%'
      }
    },
    {
      title: 'Interactions',
      field: 'wait_connected.length',
      render: (rowData) => {
        return (
          <div>
            <p className='m-0'>{!rowData?.connected_clients ? '-' : rowData?.connected_clients}</p>
          </div>
        );
      },
      cellStyle: {
        textAlign: 'center',
        width: '33.3%',
        maxWidth: '33.3%',
        padding: 0
      },
      headerStyle: {
        padding: 0,
        textAlign: 'center',
        width: '33.3%',
        maxWidth: '33.3%'
      }
    },
    {
      title: 'Avg Call Duration',
      field: 'Agents',
      render: (rowData) => {
        return (
          <div>
            <p className='m-0'>
              {!rowData?.avg_call_durations
                ? '--:--:--'
                : moment.utc(rowData?.avg_call_durations * 1000).format('HH:mm')}
            </p>
          </div>
        );
      },
      cellStyle: {
        textAlign: 'center',
        width: '33.3%',
        maxWidth: '33.3%',
        padding: 0,
        paddingRight: 10
      },
      headerStyle: {
        textAlign: 'center',
        width: '33.3%',
        maxWidth: '33.3%',
        padding: 0,
        paddingRight: 10
      }
    }
  ];

  return (
    <>
      {role === 'super' ? (
        <>
          <div className='row mt-4'>
            <div className='d-flex' style={{ height: '116px' }}>
              <div className='card p-3 dash-card border-0 text-center ' style={{ width: '200px' }}>
                <h6 className='mb-3'>Total Companies</h6>
                <div className='d-flex justify-content-around'>
                  <img src={countries} alt='' />
                  {loading ? (
                    <SkeletonTheme color='#eee'>
                      <Skeleton width={150} height={30} className='ml-2' />
                    </SkeletonTheme>
                  ) : (
                    <h4 className='m-0 ml-2'>{companiesList?.data?.data?.data.total}</h4>
                  )}
                </div>
                <Link
                  to={routes.WEBSITES}
                  className='more d-flex justify-content-between align-items-center mt-3'>
                  <p>View More</p>
                  <i className='ion-android-arrow-dropright-circle' />
                </Link>
              </div>
              <div
                style={{ width: '200px' }}
                className='card p-3 dash-card border-0 d-flex justify-content-center text-center '>
                <h6 className='mb-3'>Users</h6>
                <Link
                  to={routes.ADMIN}
                  className='more d-flex justify-content-between align-items-center mt-3'>
                  <p>View More</p>
                  <i className='ion-android-arrow-dropright-circle' />
                </Link>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={'dashboard-body'}>
            <div className='pl-0 mb-0 text-nowrap'>
              <p className='h4 font-weight-bold'>Welcome back, {profile?.first_name}</p>
              <p className='h6 font-weight-bold welcome-date'>
                {moment().format('dddd, D MMMM YYYY')}
              </p>
            </div>
            <div className='container-fluid'>
              <div className='row'>
                <Overview
                  {...{
                    setTotalWaiting,
                    loading,
                    totalOnline: agentsOnline?.data?.data?.count || 0,
                    totalConnected: connectedWaitingList?.data?.data?.total || 0
                  }}
                />
                <div className='col-6 col-xs-12 m-3 mr-4 mb-0 px-0 dash-box dash-box2'>
                  <MuiTableDashboard
                    columns={columns}
                    data={agentQuarry?.data?.data}
                    title={'CHATS PER AGENT'}
                    options={{ pageSizeOptions: [] }}
                  />
                </div>
              </div>
            </div>
            <div className='container-fluid'>
              <div className='row mt-0'>
                <div
                  className='col-2 d-flex w-100 flex-column py-4 align-items-start my-2 mx-2 ml-0 dash-box down-box csat'
                  style={{ maxWidth: '25%' }}>
                  <h4>CSAT</h4>
                  {loading ? (
                    <SkeletonTheme color='#eee'>
                      <Skeleton width={150} height={30} className='ml-2' />
                    </SkeletonTheme>
                  ) : (
                    <div className='d-flex flex-column w-100 justify-content-between'>
                      <div className=''>
                        <h1 className='m-0'>{currentMonthRating?.toFixed(0) || 0}%</h1>
                        <p className='m-0'>This Month</p>
                      </div>
                      <div className=''>
                        <h1 className='m-0'>{lastMonthRating?.toFixed(0) || 0}%</h1>
                        <p className='m-0'>Last Month</p>
                      </div>
                      <div className=''>
                        <h5
                          className='m-0 my-3 mb-4 font-weight-bold'
                          style={{ paddingTop: '2rem', fontSize: '16px' }}>
                          LAST 7 DAYS
                        </h5>
                        {last7DaysRatingsData?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className='d-flex justify-content-between last-week p-0 mb-2'>
                              <p className='p-0'>{`${item?.agent_first_name} ${item?.agent_last_name}`}</p>
                              <p className='p-0'>{`${
                                parseFloat(item?.weekly_percentage_rating || 0)?.toFixed(0) || 0
                              }%`}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className='col-3 d-flex w-100 flex-column py-4 px-4 align-items-start my-2 mt-0 mx-2 dash-box down-box dept'
                  style={{ maxWidth: '25%' }}>
                  <h4 className=''>CSAT BY TEAM</h4>
                  {loading ? (
                    <SkeletonTheme color='#eee'>
                      <Skeleton width={150} height={30} className='ml-2' />
                    </SkeletonTheme>
                  ) : (
                    <div className='d-flex flex-column w-100 justify-content-between'>
                      {!teamRatingsData?.length ? (
                        <p style={{ fontSize: 21 }} className='d-flex text-center'>
                          There are no records to display
                        </p>
                      ) : (
                        teamRatingsData?.map((item) => {
                          return (
                            <div key={item.team_id}>
                              <div className='d-flex justify-content-between w-100'>
                                <p className=''>{item.team_name}</p>
                                <p className=''>{`${
                                  parseFloat(item?.rating)?.toFixed(0) || 0
                                }%`}</p>
                              </div>
                              <ProgressBar
                                now={parseFloat(item?.rating)?.toFixed(0) || 0}
                                className={`team-progress ${
                                  parseFloat(item?.rating)?.toFixed(0) < 25
                                    ? 'firster'
                                    : parseFloat(item?.rating)?.toFixed(0) >= 25 &&
                                      parseFloat(item?.rating)?.toFixed(0) <= 50
                                    ? 'seconder'
                                    : parseFloat(item?.rating)?.toFixed(0) > 50 &&
                                      parseFloat(item?.rating)?.toFixed(0) <= 75
                                    ? 'thirder'
                                    : 'fourther'
                                }`}
                              />
                            </div>
                          );
                        })
                      )}
                    </div>
                  )}
                </div>
                <div
                  className='col-3 d-flex w-100 flex-column py-4 align-items-start my-2 mt-0 mx-3 px-4 dash-box down-box dept agent-status'
                  style={{ maxWidth: '25%' }}>
                  <h4>AGENT STATUS</h4>
                  {loading ? (
                    <SkeletonTheme color='#eee'>
                      <Skeleton width={150} height={30} className='ml-2' />
                    </SkeletonTheme>
                  ) : (
                    <div className='d-flex flex-column w-100 justify-content-between'>
                      {agentList?.map((item) => {
                        return (
                          <div
                            key={item?._id}
                            className='d-flex w-100 mb-3 justify-content-between last-week'>
                            <p>{`${item?.first_name} ${item?.last_name}`}</p>
                            <p className={`${item?.is_online ? 'green' : 'red'}`}>{`${
                              item?.is_online ? 'Online' : 'Offline'
                            }`}</p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div
                  className='col-2 text-center d-flex w-100 flex-column py-4 align-items-center my-2 mt-0 mx-2 dash-box down-box engagement'
                  style={{ maxWidth: '25%' }}>
                  <h4 className='h4'>ENGAGEMENT</h4>
                  {loading ? (
                    <SkeletonTheme color='#eee'>
                      <Skeleton width={150} height={30} className='ml-2' />
                    </SkeletonTheme>
                  ) : (
                    <div className='d-flex flex-column w-100 justify-content-between'>
                      <div className=''>
                        <h1 className='m-0 h1'>
                          {chatCount?.data?.data?.total >= 1000
                            ? Math.round(chatCount?.data?.data?.total / 1000) + 'K'
                            : chatCount?.data?.data?.total || 0}
                        </h1>
                        <p className='m-0'>Total Chats</p>
                      </div>
                      <div className='adjust'>
                        <h1 className='m-0 h1'>
                          {callsCount?.data?.data?.total >= 1000
                            ? Math.round(callsCount?.data?.data?.total / 1000) + 'K'
                            : callsCount?.data?.data?.total || 0}
                        </h1>
                        <p className='m-0'>Total Calls</p>
                      </div>
                      <div className='adjust'>
                        <h1 className='m-0 h1'>{totalWaiting}</h1>
                        <p className='m-0'>Calls Waiting</p>
                      </div>

                      <div className='adjust'>
                        <h1 className='m-0 h1'>{connectedWaitingList?.data?.data?.total || 0}</h1>
                        <p className='m-0'>Connected Calls</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const Overview = memo(({ loading, setTotalWaiting, totalConnected, totalOnline }) => {
  const {
    state: { isLoading, data: { data: [wait] = [], total = 0 } = {} },
    setState
  } = useRequest({
    params: [
      {
        limit: 1,
        status: 'waiting',
        orderBy: 'createdAt',
        order: 'ASC'
      }
    ],
    dataPoint: 'data',
    onSuccess: (data) => setTotalWaiting(data.total),
    asyncRequest: waits
  });

  useSocketListeners({
    listeners: {
      'longest-wait': ({ waitList }) =>
        setState((s) => {
          if (!waitList) {
            s.data = {};
            return s;
          }
          if (s.data.data?.[0]) {
            s.data.data[0].createdAt = waitList.createdAt;
          } else {
            s.data = { data: [waitList], total: 1 };
            s.data.data = [waitList];
          }
        }),
      ...(!wait
        ? {
            'WaitList.created': ({ waitList }) => setState({ data: { data: [waitList], total: 1 } })
          }
        : {})
    }
  });

  return (
    <div className='col-6 col-xs-12 d-flex w-100 flex-column py-4 my-3 mb-0 mx-0 mr-1 dash-box dash-box1'>
      <h4 className='align-self-start m-2'>OVERVIEW</h4>
      {loading ? (
        <SkeletonTheme color='#eee'>
          <Skeleton width={150} height={30} className='ml-2' />
        </SkeletonTheme>
      ) : (
        <Row className='justify-content-around align-items-center align-self-center h-100 w-100 today'>
          <Stat
            Icon={Queue}
            title='Chat Queue'
            value={total + totalConnected}
            className='border-right'
          />
          <Stat
            Icon={ClockRotateRight}
            title='Wait Time'
            fill='#dc3545'
            backgroundColor='rgba(220, 53, 69, 0.3)'
            className='border-right'
            value={
              <>
                {!wait
                  ? '00:00'
                  : !isLoading && (
                      <Interval
                        interval={1000}
                        Render={() => {
                          const d = moment.duration(moment().diff(wait?.createdAt));
                          return (
                            <>
                              {padStart(d._data.hours, 2, '0')}:{padStart(d._data.minutes, 2, '0')}
                              <span style={{ fontSize: 12 }}>
                                :{padStart(d._data.seconds, 2, '0')}
                              </span>
                            </>
                          );
                        }}
                      />
                    )}
              </>
            }
          />
          <Stat
            Icon={OnlineUsers}
            title='Agents Online'
            backgroundColor='rgba(40, 167, 69, 0.3)'
            fill='#28a745'
            value={totalOnline}
          />
        </Row>
      )}
    </div>
  );
});

const Stat = ({
  Icon,
  title,
  value,
  fill = '#ffc107',
  backgroundColor = 'rgba(255, 193, 7, 0.3)',
  className = ''
}) => (
  <Col sm={4} xs={12} className={'d-flex align-items-center my-2 ' + className}>
    <Col
      md={4}
      style={{ backgroundColor, borderRadius: '1rem' }}
      className='p-3 d-flex align-items-center justify-content-center'>
      <Icon height='3rem' width='3rem' fill={fill} />
    </Col>
    <Col md={8}>
      <span className='text-muted'>{title}</span>
      <h3>{value}</h3>
    </Col>
  </Col>
);

export default memo(Dashboard);
