import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './style.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import MuiTable from '../CustomTable/MuiTable';
import dots from '../../assets/icons/dots.png';
import { createTeam, deleteTeam, getTeams, updateTeam, viewTeam } from '../../utils/api/team';

const initialState = {
  name: '',
  description: '',
  isLoading: false
};
const initialState2 = {
  name: '',
  description: '',
  id: null
};
const Team = () => {
  const [state, setState] = useState(initialState);
  const [teamID, setTeamID] = useState(null);
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [singleTeam, setSingleTeam] = useState(initialState2);
  const [actionOpened, setActionOpened] = useState(-1);
  const toggle = () => setModal(!modal);

  const toggleEdit = async (item) => {
    setEditModal(true);
    if (item.id) {
      await getSingleTeam(item.id);
      setEditModal(true);
      setTeamID(item.id);
    } else {
      setEditModal(false);
    }
  };

  useEffect(() => {}, [editModal]);

  const getSingleTeam = async (id) => {
    if (id) {
      const {
        data: { team }
      } = await viewTeam(id);

      setSingleTeam(team);
    }
  };

  const handleSingleValidation = () => {
    let nameValidation = {
      message: 'Name is Required',
      status: false
    };
    let descriptionValidation = {
      message: 'description is required',
      status: false
    };
    let passed = {
      status: true
    };
    return singleTeam.name !== ''
      ? singleTeam.description === ''
        ? descriptionValidation
        : passed
      : nameValidation;
  };
  const handleSingleTeamChange = ({ target: { value, name } }) => {
    setSingleTeam((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSingleTeamSubmit = async (e) => {
    try {
      e.preventDefault();
      const validate = handleSingleValidation();

      if (validate.status) {
        setSingleTeam({
          ...state,
          loading: true
        });
        const data = {
          name: singleTeam.name,
          description: singleTeam.description,
          company: process.env.REACT_APP_COMPANY_ID
        };

        const {
          data: { message }
        } = await updateTeam(teamID, data);

        setState(initialState);
        setEditModal(false);
        toast.success(message, { autoClose: 2000 });
        await allTeamQuery.refetch();
      } else {
        toast.error(validate.message, { autoClose: 2000 });
      }
    } catch (error) {
      setState({
        ...state,
        loading: false
      });
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    }
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: 'Confirmation!',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => confirmChangeStatus(id)
        },
        {
          label: 'No'
        }
      ]
    });
  };

  const confirmChangeStatus = async (id) => {
    const {
      data: { message }
    } = await deleteTeam(id);

    await allTeamQuery.refetch();
    toast.success(message, { autoClose: 2000 });
  };

  const allTeamQuery = useQuery('allTeamQuery', getTeams, {
    refetchOnWindowFocus: false
  });

  const handleValidation = () => {
    let nameValidation = {
      message: 'Name is Required',
      status: false
    };
    let descriptionValidation = {
      message: 'description is required',
      status: false
    };

    let passed = {
      status: true
    };
    return state.name !== ''
      ? state.description === ''
        ? descriptionValidation
        : passed
      : nameValidation;
  };

  const handInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleTeamSubmit = async (e) => {
    try {
      e.preventDefault();
      const validate = handleValidation();
      if (validate.status) {
        setState({
          ...state,
          loading: true
        });
        const data = {
          name: state.name,
          description: state.description
        };

        const {
          data: { message }
        } = await createTeam(data);

        setState(initialState);
        setModal(false);
        toast.success(message, { autoClose: 2000 });
        await allTeamQuery.refetch();
      } else {
        toast.error(validate.message, { autoClose: 2000 });
      }
    } catch (error) {
      setState({
        ...state,
        loading: false
      });
      toast.error(error?.response?.data?.message, {
        autoClose: 2000
      });
    }
  };

  const columns = [
    // {
    //   title: '#',
    //   field: '',
    //   align: 'center',
    //   render: (rowData) => rowData.tableData.id + 1,
    //   cellStyle: {
    //     paddingLeft: 60,
    //     textAlign: 'left',
    //     width: '20%',
    //     maxWidth: '20%'
    //   },
    //   headerStyle: {
    //     paddingLeft: 60,
    //     textAlign: 'left',
    //     width: '20%',
    //     maxWidth: '20%'
    //   }
    // },
    {
      title: 'Name',
      field: 'name',
      cellStyle: {
        textAlign: 'left',
        width: '30%',
        maxWidth: '30%'
      },
      headerStyle: {
        textAlign: 'left',
        width: '30%',
        maxWidth: '30%'
      }
    },
    {
      title: 'description',
      field: 'description',
      cellStyle: {
        textAlign: 'left',
        width: '30%',
        maxWidth: '30%'
      },
      headerStyle: {
        textAlign: 'left',
        width: '30%',
        maxWidth: '30%'
      }
    },
    {
      title: 'No of Agents',
      field: 'number_of_agent',
      render: (rowData) => rowData.number_of_agents,
      cellStyle: {
        textAlign: 'center',
        width: '30%',
        maxWidth: '30%'
      },
      headerStyle: {
        textAlign: 'center',
        width: '30%',
        maxWidth: '30%'
      }
    },
    {
      title: 'Actions',
      field: '',
      headerSort: false,
      align: 'left',
      render: (rowData) => {
        return (
          <>
            <div className='menu-cover position-relative d-flex justify-content-center align-items-center'>
              <img
                style={{ cursor: 'pointer' }}
                alt=''
                src={dots}
                height={17}
                onClick={() =>
                  actionOpened === rowData.tableData.id
                    ? setActionOpened(-1)
                    : setActionOpened(rowData.tableData.id)
                }
              />
              {actionOpened === rowData.tableData.id && (
                <div
                  className={`action-opened ${
                    (rowData.tableData.id > 0 && rowData.tableData.id % 6 === 0) ||
                    (rowData.tableData.id > 0 && rowData.tableData.id % 5 === 0)
                      ? 'row-bottom'
                      : ''
                  }`}>
                  <div className='action-container d-flex flex-column justify-content-center align-items-center'>
                    <button
                      data-toggle='modal'
                      onClick={() => {
                        toggleEdit(rowData);
                        setActionOpened(-1);
                      }}
                      data-target='#edit-modal'
                      className='btn btn-sm w-75 d-flex justify-content-start align-items-center mb-2'>
                      <i className='fas fa-edit text-success mr-3' />
                      <span className='text-dark'>Edit</span>
                    </button>

                    <button
                      onClick={() => {
                        handleDelete(rowData.id);
                        setActionOpened(-1);
                      }}
                      className='btn btn-sm w-75 d-flex justify-content-start align-items-center'>
                      <i className='fas fa-trash-alt text-red mr-3' />
                      <span className='text-dark'>Delete</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        );
      },
      cellStyle: {
        textAlign: 'center',
        width: '20%',
        maxWidth: '20%'
      },
      headerStyle: {
        // padding: 0,
        textAlign: 'center',
        width: '20%',
        maxWidth: '20%'
      }
    }
  ];

  return (
    <>
      <div className='team card mt-4 p-4 overflow-auto table-responsive-sm'>
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <div className='card-title pl-3 h4 mb-0 font-weight-bold text-nowrap'>Team</div>
          <div className='d-flex justify-content-between align-items-center'>
            <button onClick={toggle} className='btn add-btn ml-2 text-nowrap'>
              Add Team
            </button>
          </div>
        </div>
        {allTeamQuery.loading ? (
          <SkeletonTheme color='#eee'>
            <Skeleton width={700} height={10} count={5} className='ml-2' />
          </SkeletonTheme>
        ) : (
          <div className='mt-3'>
            <MuiTable
              columns={columns}
              data={allTeamQuery.data?.data?.data}
              title={'All Department'}
            />
          </div>
        )}
      </div>

      {/*  Modal  */}
      {modal && (
        <Modal style={{ maxWidth: '500px', width: '100%' }} isOpen={modal} toggle={toggle}>
          <ModalHeader className='pl-4' toggle={toggle}>
            Add Team
          </ModalHeader>
          <ModalBody className='p-4'>
            <form id='new-team' onSubmit={handleTeamSubmit}>
              <div className='form-group'>
                <label style={{ color: '#43425D' }}>
                  {' '}
                  Name
                  <span className='text-danger'>*</span>
                </label>
                <input
                  value={state.name}
                  onChange={handInputChange}
                  name='name'
                  type='text'
                  className='form-control'
                  style={{ borderRadius: '8px' }}
                  id='exampleFormControlInput1'
                  placeholder='Team name'
                  required
                />
              </div>
              <div className='form-group mt-4'>
                <label style={{ color: '#43425D' }}>
                  Description
                  <span className='text-danger'>*</span>
                </label>
                <textarea
                  value={state.description}
                  onChange={handInputChange}
                  name='description'
                  className='form-control'
                  style={{ borderRadius: '8px' }}
                  id='exampleFormControlInput1'
                  placeholder='Short description of the team'
                  required
                />
              </div>
              <div className='modal-footer pt-0'></div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type='submit'
              form='new-team'
              style={{
                width: '200px',
                backgroundColor: '#43425D',
                borderRadius: '8px'
              }}
              className='btn text-white'>
              Add Team
            </button>
          </ModalFooter>
        </Modal>
      )}
      {/*  Edit  */}
      {editModal && (
        <Modal isOpen={editModal} toggle={toggleEdit}>
          <ModalHeader toggle={toggleEdit}></ModalHeader>
          <h4 className='pl-4 pb-3'>Edit Team</h4>
          <ModalBody className='p-4'>
            <form id='edit-team' onSubmit={handleSingleTeamSubmit}>
              <div className='form-group'>
                <label className='mb-2' style={{ color: '#43425D' }}>
                  Team Name
                  <span className='text-danger'>*</span>
                </label>
                <input
                  value={singleTeam.name}
                  onChange={handleSingleTeamChange}
                  name='name'
                  type='text'
                  className='form-control team-input'
                  style={{ borderRadius: '8px' }}
                  placeholder='Team Name'
                  id='exampleFormControlInput1'
                  required
                />
              </div>
              <div className='form-group'>
                <label className='my-2 mt-4' style={{ color: '#43425D' }}>
                  Team Description
                  <span className='text-danger'>*</span>
                </label>
                <textarea
                  value={singleTeam.description}
                  onChange={handleSingleTeamChange}
                  name='description'
                  className='form-control'
                  style={{ borderRadius: '8px' }}
                  id='exampleFormControlInput1'
                  placeholder='Short description of the team'
                  required
                />
                <small>Short description of the team</small>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type='submit'
              form='edit-team'
              style={{
                width: '200px',
                backgroundColor: '#43425D',
                borderRadius: '8px'
              }}
              className='btn text-white mb-3'>
              Update Team
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default Team;
