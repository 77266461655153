import api from "../../api";

const waits = (params) => api.get("/waits", { params });

export const updateWait = (id, params) => api.put("/waits/" + id, params);

export const transferWait = (id, params) =>
  api.post(`/waits/${id}/transfer`, params);

export default waits;
