import jwtDecode from "jwt-decode";

export const decode = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        decodedToken.isExpired = false
    
        if (decodedToken.exp < (new Date().getTime() / 1000)) {
            decodedToken.isExpired = true
        }
    
        return decodedToken;
    } catch (error) {
        console.log('error: ', error);
        
        if(localStorage.getItem('token')) {
            localStorage.removeItem('token');  
            window.location.href = '/auth/login';
        }
    }
}