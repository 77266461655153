import { forwardRef, useCallback, useMemo, memo, useEffect } from 'react';
import { Button, Spinner } from 'reactstrap';
import { useRequest, useScroll } from '../../utils/hooks/index.js';
import ListView from './ListView';

const Inifinite = memo(
  forwardRef(function (
    {
      asyncRequest,
      onSuccess,
      setData,
      params,
      name,
      RenderItem,
      inverted,
      reducer,
      showLoadMore,
      dataName = 'data',
      style,
      id,
      className,
      onDataInit,
      disableEndReached = false,
      disableBeginReached = true,
      reversed,
      ...rest
    },
    ref
  ) {
    const {
      next,
      setState,
      state: {
        data: { [dataName]: data = [], hasNextPage },
        isLoading,
      },
      stateRef,
    } = useRequest(
      {
        infinite: true,
        asyncRequest,
        setData,
        params,
        name,
        reducer,
        onSuccess,
        dataName,
        ...rest,
      },
      [params]
    );

    const _style = useMemo(
      () => ({ transform: inverted && 'scaleY(-1)' }),
      [inverted]
    );

    const _RenderItem = useCallback(
      ({ item, index }) => (
        <RenderItem
          item={item}
          index={index}
          style={_style}
          messageRef={stateRef}
        />
      ),
      [_style, RenderItem, stateRef]
    );

    const RenderFooter = useCallback(
      () => (
        <>
          {isLoading ? (
            <Spinner />
          ) : showLoadMore ? (
            hasNextPage && (
              <Button
                style={{
                  transform: inverted && 'scaleY(-1)',
                }}
                onClick={() => next()}
              >
                load more
              </Button>
            )
          ) : null}
        </>
      ),
      [isLoading, inverted, hasNextPage, showLoadMore]
    );

    const onEndReached = useCallback(
      () => !showLoadMore && next(),
      [showLoadMore, next]
    );

    const onBeginReached = useCallback(
      async (e) => {
        const { nextPage } = stateRef.current.data || {};
        if (!showLoadMore && nextPage) {
          const scrollHeight = e.target.scrollHeight;
          await next();
          setTimeout(() => {
            // maintain scroll position after message prepend
            e.target.scrollTop = e.target.scrollHeight - scrollHeight;
          }, 60);
        }
      },
      [showLoadMore, next, stateRef]
    );

    const {
      ref: scrollRef,
      scrollToBottom,
      scrollTo,
    } = useScroll({
      onEndReached: !disableEndReached && onEndReached,
      onBeginReached: !disableBeginReached && onBeginReached,
    });

    if (ref) {
      if (!ref.current) ref.current = {};
      Object.assign(ref.current, { setState, scrollToBottom, scrollTo });
    }

    useEffect(() => {
      if (onDataInit && !isLoading && ref.current.shouldScrollToBottom) {
        onDataInit(data);
        ref.current.shouldScrollToBottom = false;
      }
    }, [isLoading]);

    return (
      <ListView
        data={data}
        loading={isLoading}
        inverted={inverted}
        RenderFooter={RenderFooter}
        RenderItem={_RenderItem}
        style={style}
        id={id}
        ref={scrollRef}
        className={className}
        reversed={reversed}
      />
    );
  })
);

export default Inifinite;
