import { useCallback, memo, forwardRef } from 'react';
import { Spinner } from 'reactstrap';

const ListView = memo(
  forwardRef(function (
    {
      data,
      RenderFooter,
      RenderItem,
      inverted,
      loading,
      className,
      id,
      style = {},
      reversed,
    },
    ref
  ) {
    const Items = useCallback(
      ({ RenderItem }) =>
        (reversed ? data.slice().reverse() : data).map((item, key) => (
          <RenderItem
            item={item}
            key={typeof item === 'number' ? item : item?.id || key}
            messageRef={ref}
            index={key}
          />
        )),
      [data, reversed]
    );

    return (
      <div
        ref={ref}
        id={id}
        className={className}
        style={{
          transform: inverted && 'scaleY(-1)',
          ...style,
        }}
      >
        {loading && <Spinner />}
        <Items RenderItem={RenderItem} />
        {RenderFooter && <RenderFooter />}
      </div>
    );
  })
);

export default ListView;
