import React, { useState } from 'react';
import logo from '../assets/images/logo.png';
import { Link, useParams, useHistory } from 'react-router-dom';
import routes from '../constants/routes';
import { Spinner } from 'reactstrap';
import '../../src/ForgotPassword/forgotPassword.css';
import { toast } from 'react-toastify';
import { resetPassword } from '../utils/api/passwordReset';

const initialState = {
  password: '',
  confirmPassword: '',
  loading: false
};
const ResetPassword = () => {
  const [state, setState] = useState(initialState);
  let { id, token } = useParams();
  const history = useHistory();
  const handlePasswordChange = ({ target: { value, name } }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSingleValidation = () => {
    let passwordValidation = {
      message: 'Password is Required',
      status: false
    };
    let confirmPasswordValidation = {
      message: 'confirmPassword is required',
      status: false
    };
    let passwordMatchValidation = {
      message: 'password does not match',
      status: false
    };
    let passed = {
      status: true
    };
    return state.password !== ''
      ? state.confirmPassword === ''
        ? confirmPasswordValidation
        : state.password !== state.confirmPassword
        ? passwordMatchValidation
        : passed
      : passwordValidation;
  };
  const restPassword = async (e) => {
    e.preventDefault();
    let validation = handleSingleValidation();
    if (validation.status) {
      try {
        setState((prevState) => ({
          ...prevState,
          loading: true
        }));
        await resetPassword(id, token, { password: state.password });
        setState((prevState) => ({
          ...prevState,
          loading: false,
          password: '',
          confirmPassword: ''
        }));
        toast.success('Password Reset successful', {
          autoClose: 2000
        });
        history.push('/auth/login');
      } catch (error) {
        toast.error('An error occurred', { autoClose: 2000 });
        setState({ isLoading: false });
      }
    } else {
      toast.error(validation.message, { autoClose: 2000 });
    }
  };
  return (
    <>
      <div className='main-div'>
        <div
          className='position-relative card forgot-page py-4 px-5'
          style={{ borderRadius: '8px' }}>
          <form onSubmit={restPassword}>
            <div style={{ textAlign: 'center' }} className='mb-3'>
              <img src={logo} alt='logo' width='250px' />
              <h1 style={{ color: '#042F47', fontWeight: 'bold' }}>Reset Password</h1>
            </div>
            <div className='form-group'>
              <label htmlFor='new-password'>Password</label>
              <input
                className='form-control'
                type='password'
                id='new-password'
                name='password'
                aria-describedby='emailHelp'
                placeholder='Enter your new password'
                value={state.password}
                onChange={handlePasswordChange}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='confirm-password'>Confirm Password</label>
              <input
                className='form-control'
                type='password'
                id='confirm-password'
                name='confirmPassword'
                aria-describedby='emailHelp'
                placeholder='confirm your password'
                value={state.confirmPassword}
                onChange={handlePasswordChange}
              />
            </div>
            <span className='p-2 mt-3 login-link '>
              Remember the password?
              <Link className='ml-2 font-weight-bold' to={routes.LOGIN}>
                Login
              </Link>
            </span>
            <div className='d-flex mt-5 align-items-center justify-content-center'>
              <button
                disabled={state.loading}
                type='submit'
                className='d-flex justify-content-center align-items-center btn forgot-btn-color p-2'>
                {state.loading ? <Spinner color='light' size='sm' /> : ''}
                <span className='ml-1'>Reset Password</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
