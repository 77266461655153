import api from "../../api";

export const createBroadcast = (params, config) =>
  api.post("broadcasts", params, config);

export const getBroadcasts = (params, config) =>
  api.get("broadcasts", { params }, config);

export const readBroadcast = (id, params) =>
  api.post(`broadcasts/${id}/read`, params);
