import React from 'react';
import { Label, FormGroup, Input, Col, Spinner, Row } from 'reactstrap';
import { Switch } from '@material-ui/core';
import useState from 'use-react-state';
import { toast } from 'react-toastify';
import { updateCompany } from '../../../utils/api/companies';
import '../style.css';
import TabWrapper from '../TabWrapper';
import Loader from '../../Loader';

export default function WidgetSetting({ companyData }) {
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState(companyData);

  const onChange = (e) => setCompany({ [e.target.name]: e.target.value });
  const onToggle = (e) =>
    setCompany((company) => {
      company.fields[e.target.name].show = Boolean(e.target.checked);
    });

  const handleInputValidation = () => {
    const nameValidation = {
      message: 'Bank name is required',
      status: false
    };

    const urlValidation = {
      message: 'Bank URL is required',
      status: false
    };

    const fromDayValidation = {
      message: 'Day to resume work from is required',
      status: false
    };

    const toDayValidation = {
      message: 'Day to stop work is required',
      status: false
    };
    const fromTimeValidation = {
      message: 'Time to resume work is required',
      status: false
    };

    const toTimeValidation = {
      message: 'Time to close from work is required',
      status: false
    };

    const hexCodeValidation = {
      message: 'Your preferred widget color is required',
      status: false
    };

    const welcomeTextValidation = {
      message: 'You have to drop a welcome message for customers',
      status: false
    };

    const passed = {
      status: true
    };

    return company.name === ''
      ? nameValidation
      : company.url === ''
      ? urlValidation
      : company.from_day === ''
      ? fromDayValidation
      : company.to_day === ''
      ? toDayValidation
      : company.from_time === ''
      ? fromTimeValidation
      : company.to_time === ''
      ? toTimeValidation
      : company.hex_code === ''
      ? hexCodeValidation
      : company.welcome_message === ''
      ? welcomeTextValidation
      : passed;
  };

  const updateCompanyData = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    const validate = handleInputValidation();

    if (validate?.status) {
      try {
        const response = await updateCompany(company.id, company);

        toast.success('Company update successful', {
          autoClose: 2000
        });

        setIsLoading(false);
        setCompany(response?.data?.company);
      } catch (err) {
        console.log('error', err);
        toast.error('An error occurred', { autoClose: 2000 });
        setIsLoading(false);
      }
    } else {
      toast.error(validate.message, { autoClose: 2000 });
      setIsLoading(false);
    }
  };

  const onSubmit = async (event) => {
    return updateCompanyData(event);
  };

  return (
    <>
      <TabWrapper tabName='Widget Settings'>
        {isLoading ? (
          <Loader />
        ) : (
          <form onSubmit={onSubmit} id='edit-company'>
            <Row className='pt-4 pl-5'>
              <Col md={12} className='d-flex flex-column align-items-start justify-content-center'>
                <div className='d-flex w-75 justify-content-between my-2'>
                  <FormGroup className='w-100 font-weight-bold'>
                    <Label className='label-title' for='name'>
                      Bank Name
                    </Label>
                    <Input
                      name='name'
                      placeholder='Bank name'
                      value={company.name}
                      className='textbox form-input '
                      onChange={onChange}
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col md={12} className='d-flex flex-column align-items-start justify-content-center'>
                <div className='d-flex w-75 justify-content-between my-2'>
                  <FormGroup className='w-100 font-weight-bold'>
                    <Label className='label-title' for='url'>
                      Web Address
                    </Label>
                    <Input
                      name='url'
                      placeholder='Your URL without http or https'
                      className='textbox form-input '
                      onChange={onChange}
                      value={company.url}
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col md={12} className='d-flex flex-column align-items-start justify-content-center'>
                <div className='d-flex w-75 justify-content-between my-2'>
                  <FormGroup className='w-100 font-weight-bold'>
                    <Label className='label-title' for='welcome_message'>
                      Welcome Message
                    </Label>
                    <Input
                      name='welcome_message'
                      placeholder='Web Address'
                      className='textbox form-input'
                      onChange={onChange}
                      value={company.welcome_message}
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col md={12} className='d-flex flex-column align-items-start justify-content-center'>
                <div className='d-flex w-75 justify-content-between my-2'>
                  <FormGroup className='w-100 font-weight-bold'>
                    <Label className='label-title' for='hex_code'>
                      Widget Color
                    </Label>
                    <Input
                      name='hex_code'
                      placeholder='e.g #083047'
                      onChange={onChange}
                      className='textbox form-input'
                      value={company.hex_code}
                    />
                    <label for='colorpicker'>Select a color: </label>{' '}
                    <input
                      type='color'
                      id='colorpicker'
                      name='hex_code'
                      onChange={onChange}
                      className='mt-2'
                      value={company.hex_code}></input>
                  </FormGroup>
                </div>
              </Col>
              {/* {Object.keys(company.fields).map((item, index) => (
                <Col
                  md={12}
                  className='d-flex flex-column align-items-start justify-content-center'
                  key={index}>
                  <div className='d-flex w-75 justify-content-between my-2'>
                    <FormGroup className='w-75 font-weight-bold'>
                      <Label className='label-title' for={company.fields[item]}>
                        {company.fields[item].label}
                      </Label>
                      <Switch
                        name={String(company.fields['item'])}
                        onChange={onToggle}
                        checked={company.fields[item].required}
                        value={company.fields[item].required}
                        sx={{
                          width: 300,
                          color: 'success.main',
                          '& .MuiSlider-thumb': {
                            borderRadius: '1px'
                          }
                        }}
                      />
                    </FormGroup>
                  </div>
                </Col>
              ))} */}
              <Col md={12} className='d-flex flex-column align-items-start justify-content-center'>
                <h4>Options</h4>
                <div className='d-flex w-75 justify-content-between my-2'>
                  <FormGroup className='w-75 font-weight-bold'>
                    <Label className='label-title' for='bvn'>
                      {company?.fields?.bvn?.label}
                    </Label>{' '}
                    <Switch
                      name='bvn'
                      onChange={onToggle}
                      checked={company?.fields?.bvn?.show}
                      value={company?.fields?.bvn?.show}
                      color={'secondary'}
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col md={12} className='d-flex flex-column align-items-start justify-content-center'>
                <div className='d-flex w-75 justify-content-between my-2'>
                  <FormGroup className='w-75 font-weight-bold'>
                    <Label className='label-title' for='account'>
                      {company?.fields?.account?.label}
                    </Label>{' '}
                    <Switch
                      name='account'
                      onChange={onToggle}
                      checked={company.fields?.account?.show}
                      value={company?.fields?.account?.show}
                      color={'secondary'}
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col md={12} className='d-flex flex-column align-items-start justify-content-center'>
                <div className='d-flex w-75 justify-content-between my-2'>
                  <FormGroup className='w-75 font-weight-bold'>
                    <Label className='label-title' for='mobile'>
                      {company?.fields?.mobile?.label}
                    </Label>{' '}
                    <Switch
                      name='mobile'
                      onChange={onToggle}
                      color={'secondary'}
                      checked={company?.fields?.mobile?.show}
                      value={company?.fields?.mobile?.show}
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col md={12} className='d-flex flex-column align-items-start justify-content-between'>
                <div className='form-group m-0'>
                  <label className='m-0' style={{ color: '#43425D', fontWeight: 'bold' }}>
                    <h4>Hours of operation</h4>
                  </label>
                  <div className='row'>
                    <div className='col-md-6 col-sm-12 col-xs-12 col-xl-6'>
                      <p className='font-weight-bold'>Days</p>
                      <div className='form-group'>
                        <label style={{ color: '#43425D' }}>Select Day</label>
                        <select
                          onChange={onChange}
                          value={company.from_day}
                          name='from_day'
                          className='time-control form-control'
                          id='exampleFormControlSelect2'
                          style={{ width: '123px' }}>
                          <option disabled>Select Day</option>
                          <option value='Sunday'>Sunday</option>
                          <option value='Monday'>Monday</option>
                          <option value='Tuesday'>Tuesday</option>
                          <option value='Wednesday'>Wednesday</option>
                          <option value='Thursday'>Thursday</option>
                          <option value='Friday'>Friday</option>
                          <option value='Saturday'> Saturday</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 col-xs-12 col-xl-6'>
                      <div className='form-group'>
                        <p className='font-weight-bold'>Hours</p>
                        <label style={{ color: '#43425D' }}>Select Time</label>
                        <input
                          value={company.from_time}
                          onChange={onChange}
                          name='from_time'
                          type='time'
                          className='time-control form-control'
                          style={{ borderRadius: '8px', width: '110px' }}
                          id='exampleFormControlInput1'
                          required
                          placeholder='Company hours'
                        />
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 col-xs-12 col-xl-6'>
                      <div className='form-group'>
                        <label style={{ color: '#43425D' }}>Select Day</label>
                        <select
                          onChange={onChange}
                          value={company.to_day}
                          name='to_day'
                          className='time-control form-control'
                          id='exampleFormControlSelect3'
                          style={{ width: '123px' }}>
                          <option disabled>Select Day</option>
                          <option value='Monday'>Monday</option>
                          <option value='Tuesday'>Tuesday</option>
                          <option value='Wednesday'>Wednesday</option>
                          <option value='Thursday'>Thursday</option>
                          <option value='Friday'>Friday</option>
                          <option value='Saturday'> Saturday</option>
                          <option value='Sunday'>Sunday</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 col-xs-12 col-xl-6'>
                      <div className='form-group'>
                        <label style={{ color: '#43425D' }}>Select Time</label>
                        <input
                          name='to_time'
                          value={company.to_time}
                          onChange={onChange}
                          type='time'
                          className='time-control form-control'
                          style={{ borderRadius: '8px', width: '110px' }}
                          id='exampleFormControlInput1'
                          required
                          placeholder='Company hours'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                md={12}
                className='d-flex flex-column align-items-center mt-3 justify-content-center pb-4'>
                <div className='d-flex flex-column align-items-center w-100 justify-content-center mt-4 pb-3'>
                  <button
                    type='submit'
                    form='edit-company'
                    style={{
                      width: '210px',
                      backgroundColor: '#43425D',
                      borderRadius: '8px',
                      padding: '10px',
                      fontWeight: 'bold'
                    }}
                    className='btn text-white'>
                    {isLoading ? <Spinner color='light' size='sm' /> : ''}
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        )}
      </TabWrapper>
    </>
  );
}
