import api from "../../api";

export const agents = (params) => api.get("agents", { params });

export const user = (id, params) => api.get(`customers/${id}`, { params });

export const viewAgent = (id, params) => api.get("agents/" + id, { params });

export const updateAgent = (id, data) => api.put("agents/" + id, data);

export const createAgent = (params) => api.post("agents", params);

export const deleteAgent = (id, data) => api.delete('agents/' + id, { data });
