import React, { createRef, memo } from "react";
import MaterialTable from "material-table";

const MuiTable = memo(
  ({ columns, data, title, exportButton, options = {}, ...p }) => {
    const tableRef = createRef();
    return (
      <MaterialTable
        {...p}
        tableRef={tableRef}
        title={title}
        data={data}
        columns={columns}
        options={{
          sorting: false,
          paging: true,
          pageSize: 7,
          pageSizeOptions: [7],
          headerStyle: {
            fontWeight: "bold",
            textTransform: "uppercase",
            backgroundColor: "#F5F6F9",
          },
          exportButton: exportButton,
          emptyRowsWhenPaging: true, //to make page size fix in case of less data rows
          ...options,
        }}
      />
    );
  }
);
export default MuiTable;
