import React, { useState } from "react";
import "./style.css";
import { coloredIcons } from "../../assets/icons/index";

const PublicChatWidget = () => {
  const [toggle, setToggle] = useState(false);
  const [showMeClass] = useState("show-me");
  const [detailsForm] = useState("details-form");
  const Showme = () => {
    setToggle(!toggle);
  };
  return (
    <div
      className='main-content'
      style={{ height: "100vh", overflowY: "auto" }}
    >
      <span className='chat-icon' onClick={Showme}>
        <img
          alt=''
          id='chat-icon'
          src={
            toggle
              ? coloredIcons["Group 11344.svg"].default
              : coloredIcons["video-chat.svg"].default
          }
          width='50px'
          height='50px'
        />
      </span>

      <span className={toggle ? showMeClass : detailsForm} id='details-form'>
        <div className='header'>
          <span style={{ fontWeight: "bold", fontSize: "20px" }}>
            Video Banking
          </span>
          <small>How can we help you.</small>
        </div>

        <form className='form-style'>
          <div className='form-group'>
            <label for='exampleFormControlInput1' style={{ color: "#43425D" }}>
              Name
            </label>
            <input
              type='text'
              className='form-control'
              style={{ borderRadius: "8px" }}
              id='exampleFormControlInput1'
            />
          </div>
          <div className='form-group'>
            <label for='exampleFormControlInput1' style={{ color: "#43425D" }}>
              Email
            </label>
            <input
              type='text'
              className='form-control'
              style={{ borderRadius: "8px" }}
              id='exampleFormControlInput1'
            />
          </div>
          <div className='form-group'>
            <label for='exampleFormControlInput1' style={{ color: "#43425D" }}>
              Phone Number
            </label>
            <input
              type='tel'
              className='form-control'
              style={{ borderRadius: "8px" }}
              id='exampleFormControlInput1'
            />
          </div>
          <div className='form-group'>
            <label for='exampleFormControlInput1' style={{ color: "#43425D" }}>
              Account Number
            </label>
            <input
              type='text'
              className='form-control'
              style={{ borderRadius: "8px" }}
              id='exampleFormControlInput1'
            />
          </div>
          <div className='form-group'>
            <label
              for='exampleFormControlTextarea1'
              style={{ color: "#43425D" }}
            >
              Message
            </label>
            <textarea
              className='form-control'
              style={{ borderRadius: "8px" }}
              id='exampleFormControlTextarea1'
              rows='3'
            ></textarea>
          </div>
          <div className='form-group text-right mb-0'>
            <button
              type='button'
              style={{
                width: "130px",
                backgroundColor: "#43425D",
                borderRadius: "8px",
              }}
              className='btn text-white'
            >
              Submit
            </button>
          </div>
        </form>
      </span>
    </div>
  );
};

export default PublicChatWidget;
