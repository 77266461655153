/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { Transfer, Camera, X } from '../../assets/icons/jsxIcons';
import endCall from '../../assets/icons/colored/noun_end call_2464046.svg';
import sendButton from '../../assets/icons/colored/Send Button.svg';
import manAvatar from '../../assets/images/avatar-man.png';
import { useUser } from '../../utils/hooks/user';
import { useRequest, useSocketListeners } from '../../utils/hooks';
import api from '../../api';
import { useSocket } from '../../utils/hooks';
import moment from 'moment';
import { transferWait, updateWait } from '../../utils/api/wait';
import { useEmitter } from '../../utils/react-listeners';
import {
  Button,
  CardHeader,
  CardText,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  ModalFooter,
  FormGroup,
  Input,
  Label
} from 'reactstrap';
import useState from 'use-react-state';
import { Pressable } from '../theme';
import { agents } from '../../utils/api/user';
import { capitalize } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import { Interval } from '../common/Time';
import Inifinite from '../common/Infinite';
import { isFileType } from '../../utils';
import { createMessage } from '../../utils/api/message';
import { Day } from '../common/Day';
import { createSummary } from '../../utils/api/summary';
import { toast } from 'react-toastify';
import messageSound from '../../assets/audio/message.mp3';

const ChatSection = ({
  conversation_id,
  otherUser,
  waitId,
  setSession,
  startTime,
  videoRef,
  isEndChatOpen: isEndChatOpen_
}) => {
  const [
    { message, isTransferOpen, isEndChatOpen, file, typingEvent, typingMessage },
    setState,
    stateRef
  ] = useState({
    file: null,
    filebase64: null,
    message: '',
    isTransferOpen: false,
    isEndChatOpen: isEndChatOpen_,
    timeOut: null,
    typingEvent: false,
    typingMessage: ''
  });
  const [modal, setModal] = useState(false);
  const fileRef = useRef();
  const infiniteMessagesRef = useRef();
  const user = useUser();
  const senderUser = useRef({});

  const { socket } = useSocket();

  const emitter = useEmitter();

  const formToggle = () => setModal(!modal);

  const listeners = useMemo(
    () => ({
      [`message.new.${conversation_id}`]: ({ message }) =>
        infiniteMessagesRef.current.setState(({ data } = {}) => {
          data.data.unshift(message);
          setTimeout(() => {
            if ([message?.user?.id, message?.user].includes(user?.user?.id)) {
              infiniteMessagesRef.current.scrollToBottom();
            } else {
              senderUser.current.id = message?.user?.id || message?.user;
              videoRef.current.playSound(messageSound, (sound) => (sound.loop = false));
            }
          }, 80);
        }),
      [`WaitList.closed.${waitId}`]: () => {
        setSession(() => ({}));
        emitter.emit('waits.connected.refresh');
        closeEndChatModal();
      },
      [`typing.${conversation_id}`]: (data) => {
        setState((state) => {
          state.typingEvent = true;
          state.typingMessage = data.data.message;
          if (state.timeOut) {
            clearTimeout(state.timeOut);
          }
          state.timeOut = setTimeout(() => {
            setState({ timeOut: true, typingMessage: '', typingEvent: false });
          }, 2000);
        });
      }
    }),
    [conversation_id, infiniteMessagesRef, user?.id, waitId, emitter, videoRef]
  );

  useSocketListeners({ listeners });

  const sendMessage = useCallback(() => {
    (stateRef.current.message?.length || stateRef.current.file) &&
      socket.emit('messageFromClient', {
        agent: user?.id,
        conversation: conversation_id,
        message: stateRef.current.message
      });
    setState({ message: '', file: null });
  }, [conversation_id, stateRef, user?.id, socket]);

  const sendFile = useCallback(async () => {
    try {
      const data = new FormData();
      data.append('agent', user?.id);
      data.append('conversation', conversation_id);
      data.append('message', stateRef.current.message);
      data.append('attachment', file);

      await createMessage(data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setState({ message: '', file: null });
    } catch (error) {
      console.log(error);
    }
  }, [conversation_id, stateRef, user?.id, socket.connected, file]);

  const fileHandler = (e) => setState({ file: e?.target?.files[0] });

  const onChange = (e) => {
    setState({ message: e.target.value });
    otherUser?.user?.id &&
      socket.emit('typing', {
        to: otherUser?.user.id,
        conversation: conversation_id
      });
  };

  // const endChat = useCallback(async () => {
  //   try {
  //     await updateWait(waitId, { status: 'closed' });
  //     setSession(() => ({}));
  //     emitter.emit('waits.connected.refresh');
  //   } catch (error) {
  //     console.log({ error });
  //   } finally {
  //     closeEndChatModal();
  //   }
  // }, [waitId, setSession]);

  const scrollToBottom = useCallback(() => {
    const el = document.getElementById('messages');
    el.scrollTop = el.scrollHeight;
  }, []);

  useEffect(() => {
    infiniteMessagesRef.current.shouldScrollToBottom = true;
  }, [conversation_id]);

  const toggleTransfer = useCallback(
    () =>
      setState((s) => {
        s.isTransferOpen = !s.isTransferOpen;
      }),
    []
  );

  const emitCallCustomer = () =>
    confirmAlert({
      title: 'Confirmation!',
      message: 'Are you sure you want to call customer?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => emitter.emit('calls.call.customer', otherUser?.user?.id || otherUser?.user)
        },
        {
          label: 'No'
        }
      ]
    });

  const openEndChatModal = useCallback(() => setState({ isEndChatOpen: true }), []);
  const closeEndChatModal = useCallback(() => setState({ isEndChatOpen: false }), []);

  const asyncRequest = useCallback((params) => api.get('messages', { params }), []);

  const queryParams = useMemo(
    () => [{ conversation: conversation_id, limit: 6 }],
    [conversation_id]
  );

  const RenderItem = useCallback(
    ({ item: m, messageRef, index }) => (
      <Message
        {...m}
        isSender={(m.user?.id || m.user) === user?.user?.id}
        messageRef={messageRef}
        index={index}
      />
    ),
    [user?.id]
  );

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        file ? sendFile() : sendMessage();
      }
    };
    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, [sendMessage, sendFile, file]);

  return (
    <div className='card chat-card no-card-border mb-4'>
      <div className='card-head'>
        <div className='card-body card-body-cus p-4'>
          <div className='d-flex align-items-center'>
            <h5 className='mb-0'>
              {otherUser?.first_name} {otherUser?.last_name}
            </h5>
            <span className='agent-connected-txt d-flex ml-auto'>
              <Pressable onClick={emitCallCustomer} className='chat-action'>
                <Camera />
              </Pressable>
              <Pressable onClick={toggleTransfer} className='chat-action'>
                <Transfer />
              </Pressable>
              <button
                style={{ backgroundColor: 'transparent', border: 'none' }}
                disabled={waitId === undefined ? true : false}
                onClick={openEndChatModal}
                className={`chat-action mr-0 ${waitId === undefined ? 'end-chat-btn' : ''}`}>
                <img src={endCall} alt='icon' />
              </button>
            </span>
          </div>
        </div>
      </div>

      <div className='p-4 bg-white'>
        <div className='chat-head d-flex'>
          <button className='btn btn-sm btn-c-darkblue p-0 p-1'>Start CoBrowsing</button>
          <div className='d-flex align-items-center ml-auto'>
            <p className='mb-0 mr-1'>Site Time:</p>
            <Interval
              interval={1000}
              Render={() => (
                <p className='mb-0'>{moment(moment().diff(startTime)).format('mm:ss')}</p>
              )}
            />
          </div>
        </div>
        {/*chat div*/}
        <Inifinite
          ref={infiniteMessagesRef}
          params={queryParams}
          RenderItem={RenderItem}
          name={`messages.${conversation_id}`}
          asyncRequest={asyncRequest}
          loadOnMount={!!conversation_id}
          id='messages'
          style={styles.messages}
          className='mt-4 scroller'
          disableEndReached={true}
          disableBeginReached={false}
          onDataInit={scrollToBottom}
          reversed
        />
        <Row className='handle-content align-items-center justify-content-between'>
          <Col md={1}>
            <Pressable onClick={() => fileRef.current.click()}>
              <i
                style={{
                  color: '#083047',
                  fontSize: '30px'
                }}
                className='ion-paperclip'
              />
              <input
                type='file'
                name='attachment'
                onChange={fileHandler}
                ref={fileRef}
                style={{ display: 'none' }}
              />
            </Pressable>
          </Col>

          <Col md={9}>
            <small>{typingMessage}</small>
            <input
              type='text'
              placeholder='Type your message here...'
              value={message}
              onChange={onChange}
            />
          </Col>

          <Col md={2}>
            <button className='' onClick={file ? sendFile : sendMessage}>
              <img src={sendButton} alt='btn-icon' />
            </button>
          </Col>
        </Row>
        {file?.name && (
          <Row className='d-flex align-items-center'>
            <small className='file-name'>{file?.name}</small>
            <Pressable onClick={() => fileHandler()} className='m-1'>
              X
            </Pressable>
          </Row>
        )}
        <span style={{ fontStyle: 'italic' }}>
          {typingEvent && typingMessage ? `${otherUser.first_name} is typing...` : ''}
        </span>
      </div>
      <TransferModal
        isOpen={isTransferOpen}
        toggle={toggleTransfer}
        otherUser={otherUser}
        waitId={waitId}
        setSession={setSession}
      />
      <EndChatModal close={closeEndChatModal} isOpen={isEndChatOpen} endChat={formToggle} />
      <ChatSummaryForm
        modal={modal}
        formToggle={formToggle}
        conversationId={conversation_id}
        agentId={user?.id}
        closeEndChatModal={closeEndChatModal}
        setSession={setSession}
        waitId={waitId}
      />
    </div>
  );
};

const EndChatModal = memo(({ endChat, isOpen, close }) => (
  <Modal
    style={styles.endModal}
    toggle={close}
    className='shadow-sm  bg-white py-4'
    contentClassName='border-0'
    isOpen={isOpen}>
    <ModalBody className='d-flex flex-column  justify-content-center align-items-center'>
      <Pressable className='ml-auto mr-3' onClick={close}>
        <X width={20} />
      </Pressable>
      <h5 className='py-3'>Are you sure you want to end engagement?</h5>
      <Row align='center'>
        <Col md={6}>
          <Button
            className='d-flex justify-content-center align-items-center mr-3'
            style={styles.yesBtn}
            onClick={endChat}>
            <h5 className=''>Yes</h5>
          </Button>
        </Col>
        <Col md={6}>
          <Button
            className='d-flex justify-content-center align-items-center ml-3'
            color='light'
            style={styles.noBtn}
            onClick={close}>
            <h5 className=''>No</h5>
          </Button>
        </Col>
      </Row>
    </ModalBody>
  </Modal>
));

const TransferModal = memo(({ isOpen, toggle, otherUser, waitId, setSession }) => {
  const [{ loading }, setState] = useState({ loading: false });
  const {
    state: { data: { data = [] } = {}, isLoading }
  } = useRequest(
    {
      loadOnMount: isOpen,
      asyncRequest: agents,
      params: [{ limit: 20, query: { is_online: 1 }, excludeUser: true }]
    },
    [isOpen]
  );

  const emitter = useEmitter();

  const transfer = useCallback(
    async (agent) => {
      try {
        setState({ loading: true });
        await transferWait(waitId, { agent });
        setState({ loading: false });
        emitter.emit('waits.connected.refresh');
        setSession(() => ({}));
        toggle();
      } catch (error) {
        setState({ loading: false });
        console.log({ error });
      }
    },
    [toggle, waitId]
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        Transfer {otherUser?.first_name} {otherUser?.last_name}
      </ModalHeader>
      <ModalBody>
        <CardHeader>
          <h5>Online Agents</h5>
          <div className='agents-head  font-weight-bold'>
            <span>Name</span>
            <span>Team</span>
            <span>Action</span>
          </div>
        </CardHeader>
        <CardText className='scroller p-2'>
          {isLoading && (
            <Col className='d-flex align-items-center justify-content-center'>
              <Spinner />
            </Col>
          )}
          {data.map(({ id, first_name, last_name, team }) => (
            <div key={id} className={`agents-row hover-overlay`}>
              <h6 className='d-flex align-items-center'>
                {first_name} {last_name}
                <div className='ml-1 bg-success p-2 rounded-circle' />
              </h6>
              <p>{capitalize(team?.name)}</p>
              <Button disabled={loading} onClick={() => transfer(id)}>
                {loading ? <Spinner /> : 'Transfer'}
              </Button>
            </div>
          ))}
        </CardText>
      </ModalBody>
    </Modal>
  );
});

const Message = memo(({ message, isSender, createdAt, user, attachment, messageRef, index }) => {
  const file = useMemo(
    () =>
      attachment && {
        name: attachment.split('.').slice(-2, -1),
        ext: attachment?.split('.').pop()
      },
    [attachment]
  );

  return (
    <>
      <Day
        currentMessageAt={createdAt}
        previousMessageAt={messageRef?.current?.data?.data[index - 1]?.createdAt}
      />
      <div
        className={`d-flex chat-message align-items-center ${
          isSender ? 'chat-message--reply' : ''
        } mb-3`}>
        {(isSender || user?.agent) && (
          <div className='chat-avatar m-2'>
            <img
              className='bg-gray'
              src={user?.agent?.profile_picture || manAvatar}
              alt='avatar1'
            />
          </div>
        )}
        <div className='chat-line mr-2'>
          {!!attachment ? (
            isFileType(file.ext) === 'image' ? (
              <img style={{ width: 150, height: 150 }} src={attachment} alt='' className='mb-2' />
            ) : (
              <a target='_blank' rel='noreferrer' href={attachment} download={file.name}>
                {file.name}
              </a>
            )
          ) : null}
          {!!message && <div className='chat-txt'>{message}</div>}
          <div className='chat-time'>{moment(createdAt).format('hh:mm A')}</div>
        </div>
      </div>
    </>
  );
});

const ChatSummaryForm = memo(
  ({ formToggle, modal, agentId, conversationId, closeEndChatModal, setSession, waitId }) => {
    const [state, setState] = useState({
      summary: '',
      case_resolved: '',
      isLoading: false
    });

    const emitter = useEmitter();

    const onChangeInput = ({ target: { value, name } }) => setState({ [name]: value });

    const handleInputValidation = () => {
      const summaryValidation = {
        message: 'Summary is Required',
        status: false
      };
      const caseValidation = {
        message: 'Please indicate case resolution status',
        status: false
      };
      const passed = {
        status: true
      };
      return state.summary === ''
        ? summaryValidation
        : state.case_resolved === ''
        ? caseValidation
        : passed;
    };

    const sendMessage = async (event) => {
      event.preventDefault();

      setState({ isLoading: true });

      const validate = handleInputValidation();

      if (validate?.status) {
        try {
          await createSummary({
            summary: state.summary,
            case_resolved: state.case_resolved,
            agent: agentId,
            conversation: conversationId
          });

          await updateWait(waitId, { status: 'closed' });

          setState({
            isLoading: false,
            summary: '',
            case_resolved: ''
          });

          toast.success('Call Summary saved successfully', {
            autoClose: 2000
          });

          closeEndChatModal();
          formToggle();
          setSession(() => ({}));
          emitter.emit('waits.connected.refresh');
        } catch (err) {
          toast.error('An error occurred', { autoClose: 2000 });
          setState({ isLoading: false });
        }
      } else {
        toast.error(validate.message, { autoClose: 2000 });
        setState({ isLoading: false });
      }
    };

    const handleEnterKeypress = (event) => {
      if (event.key === 'Enter') {
        sendMessage(event);
      }
    };

    const onSubmit = (event) => {
      sendMessage(event);
    };

    return (
      <form id='summaryForm' onSubmit={onSubmit}>
        <Modal
          style={{ maxWidth: '600px', width: '100%', borderRadius: '8px' }}
          size='lg'
          isOpen={modal}
          toggle={formToggle}>
          <ModalHeader toggle={formToggle}></ModalHeader>
          <h4 className='text-center pb-3'>Conversation Summary</h4>
          <ModalBody className='p-4'>
            <div className='col-12'>
              <FormGroup floating>
                <Label for='summary'>Summary</Label>
                <Input
                  onChange={onChangeInput}
                  onKeyDown={handleEnterKeypress}
                  id='summary'
                  type='textarea'
                  name='summary'
                  value={state.summary}
                  resizable='true'
                  placeholder='Type a summary description'
                />
              </FormGroup>
              <FormGroup floating>
                <Label for='case_resolved'>
                  Pick an option to show the status of the issue addressed
                </Label>
                <Input
                  type='select'
                  value={state.case_resolved}
                  name='case_resolved'
                  onChange={onChangeInput}>
                  <option disabled value={''}>
                    Select case resolution status
                  </option>
                  <option value='Resolved'>Resolved</option>
                  <option value='Unresolved'>Unresolved</option>
                </Input>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter
            style={{
              marginBottom: 20
            }}>
            <button
              type='submit'
              form='summaryForm'
              style={{
                width: '200px',
                backgroundColor: '#43425D',
                borderRadius: '8px'
              }}
              className='btn text-white'>
              {state.isLoading ? <Spinner color='light' size='sm' /> : ''}
              Submit
            </button>
          </ModalFooter>
        </Modal>
      </form>
    );
  }
);

const styles = {
  messages: { height: '465px' },
  endModal: { width: 400, borderRadius: 15 },
  yesBtn: {
    backgroundColor: '#083147',
    width: 110,
    height: 40,
    borderRadius: 10
  },
  noBtn: {
    backgroundColor: '#C4C4C4',
    width: 110,
    height: 40,
    borderRadius: 10
  }
};

export default ChatSection;
