import moment from 'moment';
import React, { memo, useCallback, useMemo } from 'react';
import Calendar from 'react-calendar';
import { Col, UncontrolledPopover } from 'reactstrap';
import { ChevronDown } from '../../assets/icons/jsxIcons';
import 'react-calendar/dist/Calendar.css';
import { Pressable, Text } from '../theme';
import { useRequest } from '../../utils/hooks';
import calendars from '../../utils/api/calendar';
import { useUser } from '../../utils/hooks/user';
import useState from 'use-react-state';
import { emitter } from '../../utils/react-listeners';

const RequestCalendar = memo(({ click }) => {
  const [{ opened, date }, setState] = useState({
    opened: false,
    date: moment().format('YYYY-MM-DD')
  });

  const now = useMemo(() => moment().format('ddd, MMM D'), []);

  const onClickDay = useCallback((date) => {
    setState({ date: moment(date).format('YYYY-MM-DD') });
  }, []);

  const value = useMemo(() => moment(date).toDate(), [date]);

  return (
    <Col className='px-0 py-2 rounded-2 bg-white text-blue'>
      <Text style={styles.headtext} className='pl-3 my-2 font-weight-bold'>
        CallBack Requests
      </Text>
      <Text role='button' id='action-date' className='pl-3 my-2 color-primary font-weight-bold'>
        {now}
        <ChevronDown width={20} />
      </Text>
      <UncontrolledPopover
        placement='bottom'
        isOpen={opened}
        target='action-date'
        trigger='legacy'
        toggle={() =>
          setState((s) => {
            s.opened = !s.opened;
          })
        }>
        <Calendar
          value={value}
          onClickDay={onClickDay}
          className='border-0 shadow p-3 bg-white rounded'
        />
      </UncontrolledPopover>
      <Col style={styles.today} className='pl-0 d-flex border-top border-bottom align-items-center'>
        <Text style={styles.headtext} className='font-weight-bold p-3'>
          {moment(date).calendar(null, {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: '[last] dddd',
            nextWeek: 'dddd',
            sameElse: 'L'
          })}
        </Text>
      </Col>
      <Calbbacks date={date} click={click} />
    </Col>
  );
});

const Calbbacks = memo(({ date: scheduledAt, click }) => {
  const id = useUser(({ id } = {}) => id);

  const {
    state: {
      data: { data: callbacks = [] },
      isLoading
    }
  } = useRequest(
    {
      asyncRequest: calendars,
      params: [{ scheduledAt, actionist: id, status: 'accepted' }]
    },
    [id, scheduledAt]
  );

  return (
    <Col
      style={{
        maxHeight: '245px'
      }}
      className='scroller'>
      {isLoading
        ? 'loading...'
        : callbacks?.map(({ scheduledAt, reason, conversation, customer, id }) => (
            <Callback
              key={id}
              click={click}
              customer={customer}
              scheduledAt={scheduledAt}
              reason={reason}
              conversation={conversation}
            />
          ))}
    </Col>
  );
});

const Callback = memo(({ reason, conversation, scheduledAt, click, customer }) => {
  const past = useMemo(() => moment(scheduledAt).add(1, 'hour').isBefore(), [scheduledAt]);

  const onClick = () => {
    if (conversation) {
      click({
        conversation_id: conversation?.id || conversation,
        customer,
        startTime: new Date(),
        waitId: conversation.waitLists?.[0]?.id
      });

      conversation.waitLists?.[0]?.id && emitter.emit('waits.connected.refresh');
    }
  };

  const isConnected = !!conversation.waitLists?.[0]?.id;

  return (
    <Pressable disabled={past} onClick={onClick}>
      <Col
        className={`rounded my-2 p-2 ${
          past ? 'bg-white border' : isConnected ? 'bg-success' : 'color-bg-primary'
        }`}>
        <Text className={`${past ? 'color-primary' : 'text-white'}`}>
          {customer?.first_name} {customer?.last_name} | {reason}
        </Text>
        <Text className={`${past ? 'color-primary' : 'text-white'}`}>
          {moment(scheduledAt).format('hh:mm A')}
          {' - '}
          {moment(scheduledAt).add(1, 'h').format('hh:mm A')}
        </Text>
      </Col>
    </Pressable>
  );
});

const styles = {
  today: { borderLeft: '3px solid #083047' },
  headtext: {
    fontSize: 16,
    color: '#083047'
  }
};

export default RequestCalendar;
