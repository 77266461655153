import React, { memo, useEffect, useState } from "react";

export const Interval = memo(({ Render, interval = 1000 * 60 * 5 }) => {
  const [, setState] = useState();

  useEffect(() => {
    const intv = setInterval(() => setState((bool) => !bool), [interval]);

    return () => clearInterval(intv);
  }, [interval]);

  return <Render />;
});
