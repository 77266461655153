import React from "react";
import "./style.css";
import { coloredIcons } from "../../assets/icons";
import NonPath from "../../assets/images/NoPath - Copy (4).png";
import Avatar from "../../assets/images/avatar-man.png";

const index = () => {
  return (
    <div
      className='main-content'
      style={{ height: "100vh", overflowY: "auto" }}
    >
      <span className='chat-icon'>
        <img
          alt=''
          id='chat-icon'
          src={coloredIcons["Group 11344.svg"].default}
          width='50px'
          height='50px'
        />
      </span>

      <span className='details-form' id='details-form'>
        <div className='header'>
          <span style={{ fontWeight: "bold", fontSize: "20px" }}>
            Video Banking
          </span>
          <br />
          <small>How can we help you.</small>
        </div>
        <span className='top-img'>
          <img alt='' src={NonPath} width='100%' height='150px' />
        </span>
        <div
          style={{ height: "400px !important" }}
          className='card chat-card no-card-border'
        >
          <div className='card-content chat-content pt-3'>
            <div className='chat-history mt-4'>
              <div className='d-flex chat-message align-items-center'>
                <div className='chat-avatar mr-1'>
                  <img src={NonPath} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>

              <div className='d-flex chat-message chat-message--reply align-items-center mb-3'>
                <div className='chat-avatar ml-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>
              <div className='d-flex chat-message align-items-center mb-3'>
                <div className='chat-avatar mr-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>

              <div className='d-flex chat-message chat-message--reply align-items-center mb-3'>
                <div className='chat-avatar ml-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>
              <div className='d-flex chat-message align-items-center mb-3'>
                <div className='chat-avatar mr-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>

              <div className='d-flex chat-message chat-message--reply align-items-center mb-3'>
                <div className='chat-avatar ml-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>
              <div className='d-flex chat-message align-items-center mb-3'>
                <div className='chat-avatar mr-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>

              <div className='d-flex chat-message chat-message--reply align-items-center mb-3'>
                <div className='chat-avatar ml-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>
              <div className='d-flex chat-message align-items-center mb-3'>
                <div className='chat-avatar mr-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>

              <div className='d-flex chat-message chat-message--reply align-items-center mb-3'>
                <div className='chat-avatar ml-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>
              <div className='d-flex chat-message align-items-center mb-3'>
                <div className='chat-avatar mr-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>

              <div className='d-flex chat-message chat-message--reply align-items-center mb-3'>
                <div className='chat-avatar ml-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>
              <div className='d-flex chat-message align-items-center mb-3'>
                <div className='chat-avatar mr-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>

              <div className='d-flex chat-message chat-message--reply align-items-center mb-3'>
                <div className='chat-avatar ml-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>
              <div className='d-flex chat-message align-items-center mb-3'>
                <div className='chat-avatar mr-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>

              <div className='d-flex chat-message chat-message--reply align-items-center mb-3'>
                <div className='chat-avatar ml-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>
              <div className='d-flex chat-message align-items-center mb-3'>
                <div className='chat-avatar mr-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>

              <div className='d-flex chat-message chat-message--reply align-items-center mb-3'>
                <div className='chat-avatar ml-1'>
                  <img src={Avatar} alt='avatar' />
                </div>
                <div className='chat-line'>
                  <div className='chat-txt'>
                    Hye how are you Hye how are you Hye how are you Hye how are
                    you
                  </div>
                  <div className='chat-time'>10:24</div>
                </div>
              </div>
            </div>
          </div>

          <div className='message-handle'>
            <div className='handle-content'>
              <input type='text' placeholder='Type your message here...' />

              <div className='handle-buttons d-flex justify-content-between'>
                <p role='button' className='handle-attach'>
                  <i className='ion-paperclip'></i>
                </p>

                <p role='button' className='btn-send'>
                  <img
                    src={coloredIcons["Send Button.svg"].default}
                    alt='btn-icon'
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </span>
    </div>
  );
};

export default index;
