import React from 'react';
import './style.css';

export default function TabWrapper({ tabName, children }) {
  return (
    <div className='tab-container'>
      <div className='pl-2 pr-2'>
        <h4 className='pt-4 pl-5'>{tabName}</h4>
        <hr />
        {children}
      </div>
    </div>
  );
}
