import React from "react";

function ReportsNew() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            stroke='currentColor'
            width='22'
            height='22'
            viewBox='0 0 24 24'
        >
            <path
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    d='M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z'
    />
        </svg>
    );
}

export default ReportsNew;
