import { createContext, useReducer } from "react";
import useEventListener from "../../utils/react-listeners";
const AuthContext = createContext({});
const AuthConsumer = AuthContext.Consumer;

const initialState = {
  isAuth: false,
  userEmail: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("profile", JSON.stringify(action.payload.user));
      return { ...state, isAuth: true };
    case "SET_LOGIN":
      let istoken = localStorage.getItem("token");
      !istoken && localStorage.setItem("token", action.payload.token);
      return { ...state, isAuth: true, userEmail: action.payload.email };
    case "LOGOUT":
      localStorage.removeItem("token");
      return { ...state, isAuth: false };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(reducer, initialState);

  useEventListener({
    listeners: { "log-out": () => authDispatch({ type: "LOGOUT" }) },
  });

  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider, AuthConsumer };
