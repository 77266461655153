import React, { memo } from 'react';
import { Col, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import {
  Desktop,
  Expand,
  Mic,
  MicOff,
  Phone,
  PhoneMissed,
  Video,
  VideoAlt,
  VideoOff
} from '../../assets/icons/jsxIcons';
import { user } from '../../utils/api/user';
import { useRequest } from '../../utils/hooks';
import { Pressable, Text } from '../theme';

function VideoChat({
  endCall,
  style = {},
  isVisible,
  muted,
  mute,
  fullScreen,
  startScreenSharing,
  stopScreenSharing,
  screenShared,
  toggleVideo,
  videoOff,
  remoteVideoRef,
  localVideoRef
}) {
  return (
    <div
      id='video-call-container'
      style={{
        height: '250px',
        display: isVisible ? 'block' : 'none',
        ...style
      }}
      className='chat-interaction'>
      <video
        ref={remoteVideoRef}
        style={{ objectFit: 'cover' }}
        className='chat-incoming'
        id='remote-video'
        autoPlay
      />
      <video
        ref={localVideoRef}
        style={{ objectFit: 'cover' }}
        className='chat-out'
        id='local-video'
        autoPlay
        muted
      />
      <Controls
        endCall={endCall}
        muted={muted}
        mute={mute}
        fullScreen={fullScreen}
        startScreenSharing={startScreenSharing}
        stopScreenSharing={stopScreenSharing}
        screenShared={screenShared}
        toggleVideo={toggleVideo}
        videoOff={videoOff}
      />
    </div>
  );
}

const Controls = ({
  endCall,
  muted,
  mute,
  fullScreen,
  startScreenSharing,
  stopScreenSharing,
  screenShared,
  toggleVideo,
  videoOff
}) => {
  return (
    <Row
      id='video-call-actions'
      className='justify-content-end align-items-center p-2 pr-4'
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        display: 'none'
      }}>
      {!!fullScreen && (
        <ActionBtn onClick={fullScreen}>
          <Expand width={20} height={20} />
        </ActionBtn>
      )}
      {!!mute && (
        <ActionBtn onClick={mute}>
          {muted ? <MicOff fill='#fff' width={20} height={20} /> : <Mic width={20} height={20} />}
        </ActionBtn>
      )}
      {!!toggleVideo && (
        <ActionBtn onClick={toggleVideo}>
          {videoOff ? (
            <VideoOff fill='#fff' width={20} height={20} />
          ) : (
            <VideoAlt width={20} height={20} />
          )}
        </ActionBtn>
      )}
      {!!(startScreenSharing || stopScreenSharing) && (
        <ActionBtn onClick={screenShared ? stopScreenSharing : startScreenSharing}>
          <Desktop width={20} height={20} />
        </ActionBtn>
      )}
      {endCall && (
        <ActionBtn onClick={endCall}>
          <PhoneMissed width={20} height={20} />
        </ActionBtn>
      )}
    </Row>
  );
};

const ActionBtn = ({ children, ...props }) => (
  <Pressable
    style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
    className='p-2 px-2 rounded-circle mx-2 text-white'
    {...props}>
    {children}
  </Pressable>
);

export const IncomingVideoCallModal = memo(({ isIncoming, answerCall, rejectCall, videoRef }) => {
  const {
    state: { isLoading, data: { data } = {} }
  } = useRequest(
    {
      asyncRequest: user,
      params: [videoRef?.current?.data?.from],
      loadOnMount: !!videoRef?.current?.data?.from
    },
    [videoRef?.current?.data?.from]
  );

  return (
    <Modal
      backdrop={false}
      toggle={rejectCall}
      contentClassName='border-0'
      className='rounded'
      style={styles.incomingModal}
      isOpen={isIncoming}>
      <ModalBody
        style={styles.incomingModalBody}
        className='d-flex flex-column  align-items-center'>
        <Row className='d-flex align-items-center justify-content-center mt-5'>
          <Col>
            <div style={styles.line} />
          </Col>
          <Col>
            <Text className='text-white'>Incoming Video Call</Text>
          </Col>
          <Col>
            <div style={styles.line} />
          </Col>
        </Row>
        <div style={styles.imgCont} className='justify-content-center mb-5'>
          <img src={data?.profile?.picture} alt='profile' style={styles.img} />
        </div>
        {isLoading ? (
          <Spinner className='d-flex align-items-center justify-content-center' />
        ) : (
          <>
            <h1 className='text-white text-center mt-5'>
              {data?.profile?.name} {data?.profile?.last_name}
            </h1>
            <Text className='text-white text-center'>{data?.team}</Text>
          </>
        )}
        <Row className='mt-5'>
          <Col md={6}>
            <Pressable
              className='d-flex rounded-circle bg-danger align-items-center justify-content-center'
              style={{ ...styles.callBtn, ...styles.rejectIcon }}
              onClick={rejectCall}>
              <Phone width={40} fill='#fff' stroke={null} />
            </Pressable>
          </Col>
          <Col md={6}>
            <Pressable
              style={styles.callBtn}
              className='d-flex rounded-circle bg-success align-items-center justify-content-center'
              onClick={answerCall}>
              <Video width={40} />
            </Pressable>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
});

const styles = {
  callBtn: {
    width: 90,
    height: 90
  },
  rejectIcon: {
    transform: 'rotateZ(130deg)'
  },
  img: {
    width: 150,
    height: 150
  },
  imgCont: {
    width: 150,
    height: 150,
    backgroundColor: 'gray',
    borderRadius: '50%',
    overflow: 'hidden',
    marginTop: '6rem'
  },
  line: {
    backgroundColor: '#fff',
    width: 180,
    height: 1
  },
  incomingModalBody: {
    backgroundColor: '#083147',
    width: 600,
    height: 600
  },
  incomingModal: {
    width: 600
  }
};

export default memo(VideoChat);
