import React, { memo } from 'react';
import moment from 'moment';
import '../ChatRecord/style.css';

export const Day = memo(({ currentMessageAt, previousMessageAt }) => {
  if (currentMessageAt && !isSameDay(currentMessageAt, previousMessageAt)) {
    return (
      <div className='date-divider'>
        <hr className='divider-line' />
        <div className='divider-wrapper'>
          <p className='chat-date'>
            {moment(currentMessageAt).calendar(null, {
              sameDay: '[Today]',
              nextDay: '[Tomorrow]',
              nextWeek: 'dddd',
              lastDay: '[Yesterday]',
              lastWeek: 'ddd ll',
              sameElse: 'ddd ll'
            })}
          </p>
        </div>
      </div>
    );
  }
  return null;
});

const isSameDay = (currentMessageAt, diffMessageAt) => {
  if (!diffMessageAt) {
    return false;
  }

  const currentCreatedAt = moment(currentMessageAt);
  const diffCreatedAt = moment(diffMessageAt);

  if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) {
    return false;
  }

  return currentCreatedAt.isSame(diffCreatedAt, 'day');
};
