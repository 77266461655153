import React from "react";

const ConnectedUserWidget = ({ data }) => {
  return (
    <div
      className='card p-3 d-flex flex-row justify-content-between align-items-center '
      style={{ borderRadius: "8px", padding: "2%" }}
    >
      <div className='d-flex flex-column'>
        <div className='mb-2'>
          <h5 style={{ color: "#9CACB5" }}>Right Now</h5>
        </div>
        <div>
          <h5 style={{ fontWeight: "bolder" }}>{data.status}</h5>
        </div>
      </div>
      <div>
        <h1 className='pr-3' style={{ fontWeight: "900px", fontSize: "36px" }}>
          {data.count}
        </h1>
      </div>
    </div>
  );
};

export default ConnectedUserWidget;
