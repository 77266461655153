import { DashboardNew, RealTimeChatNew, TelephonePlus } from '../assets/icons/jsxIcons/index';
import ChatRecordsNew from '../assets/icons/jsxIcons/chatRecordsNew';

export const AgentData = [
  {
    title: 'Dashboard',
    route: 'DASHBOARD',
    Icon: DashboardNew
  },
  {
    title: 'Real Time Chat',
    route: 'REAL_TIME_CHAT',
    Icon: RealTimeChatNew
  },
  {
    title: 'Call Requests',
    route: 'CALL_REQUESTS',
    Icon: TelephonePlus
  },
  {
    title: 'Chat Records',
    route: 'CHAT_RECORDS',
    Icon: ChatRecordsNew
  }
];
