import React, { useState, useEffect } from 'react';
import '../../assets/css/topNav.css';
import IconCalendar from '../../assets/icons/agent/icon_calendar.svg';
import { Link } from 'react-router-dom';
import FilterIcon from '../../assets/icons/colored/filter.svg';
import { useUser } from '../../utils/hooks/user';
import { useEmitter } from '../../utils/react-listeners';
import axios from 'axios';
import api from '../../api';
import './style.css';

import {
  Col,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip
} from 'reactstrap';
import Broadcasts, { CreateBroadcast } from '../Broadcasts';
import { AccountCircle, ExitToAppTwoTone, HelpOutlineRounded } from '@material-ui/icons';

const source_url = window.location.href;

const MainContent = ({ noHeader, sideState, ...props }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [{ ip }, setState] = useState({
    ip: undefined
  });
  const profileData = useUser();
  const emitter = useEmitter();

  const handleLogout = async () => {
    await api.post('/logout', { source_url, ip });
    emitter.emit('log-out');
  };

  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then((data) => {
        const { ip } = data.data;

        setState({
          ip
        });
      })
      .catch((error) => console.log('error => ', error));
  }, [ip]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toolTipToggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      {noHeader ? (
        <Col
          style={{
            backgroundColor: '#FFFFFF',
            height: '77px',
            width: '100%',
            paddingTop: '20px',
            marginBottom: '1rem',
            position: 'fixed',
            top: 0,
            paddingRight: sideState ? '5rem' : '18rem',
            zIndex: 100
          }}>
          <Row className='align-items-center justify-content-end'>
            <HelpOutlineRounded
              style={{
                width: '25px',
                height: '25px',
                marginRight: '12px'
              }}
              onClick={() => window.open('https://sawport.freshdesk.com/support/home')}
              id='help'
            />
            <Tooltip placement={'left'} isOpen={tooltipOpen} target={'help'} toggle={toolTipToggle}>
              Help Center
            </Tooltip>
            <CreateBroadcast />
            <Broadcasts />
            <div
              className='d-flex'
              style={{
                marginRight: '20px',
                marginLeft: '5px',
                color: '#000'
              }}>
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                style={{
                  backgroundColor: 'white'
                }}>
                <DropdownToggle caret color='default' size='lg'>
                  <img
                    src={profileData?.profile_picture}
                    width={'100%'}
                    style={{
                      borderRadius: '50%',
                      width: '35px',
                      height: '35px',
                      marginRight: '5px'
                    }}
                    alt='avatar'
                  />
                </DropdownToggle>
                <DropdownMenu
                  style={{
                    width: '30px',
                    marginRight: '5px'
                  }}>
                  <DropdownItem onClick={() => props.history.push('/profile')}>
                    <div className='d-flex'>
                      <AccountCircle
                        style={{
                          width: '15px',
                          height: '15px',
                          marginRight: '10px'
                        }}
                      />
                      <p>Profile</p>
                    </div>
                  </DropdownItem>
                  <DropdownItem onClick={handleLogout}>
                    <div className='d-flex'>
                      <ExitToAppTwoTone
                        style={{
                          width: '15px',
                          height: '15px',
                          marginRight: '10px'
                        }}
                      />
                      <p>Logout</p>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </Row>
        </Col>
      ) : (
        <div className='top-nav'>
          <div className='d-flex justify-content-start' style={{ maxWidth: '48%' }}>
            <div className='dropdown disabled'>
              <button
                className='btn bg-white dropdown-toggle disabled'
                style={{ borderRadius: '8px' }}
                type='button'
                id='dropdownMenuButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'>
                <img src={IconCalendar} className='mr-2' width='17px' alt='' />
                Month
              </button>
              <div className='dropdown-menu disabled' aria-labelledby='dropdownMenuButton'>
                <Link>Action</Link>
                <Link>Another action</Link>
                <Link>Something else here</Link>
              </div>
            </div>
            <div>
              <span className='filter'>
                <img src={FilterIcon} width='15px' alt='' />
              </span>
            </div>
          </div>

          <div
            className='d-flex justify-content-between'
            style={{ maxWidth: '48%', minWidth: '10%' }}>
            <span style={{ position: 'relative' }}>
              <Link>
                <i
                  className='ion-ios-bell-outline'
                  style={{ fontSize: '24px', color: '#9CACB5' }}
                />
              </Link>
              <span className='red-dot' />
              <div className='dropdown'>
                <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <Link className='dropdown-item' style={{ display: 'inline-block' }}>
                      You have <super>0</super>
                      new messages{' '}
                    </Link>
                    <Link
                      style={{
                        color: '#A7BBFF',
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        cursor: 'pointer'
                      }}
                      className='p-2 view-all'>
                      {' '}
                      view all
                    </Link>
                  </div>
                </div>
              </div>
            </span>
            <Link style={{ transform: 'rotate(317deg)' }}>
              <i className='ion-android-send' style={{ fontSize: '24px', color: '#9CACB5' }} />
            </Link>
            <Link>
              <i
                className='ion-ios-location-outline'
                style={{ fontSize: '24px', color: '#9CACB5' }}
              />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default MainContent;
