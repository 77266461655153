import React, { useState, useContext, useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Alert, Spinner, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { useMutation } from 'react-query';
import { Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import api from '../../api';
import logo from '../../assets/images/logo.png';
import { AuthContext } from '../../contexts/auth';
import routes from '../../constants/routes';
import './style.css';
import { GoogleLogin } from 'react-google-login';
import { azureLogin, handleGoogleSingleSignOnUserLogin } from '../../utils/api/sso';
import { toast } from 'react-toastify';
import { LoginSocialMicrosoft } from 'reactjs-social-login';
import { MicrosoftLoginButton } from 'react-social-login-buttons';
import { Microsoft } from '../../assets/icons/jsxIcons';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const validSchema = Yup.object().shape({
  email: Yup.string().email('Incorrect format').required('Required'),
  password: Yup.string().required('Required')
});

const source_url = window.location.href;

export default function Login() {
  const { authDispatch } = useContext(AuthContext);
  const history = useHistory();
  const [errorApi, setErrorApi] = useState('');
  const [{ ip }, setState] = useState({
    ip: undefined
  });

  let token = localStorage.getItem('token');

  const { isError, isLoading, mutate, error } = useMutation(
    ({ email, password }) => api.post('/login', { email, password, ip, source_url }),
    {
      onSuccess: async ({ data }) => {
        if (data.token) {
          authDispatch({
            type: 'LOGIN',
            payload: data
          });
          history.push('/');
        } else if (!data.status) {
          setErrorApi(data.msg);
        } else {
          setErrorApi('');
        }
      }
    }
  );

  const handleSuccess = async (response) => {
    try {
      if ('accessToken' in response) {
        const token = response.accessToken;

        const { data } = await handleGoogleSingleSignOnUserLogin({ token, ip, source_url });

        if (data?.error?.status === 401) {
          toast.info('Unauthorized email', { autoClose: 2000 });
        }

        if (data.token) {
          toast.info('Logging you in', { autoClose: 2000 });

          authDispatch({
            type: 'LOGIN',
            payload: data
          });
          history.push('/');
        }
      }
    } catch (error) {
      toast.error('An error occurred', { autoClose: 2000 });
    }
  };

  const onFailure = (response) => {
    toast.error('An error occurred while authenticating user', { autoClose: 2000 });
  };

  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then((data) => {
        const { ip } = data.data;

        setState({
          ip
        });
      })
      .catch((error) => console.log('error => ', error));
  }, [ip]);

  const onResolveMS = async ({ data }) => {
    try {
      const { data: _data } = await azureLogin({ access_token: data.id_token, ip, source_url });
      if (_data.token) {
        authDispatch({
          type: 'LOGIN',
          payload: _data
        });
        history.push('/');
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.info('Unauthorized email', { autoClose: 2000 });
      } else {
        toast.error('An error occurred', { autoClose: 2000 });
      }
    }
  };

  const onReject = () => toast.error('An error occurred', { autoClose: 2000 });

  if (token) {
    return <Redirect to={routes.DASHBOARD} />;
  }

  return (
    <div>
      <div className='main-div'>
        <div className='card signin-page pb-4 px-5' styles={{ borderRadius: '8px' }}>
          <Formik
            validationSchema={validSchema}
            onSubmit={mutate}
            initialValues={{
              email: '',
              password: ''
            }}>
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <div style={{ textAlign: 'center' }} className='mb-1'>
                  <img src={logo} alt='logo' width='250px' />
                </div>
                {isError ? (
                  <Alert className='mt-3' color='danger'>
                    {error?.response?.data?.msg ||
                      '“Invalid email or password, please enter a valid email or password'}
                  </Alert>
                ) : (
                  ''
                )}
                {errorApi ? (
                  <Alert className='mt-3' color='danger'>
                    {errorApi}
                  </Alert>
                ) : (
                  ''
                )}
                <FormGroup>
                  <Label size='lg' for='exampleEmail'>
                    Email address
                  </Label>
                  <Input
                    name='email'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    valid={formik.touched.email && !formik.errors.email}
                    invalid={formik.touched.email && formik.errors.email}
                    placeholder='Enter email'
                    bsSize='lg'
                  />
                  <FormFeedback invalid>{formik.errors.email}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label size='lg' for='examplePassword'>
                    Password
                  </Label>
                  <Input
                    name='password'
                    type='password'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.emai}
                    valid={formik.touched.password && !formik.errors.password}
                    invalid={formik.touched.password && formik.errors.password}
                    placeholder='Enter password'
                    bsSize='lg'
                  />
                  <FormFeedback invalid>{formik.errors.password}</FormFeedback>
                </FormGroup>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mt-2'>
                      <FormGroup check>
                        <Input type='checkbox' className='remember' />
                        <Label check className='ml-2 remember'>
                          Remember Me
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mt-2 ml-5'>
                      <Link to={routes.FORGOT_PASSWORD} className='custom'>
                        Forgot your password?
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className='mt-5 mb-2'
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                  <button
                    type='submit'
                    className='d-flex justify-content-center align-items-center btn btn-lg btn-block btn-login-clr  p-2'>
                    {isLoading ? <Spinner color='light' size='sm' /> : ''}
                    <span className='ml-1'>Login</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div
            className='mt-0 mb-2'
            style={{
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <div className='my-3 divider'>
              <hr className='line' />
              <div className='option-wrapper'>
                <p className='option'>Or</p>
              </div>
            </div>
            <GoogleLogin
              className='d-flex justify-content-center align-items-center btn btn-lg btn-block btn-login-clr auth'
              clientId={clientId}
              buttonText='Sign in with Google'
              onSuccess={handleSuccess}
              onFailure={onFailure}
              cookiePolicy={'single_host_origin'}
            />

            <LoginSocialMicrosoft
              isOnlyGetToken
              client_id={process.env.REACT_APP_MICROSOFT_APP_ID}
              redirect_uri={window.location.href}
              onResolve={onResolveMS}
              onReject={onReject}>
              <MicrosoftLoginButton
                className='auth google-auth-btn'
                icon={Microsoft}
                activeStyle={{ background: 'transparent' }}
              />
            </LoginSocialMicrosoft>
          </div>
        </div>
        <div className='d-flex copyright mt-5'>
          <div className='pr-3'>
            <p>Copyright © 2022 - Sawport Inc.</p>
          </div>
          <div className=''>
            <Link
              className='custom'
              to={{ pathname: 'https://sawport.com/privacy-policy/' }}
              target='_blank'>
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
