import React, { useEffect, useState } from 'react';
import './style.css';
import { useParams } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import api from '../../api';
import moment from 'moment';
import { Spinner, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import attachmentIcon from '../../assets/icons/attachment-dark.png';
import { user, viewAgent } from '../../utils/api/user';
import { convertIPv6AddresstoIPv4, groupMessagesByDate } from '../../utils/file';
import { isFileType, mask } from '../../utils';
import manAvatar from '../../assets/images/avatar-man.png';
import { viewSummary } from '../../utils/api/summary';

const emailFieldState = {
  messageToId: '',
  name: '',
  subject: '',
  details: '',
  isLoading: false
};

const ChatRecords = () => {
  let { conversationId, customerId, agentId } = useParams();
  const [emailState, setEmailState] = useState(emailFieldState);
  const [agent, setAgent] = useState({});
  const [customer, setCustomer] = useState({});
  const [messages, setMessages] = useState([]);
  const [files, setFiles] = useState(null);
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false);
  const [summaryData, setSummaryData] = useState({});
  const toggle = () => {
    setModal(!modal);
    setEmailState({
      ...emailState,
      name: '',
      subject: '',
      details: ''
    });
    setFile(null);
  };

  const summaryModalToggle = () => setSummaryModal(!summaryModal);

  const getChatRecord = async () => {
    setLoading(true);
    const { data } = await viewAgent(agentId);
    const customerData = await user(customerId);
    const chatMessages = await api.get(
      `messages?conversation=${conversationId}&orderBy=createdAt&order=DESC`
    );

    const chats = chatMessages?.data?.data;

    setCustomer(customerData?.data?.customer);
    if (chatMessages.status === 200) {
      setMessages(chats);
      setLoading(false);
    }
    if (data) setAgent(data);
  };

  const getSummary = async () => {
    try {
      setLoading(true);
      const data = await viewSummary(conversationId);
      if (data.status === 200) {
        setSummaryData(data.data);
        setLoading(false);
      }
    } catch (error) {
      console.log('Error retrieving summary', error);
    }
  };

  const handleInputChange = ({ target: { value, name } }) => {
    setEmailState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileUpload = (e) => {
    setFiles(e.target?.files[0]);
    setFile(e.target?.files[0]?.name);
  };

  useEffect(() => {}, [files]);

  const handleValidation = () => {
    let nameValidation = {
      message: 'Name is Required',
      status: false
    };
    let subjectValidation = {
      message: 'Subject is required',
      status: false
    };
    let messageValidation = {
      message: 'Message is required',
      status: false
    };
    let passed = {
      status: true
    };
    return emailState.name !== ''
      ? emailState.subject === ''
        ? subjectValidation
        : emailState.details === ''
        ? messageValidation
        : passed
      : nameValidation;
  };

  useEffect(() => {
    getChatRecord();
  }, []);

  useEffect(() => {
    getSummary();
  }, []);

  const handleEmailSend = async (e) => {
    e.preventDefault();
    let validation = handleValidation();
    setEmailState({
      ...emailState,
      isLoading: true
    });
    let formData = new FormData();
    if (files || file) {
      formData.append('attachment', files);
      formData.append('name', emailState.name);
      formData.append('subject', emailState.subject);
      formData.append('message', emailState.details);
      formData.append('receiverId', customerId);
    } else {
      formData.append('name', emailState.name);
      formData.append('subject', emailState.subject);
      formData.append('message', emailState.details);
      formData.append('receiverId', customerId);
    }

    if (validation.status) {
      try {
        let res = await api.post(`/messages/email-reply`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data; '
          }
        });
        if (res?.data?.status) {
          toast.success(res?.data?.message, {
            autoClose: 2000
          });
          toggle();
        } else {
          toast.error(res?.data?.message, { autoClose: 2000 });
        }
      } catch (err) {
        toast.error('Email not sent', { autoClose: 2000 });
      }
    } else {
      toast.error(validation.message, { autoClose: 2000 });
    }
    setEmailState({
      ...emailState,
      isLoading: false
    });
  };

  const today = moment().format('YYYY-MM-DD');
  const yesterday = moment().add(-1, 'days').format('YYYY-MM-DD');

  const groupedResponse = groupMessagesByDate(messages);

  return (
    <>
      <div className='card-title pl-3 h4 mb-0 font-weight-bold text-nowrap'>Chat Records</div>
      <div className='row mt-4 chat-record'>
        <div className='col-md-8'>
          {loading && !messages?.length ? (
            <SkeletonTheme color='#eee'>
              <Skeleton width={100} height={10} count={5} className='ml-2' />
            </SkeletonTheme>
          ) : (
            <div className='card chat-card no-card-border m-0 w-100 record-card'>
              <div className='card-head'>
                <div className='row card-body card-body-cus p-4'>
                  <div className='col-md-6 d-flex align-items-center'>
                    <h5
                      style={{
                        color: '#000',
                        fontWeight: 'bold',
                        textTransform: 'capitalize'
                      }}
                      className='mb-0 mr-1'>
                      {Object.keys(agent).length === 0 && agent.constructor === Object
                        ? 'No Agent chatted'
                        : `${agent?.first_name} ${agent?.last_name}`}
                    </h5>
                    <h5 className='mb-0'>
                      {' '}
                      with {customer?.last_name} {customer?.first_name}
                    </h5>
                  </div>
                  <div className='col-md-6 d-flex justify-content-end'>
                    <button type='button' className='btn btn-success' onClick={summaryModalToggle}>
                      Conversation summary
                    </button>
                  </div>
                </div>
              </div>

              <div className='card-content chat-content pt-3 pb-3 record-content'>
                <div className='chat-history mt-4' style={{ height: '33rem' }}>
                  {groupedResponse?.length &&
                    groupedResponse.map((item) => {
                      return (
                        <>
                          <div className='date-divider'>
                            <hr className='divider-line' />
                            <div className='divider-wrapper'>
                              <p className='chat-date'>
                                {item.date === today
                                  ? 'Today'
                                  : item.date === yesterday
                                  ? 'Yesterday'
                                  : moment(item.date).format('ddd, D MMM')}
                              </p>
                            </div>
                          </div>
                          {/*Chat messages */}
                          {item?.messages.map((messageItem) => {
                            return (
                              <>
                                <div
                                  className={`d-flex chat-message align-items-center ${
                                    !messageItem.user?.customer ? 'chat-message--reply' : ''
                                  } mb-3`}>
                                  {(!messageItem.user?.customer || messageItem.user?.agent) && (
                                    <div className='chat-avatar m-2'>
                                      <img
                                        className='bg-gray'
                                        src={messageItem.user?.agent?.profile_picture || manAvatar}
                                        alt='avatar1'
                                      />
                                    </div>
                                  )}
                                  <div className='chat-line mr-2'>
                                    {!!messageItem.attachment ? (
                                      isFileType(messageItem.attachment?.split('.').pop()) ===
                                      'image' ? (
                                        <img
                                          style={{ width: 150, height: 150 }}
                                          src={messageItem.attachment}
                                          alt=''
                                          className='mb-2'
                                        />
                                      ) : (
                                        <a
                                          href={messageItem.attachment}
                                          download={messageItem?.attachment
                                            ?.split('.')
                                            .slice(-2, -1)}>
                                          {messageItem?.attachment?.split('.').slice(-2, -1)}
                                        </a>
                                      )
                                    ) : null}
                                    {!!messageItem.message && (
                                      <div className='chat-txt'>{messageItem.message}</div>
                                    )}
                                    <div className='chat-time'>
                                      {moment(messageItem.createdAt).format('hh:mm A')}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='col-md-4'>
          <div className='card p-3'>
            {loading && !agent ? (
              <SkeletonTheme color='#eee'>
                <Skeleton width={100} height={10} count={5} className='ml-2' />
              </SkeletonTheme>
            ) : (
              <>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='chat-infos'>
                      <h6 className=''>Full Name</h6>
                      <p
                        style={{ color: '#000' }}
                        className='mb-1 font-weight-bold text-capitalize'>
                        {`${customer?.first_name} ${customer?.last_name}` || '-'}
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='chat-infos'>
                      <h6 className=''>Email</h6>
                      <p style={{ color: '#000' }} className='mb-1 font-weight-bold'>
                        {customer?.email || '-'}
                      </p>
                    </div>
                  </div>
                </div>

                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <div className='chat-infos'>
                      <h6 className=''>Location</h6>
                      <p style={{ color: '#000' }} className='mb-1 font-weight-bold'>
                        {customer?.location || '-'}
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='chat-infos'>
                      <h6 className=''>Country</h6>
                      <p style={{ color: '#000' }} className='mb-1 font-weight-bold'>
                        {customer?.country || '-'}
                      </p>
                    </div>
                  </div>
                </div>

                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <div className='chat-infos'>
                      <h6 className=''>Account No.</h6>
                      <p style={{ color: '#000' }} className='mb-1 font-weight-bold'>
                        {/* {customer?.credentials?.account || '-'} */}
                        {mask(customer?.credentials?.account, '*', 4, true) || '-'}
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='chat-infos'>
                      <h6 className=''>Phone Number</h6>
                      <p style={{ color: '#000' }} className='mb-1 font-weight-bold'>
                        {customer?.credentials?.mobile || '-'}
                      </p>
                    </div>
                  </div>
                </div>

                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <div className='chat-infos'>
                      <h6 className=''>IP Address</h6>
                      <p style={{ color: '#000' }} className='mb-1 font-weight-bold'>
                        {customer?.ip_address
                          ? convertIPv6AddresstoIPv4(customer?.ip_address?.toString())
                          : '-'}
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='chat-infos'>
                      <h6 className=''>BVN</h6>
                      <p style={{ color: '#000' }} className='mb-1 font-weight-bold'>
                        {mask(customer?.credentials?.bvn, '*', 4, true) || '-'}
                      </p>
                    </div>
                  </div>
                </div>

                <div className='row mt-3'>
                  <div className='col-md-12'>
                    <div className='chat-infos'>
                      <h6 className=''>Source URL</h6>
                      <p style={{ color: '#000' }} className='mb-1 font-weight-bold'>
                        {customer?.source_url || '-'}
                      </p>
                    </div>
                  </div>
                </div>

                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <div className='chat-infos'>
                      <h6 className=''>Agent Served</h6>
                      <p style={{ color: '#000' }} className='mb-1 font-weight-bold'>
                        {Object.keys(agent).length === 0 && agent.constructor === Object
                          ? 'Unattended'
                          : `${agent?.first_name} ${agent?.last_name}` || '-'}
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='chat-infos'>
                      <h6 className=''>Date</h6>
                      <p style={{ color: '#000' }} className='mb-1 font-weight-bold'>
                        {moment(groupedResponse[0]?.date).format('MMMM Do YYYY') || '-'}
                      </p>
                    </div>
                  </div>
                </div>

                <button className='btn btn-block btn-danger mt-3 p-2' onClick={toggle}>
                  Reply via Email
                </button>
              </>
            )}
          </div>
        </div>

        {modal && (
          <Modal
            style={{ maxWidth: '600px', width: '100%' }}
            size='lg'
            isOpen={modal}
            toggle={toggle}
            id='send-email'>
            <ModalHeader toggle={toggle}></ModalHeader>
            <h4 className='text-center p-0'>Send Via Email</h4>
            <form onSubmit={handleEmailSend}>
              <ModalBody className='p-0'>
                <div>
                  <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content m-0 p-0'>
                      <form className='m-0 p-0' onSubmit={handleEmailSend}>
                        <div className='modal-body px-5'>
                          <div className='form-group'>
                            <label for='exampleFormControlInput1' style={{ color: '#43425D' }}>
                              Name
                            </label>
                            <input
                              type='text'
                              name='name'
                              value={emailState?.name}
                              onChange={handleInputChange}
                              className='form-control'
                              style={{ borderRadius: '8px' }}
                              id='exampleFormControlInput1'
                            />
                          </div>
                          <div className='form-group my-3'>
                            <label for='exampleFormControlInput2' style={{ color: '#43425D' }}>
                              Subject
                            </label>
                            <input
                              type='text'
                              name='subject'
                              value={emailState?.subject}
                              onChange={handleInputChange}
                              className='form-control'
                              style={{ borderRadius: '8px' }}
                              id='exampleFormControlInput1'
                            />
                          </div>
                          <div className='form-group message-container w-100'>
                            <label
                              for='exampleFormControlTextarea1'
                              style={{ color: '#43425D', width: '100%' }}>
                              Message
                              <textarea
                                className='form-control'
                                name='details'
                                value={emailState?.details}
                                onChange={handleInputChange}
                                style={{ borderRadius: '8px' }}
                                id='exampleFormControlTextarea1'
                                rows='3'
                              />
                            </label>
                            <div className='attach'>
                              <input type='file' onChange={handleFileUpload} className='' />
                              <img
                                src={attachmentIcon}
                                className='widget-file-upload-button d-flex justify-content-between align-items-center'
                                alt='attach file'
                                width='20px'
                              />
                              <div className='file-container'>
                                <p>{file}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter style={{ alignSelf: 'flexStart', marginBottom: 20 }}>
                <button
                  type='submit'
                  style={{
                    width: '200px',
                    backgroundColor: '#43425D',
                    borderRadius: '8px'
                  }}
                  className='btn text-white'>
                  {emailState.isLoading ? <Spinner color='light' size='sm' /> : ''} Send
                </button>
              </ModalFooter>
            </form>
          </Modal>
        )}
        {summaryModal && (
          <>
            <Modal
              style={{ maxWidth: '600px', width: '100%', borderRadius: '11px' }}
              size='lg'
              isOpen={summaryModal}
              toggle={summaryModalToggle}
              id='summary'>
              <ModalHeader toggle={summaryModalToggle}></ModalHeader>
              <h4 className='text-center p-0'>Chat Summary</h4>
              <hr />
              <ModalBody className='p-4'>
                <div className='col-12'>
                  <div className='row summary-text-wrapper pb-4'>
                    <div className='summary-text-title col-md-6 font-weight-bold'>Summary:</div>
                    <div className='summary-text col-md-6'>{summaryData?.summary || 'None'}</div>
                  </div>
                  <div className='row case-text-wrapper'>
                    <div className='font-weight-bold col-md-6 case-text-title'>
                      Case resolved status:
                    </div>
                    <div className=' col-md-6 summary-text' style={{ textTransform: 'capitalize' }}>
                      {summaryData?.case_resolved || 'None'}
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default ChatRecords;
