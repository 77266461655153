import React from "react";

function AgentNew() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            stroke='currentColor'
            width='22'
            height='22'
            viewBox='0 0 24 24'
        >
            <path
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    d='M16 7a4 4 0 11-8 0 4 4 0 018 0zm-4 7a7 7 0 00-7 7h14a7 7 0 00-7-7z'
    />
        </svg>
    );
}

export default AgentNew;
