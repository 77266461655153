import axios from 'axios';
import Qs from 'qs';
import { emitter } from '../utils/react-listeners';
import 'moment-timezone';
import moment from 'moment';

const { REACT_APP_API_URL } = process.env;

const timezone = moment.tz.guess();
moment.tz.setDefault(timezone);

export const baseUrl = REACT_APP_API_URL;
const api = axios.create({ baseURL: baseUrl });

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    config.headers['Authorization'] = token ? `Bearer ${token}` : undefined;
    config.headers['Accept'] = 'application/json';
    config.headers.common['x-timezone'] = timezone;
    // config.headers["X-Requested-With"] = "XMLHttpRequest";

    config.paramsSerializer = (params) =>
      Qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false
      });
    return config;
  },
  (err) => {
    console.log('interceptors request err: ', err);
  }
);

api.interceptors.response.use(
  (response) => response,
  (err) => {
    console.log('interceptors response err: ', err.response);
    if (err?.response?.status === 401 && window.location.pathname !== '/auth/login') {
      emitter.emit('log-out');
    }
    return Promise.reject(err);
  }
);

export default api;
