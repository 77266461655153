import React, { memo, useCallback, useMemo, useRef } from 'react';
import useState from 'use-react-state';
import { Notifications } from '@material-ui/icons';
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
  Spinner
} from 'reactstrap';
import moment from 'moment';
import { Share, X } from '../../assets/icons/jsxIcons';
import Inifinite from '../common/Infinite';
import { Pressable, Text } from '../theme';
import { createBroadcast, getBroadcasts, readBroadcast } from '../../utils/api/broadcast';
import Select from 'react-select';
import { useRequest, useSocketListeners } from '../../utils/hooks';
import { getTeams } from '../../utils/api/team';
import { capitalize } from 'lodash';
import { Switch } from '@material-ui/core';
import styles from './styles.module.sass';
import theme from '../../constants/theme';
import { useUser } from '../../utils/hooks/user';
import { toast } from 'react-toastify';

const Broadcasts = memo(function () {
  const infiniteMessagesRef = useRef();
  const [{ dropdownOpen, hasNew }, setState] = useState({
    dropdownOpen: false,
    hasNew: false
  });

  const toggle = () =>
    setState((s) => {
      s.dropdownOpen = !s.dropdownOpen;
      s.hasNew = false;
    });

  const { teamId, agentId } = useUser(({ team, id } = {}) => ({
    agentId: id,
    teamId: team?.id || team
  }));

  const onBroadcast = (broadcast) => {
    if (broadcast?.agent?.id !== agentId) {
      setState({ hasNew: true });
      toast(
        <>
          <h4 className='font-weight-bolder'>
            {broadcast.agent?.first_name} {broadcast.agent?.last_name}
          </h4>
          {broadcast.message}
        </>,
        {
          position: 'top-center',
          autoClose: broadcast.pinned ? false : 10000
        }
      );
    }
  };

  useSocketListeners({
    listeners: {
      [`Broadcast.created.${teamId}`]: onBroadcast,
      'Broadcast.created': onBroadcast
    }
  });

  const onClose = useCallback(
    async (id, index) => {
      try {
        await readBroadcast(id);
        infiniteMessagesRef.current.setState((s) => {
          s.data.data.splice(index, 1);
        });
      } catch (error) {
        console.log(error);
      }
    },
    [infiniteMessagesRef]
  );

  const queryParams = useMemo(() => [{ limit: 5 }], []);

  const RenderItem = useCallback(
    ({ item: { agent, message, createdAt, id }, index }) => (
      <>
        <div className='px-4' style={{ width: 224 }}>
          <Col className='p-3'>
            <Row className='justify-content-between align-center'>
              <div>
                <Text className={styles.agentName}>
                  {agent.first_name} {agent.last_name}
                </Text>
                <Text className={styles.time}>
                  {moment(createdAt).format('DD/MM/YYYY hh:mm A')}
                </Text>
              </div>
              <X onClick={() => onClose(id, index)} width={10} height={10} role='button' />
            </Row>
            <Text className={styles.message + ' mt-2 text-wrap'}>{message}</Text>
          </Col>
        </div>
        <div
          className='border-primary'
          style={{
            marginLeft: '10%',
            border: '0.5px solid #000',
            width: '80%'
          }}
        />
      </>
    ),
    [onClose]
  );

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction='start' size='300px'>
      <DropdownToggle color='primary' className='bg-white border-0'>
        {hasNew && <div className={styles.redDot} />}
        <Notifications
          htmlColor='#000'
          onClick={toggle}
          role='button'
          fontSize='large'
          style={{ width: '17px' }}
        />
      </DropdownToggle>
      <DropdownMenu style={{ width: 224 }} container={'body'} className='pt-0'>
        <DropdownItem header className={styles.broadcastHead}>
          <Text className={styles.broadcastHeadText}>Notification</Text>
        </DropdownItem>
        <Row className='align-items-center justify-content-center py-2'>
          <Button className='px-4 my-1' color='primary' onClick={undefined}>
            Message Inbox
          </Button>
        </Row>
        <Inifinite
          ref={infiniteMessagesRef}
          params={queryParams}
          RenderItem={RenderItem}
          name='notifications'
          style={{ height: 350 }}
          asyncRequest={getBroadcasts}
          className='scroller'
          disableEndReached={false}
          disableBeginReached={true}
          loadOnMount={dropdownOpen}
        />
      </DropdownMenu>
    </Dropdown>
  );
});

export const CreateBroadcast = memo(function () {
  const [{ dropdownOpen, pinned, message, teams, isLoading }, setState] = useState({
    dropdownOpen: false,
    pinned: false,
    isLoading: false,
    message: '',
    teams: undefined
  });

  const toggle = () =>
    setState((s) => {
      s.dropdownOpen = !s.dropdownOpen;
    });

  const {
    state: { data: { data = [] } = {} }
  } = useRequest({ asyncRequest: getTeams, name: 'teams', loadOnMount: dropdownOpen }, [
    dropdownOpen
  ]);

  const options = useMemo(
    () => data.map(({ name, id }) => ({ value: id, label: capitalize(name) })),
    [data]
  );

  const handleChange = useCallback(
    (values) =>
      setState({
        teams: (values?.map && values.map(({ value }) => value)) || [values.value]
      }),
    []
  );

  const onPin = useCallback(({ target: { checked: pinned } }) => setState({ pinned }));

  const onChangeInput = useCallback(({ target: { value: message } }) => setState({ message }));

  const onCreateBroadcast = async () => {
    try {
      setState({ isLoading: true });
      await createBroadcast({ message, pinned, teams });
      setState({
        dropdownOpen: false,
        isLoading: false,
        pinned: false,
        message: '',
        teams: undefined
      });
    } catch (error) {
      console.log(error);
      toggle();
    }
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction='left' size='30'>
      <DropdownToggle color='primary' className='bg-white border-0' style={{ padding: '9px' }}>
        <Share fill='#000' role={'button'} width={17} height={17} padding={20} className='' />
      </DropdownToggle>
      <DropdownMenu className='pt-0'>
        <DropdownItem header className={styles.broadcastHead}>
          <Text className={styles.broadcastHeadText}>Broadcast Message</Text>
        </DropdownItem>
        <div className='p-3'>
          <Label className={styles.broadcastLabel}>Share a message with your agent</Label>
          <Input
            className='border-primary'
            name='message'
            style={{ height: 200, width: 200 }}
            type='textarea'
            onChange={onChangeInput}
          />
          <Label className='py-1 fs'>Team</Label>
          <Select
            styles={{
              control: (styles) => ({
                ...styles,
                borderColor: theme.colors.primary
              })
            }}
            dropdownIndicator={null}
            className='border-primary'
            onChange={handleChange}
            options={options}
            isMulti
          />
          <Col>
            <Row className='align-items-center'>
              <Switch checked={pinned} onChange={onPin} color={'secondary'} label='Pin' />
              <Label>Pin</Label>
            </Row>
          </Col>
          <Row className='align-items-center justify-content-around'>
            <Pressable onClick={toggle}>Cancel</Pressable>
            <Button color='primary' onClick={onCreateBroadcast}>
              {isLoading ? <Spinner size='sm' /> : 'Broadcast'}
            </Button>
          </Row>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
});

export default Broadcasts;
