import './style.css';
import { useQuery } from 'react-query';
import MuiTableAllAgentsReport from '../CustomTable/MuiTableAllAgentsReport';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMap from 'highcharts/modules/map';
import map from '@highcharts/map-collection/custom/world.geo.json';
import api from '../../api';
import MuiTableReport from '../CustomTable/MuiTableReport';
import Avatar from 'react-avatar';
import { ProgressBar } from 'react-bootstrap';
import moment from 'moment';
import proj4 from 'proj4';
import StarRatings from 'react-star-ratings';
import SummaryCard from './SummaryCard';

import RedPhone from '../../assets/icons/red.png';
import Transfer from '../../assets/icons/refresh.png';
import Resolved from '../../assets/icons/green-phone.svg';
import Countries from '../../assets/icons/world-countries.svg';
import Cities from '../../assets/icons/world-cities.svg';

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import QueryWrapper from './QueryWrapper';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title
);

HighchartsMap(Highcharts);

const Reports = () => {
  const today = new Date();
  const lastMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1;
  const year = lastMonth === 0 ? today.getFullYear - 1 : today.getFullYear();
  const lastDateTimeOfLastMonth = new Date(year, lastMonth + 1, 1);
  const firstDateTimeOfLastMonth = new Date(year, lastMonth + 1, -28);

  const chartConfigOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      },
      tootip: {
        enabled: true
      }
    }
  };

  const { data: { data: currentMonthTransfers } = {} } = useQuery('currentMonthTransfers', () =>
    api.get('/call_transfers/count')
  );

  // const { data: { data: dailyCalls } = {} } = useQuery('dailyCalls', () =>
  //   api.get('/live-videos/count')
  // );

  // const { data: { data: dailyCallRequests } = {} } = useQuery('dailyCallRequests', () =>
  //   api.get('/calendars/count')
  // );

  const { data: { data: previousMonthTransfers } = {} } = useQuery('previousMonthTransfers', () =>
    api.get(
      `/call_transfers/count?startDate=${firstDateTimeOfLastMonth}&endDate=${lastDateTimeOfLastMonth}`
    )
  );

  const { data: { data: currentMonthResolvedConversations } = {} } = useQuery(
    'currentMonthResolvedConversations',
    () => api.get('/summaries/count?status=resolved')
  );

  const { data: { data: previousMonthResolvedConversations } = {} } = useQuery(
    'previousMonthResolvedConversations',
    () =>
      api.get(
        `/summaries/count?status=resolved?startDate=${firstDateTimeOfLastMonth}&endDate=${lastDateTimeOfLastMonth}`
      )
  );

  const { data: { data: currentMonthUnResolvedConversations } = {} } = useQuery(
    'currentMonthUnResolvedConversations',
    () => api.get('/summaries/count?status=unresolved')
  );

  const { data: { data: previousMonthUnResolvedConversations } = {} } = useQuery(
    'previousMonthUnResolvedConversations',
    () =>
      api.get(
        `/summaries/count?status=unresolved?startDate=${firstDateTimeOfLastMonth}&endDate=${lastDateTimeOfLastMonth}`
      )
  );

  const { data: { data: locationinfo } = {} } = useQuery('locations', () =>
    api.get('/customers/location-info')
  );

  const { data: { data: locationResponse } = {} } = useQuery('locationsCount', () =>
    api.get('/customers/location-count')
  );

  const { data: { data: mappings } = {} } = useQuery('map-tracking', () =>
    api.get('/customers/map-tracking')
  );

  const { data: { data: agentsReport } = {} } = useQuery('agentsReport', () =>
    api.get('/agents/engagements')
  );

  const chartOptions = {
    chart: {
      map: 'countries/gb/gb-all',
      proj4
    },
    title: null,
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom'
      }
    },

    series: [
      {
        name: 'Basemap',
        borderColor: '#A0A0A0',
        nullColor: 'rgba(200, 200, 200, 0.3)',
        showInLegend: false,
        mapData: map
      },
      {
        name: 'Separators',
        type: 'mapline',
        nullColor: '#707070',
        showInLegend: false,
        enableMouseTracking: false
      },
      {
        // Specify points using lat/lon
        type: 'mappoint',
        name: 'Locations',
        color: '#6F8793',
        data: mappings?.maplocation,
        minSize: 6,
        maxSize: '6%',
        shadow: {
          width: '200px'
        },
        tooltip: {
          pointFormat:
            'Country: {point.name}: <br> State: {point.city} <br> Longitude: {point.lon} <br> Latitude: {point.lat}'
        }
      }
    ]
  };

  const locationColumn = [
    {
      title: 'Country',
      field: 'country',
      render: (rowData) => {
        return (
          <div className=''>
            <p className='m-0'>{rowData?.country}</p>
          </div>
        );
      },
      cellStyle: {
        textAlign: 'left',
        width: '40%',
        maxWidth: '50%',
        padding: 15,
        paddingTop: 12,
        paddingBottom: 12
      },
      headerStyle: {
        padding: 15,
        paddingTop: 10,
        paddingBottom: 5,
        textAlign: 'left',
        width: '40%',
        maxWidth: '40%'
      }
    },
    {
      title: 'State',
      field: 'location',
      render: (rowData) => {
        return (
          <div className=''>
            <p className='m-0'>{rowData?.location}</p>
          </div>
        );
      },
      cellStyle: {
        textAlign: 'center',
        width: '35%',
        maxWidth: '35%',
        padding: 0,
        paddingTop: 12,
        paddingBottom: 12
      },
      headerStyle: {
        padding: 0,
        textAlign: 'center',
        width: '35%',
        maxWidth: '35%',
        paddingTop: 10,
        paddingBottom: 5
      }
    },
    {
      title: 'Total',
      field: 'count',
      cellStyle: {
        textAlign: 'right',
        width: '25%',
        maxWidth: '25%',
        padding: 15,
        paddingTop: 12,
        paddingBottom: 12
      },
      headerStyle: {
        padding: 15,
        textAlign: 'right',
        width: '25%',
        maxWidth: '25%',
        paddingTop: 10,
        paddingBottom: 5
      }
    }
  ];

  // const labels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  // const lineChartData = {
  //   labels,
  //   datasets: [
  //     {
  //       label: 'Daily Calls',
  //       data: dailyCalls?.data || [],
  //       fill: false,
  //       borderColor: '#845EF7'
  //     },
  //     {
  //       label: 'Daily Calls Requests',
  //       data: dailyCallRequests?.data || [],
  //       fill: false,
  //       borderColor: '#FF922B'
  //     }
  //   ]
  // };

  const doughnutData = {
    labels: ['Resolved', 'Unresolved', 'Transfers'],
    datasets: [
      {
        label: 'Call Queries',
        data: [
          currentMonthResolvedConversations?.count,
          currentMonthTransfers?.count,
          currentMonthUnResolvedConversations?.count
        ],
        backgroundColor: ['#339AF0', '#FF922B', '#51CF66'],
        hoverOffset: 6
      }
    ]
  };

  const AllAgentsColumn = [
    {
      title: 'Agents',
      icon: 'Avatar',
      render: (rowData) => {
        return (
          <div className='d-flex align-items-center'>
            <div className='mr-3'>
              <Avatar size='30' src={rowData?.profile_picture} round={true} />
            </div>
            <div>
              <p className='m-0'>{`${rowData?.first_name} ${rowData?.last_name}`}</p>
            </div>
          </div>
        );
      },
      cellStyle: {
        textAlign: 'left',
        width: '15%',
        maxWidth: '15%',
        padding: 0,
        paddingLeft: 20,
        paddingTop: 12,
        paddingBottom: 12
      },
      headerStyle: {
        padding: 20,
        paddingLeft: 25,
        paddingTop: 10,
        paddingBottom: 5,
        textAlign: 'left',
        width: '15%',
        maxWidth: '15%'
      }
    },
    {
      title: 'Availabilty',
      render: (rowData) => {
        return (
          <div className='availability'>
            <ProgressBar now={rowData?.chat_ratings} className={`team-progress fourther`} />
          </div>
        );
      },
      cellStyle: {
        textAlign: 'center',
        width: '20%',
        maxWidth: '20%',
        padding: 0,
        paddingTop: 12,
        paddingBottom: 12
      },
      headerStyle: {
        padding: 0,
        textAlign: 'center',
        width: '20%',
        maxWidth: '20%',
        paddingTop: 10,
        paddingBottom: 5
      }
    },
    {
      title: 'Total Time(hrs)',
      render: (rowData) => {
        return (
          <div className=''>
            <p className='m-0'>
              {rowData?.total_call_duration === null
                ? '00:00'
                : moment(rowData?.total_call_duration * 1000).format('hh:mm')}
            </p>
          </div>
        );
      },
      cellStyle: {
        textAlign: 'center',
        width: '20%',
        maxWidth: '20%',
        padding: 0,
        paddingTop: 12,
        paddingBottom: 12
      },
      headerStyle: {
        padding: 0,
        textAlign: 'center',
        width: '20%',
        maxWidth: '20%',
        paddingTop: 10,
        paddingBottom: 5
      }
    },
    {
      title: 'Total Calls',
      render: (rowData) => {
        return <p className='m-0'>{rowData?.total_calls || 0}</p>;
      },
      cellStyle: {
        textAlign: 'center',
        width: '15%',
        maxWidth: '15%',
        padding: 0,
        paddingTop: 12,
        paddingBottom: 12
      },
      headerStyle: {
        padding: 15,
        textAlign: 'center',
        width: '15%',
        maxWidth: '15%',
        paddingTop: 10,
        paddingBottom: 5
      }
    },
    {
      title: 'Total Chats',
      // field: 'connected_clients',
      render: (rowData) => {
        return (
          <div className=''>
            <p className='m-0'>{rowData?.connected_clients}</p>
          </div>
        );
      },
      cellStyle: {
        textAlign: 'center',
        width: '15%',
        maxWidth: '15%',
        padding: 15,
        paddingTop: 12,
        paddingBottom: 12
      },
      headerStyle: {
        padding: 15,
        textAlign: 'center',
        width: '15%',
        maxWidth: '15%',
        paddingTop: 10,
        paddingBottom: 5
      }
    },
    {
      title: 'Average Chat Rating',
      // field: 'profile.picture',
      render: (rowData) => {
        return (
          <div className=''>
            <StarRatings
              rating={(rowData?.avg_chat_ratings / 100) * 5 || 0}
              starRatedColor='#FBA502'
              numberOfStars={5}
              name='rating'
              starDimension='25px'
              starSpacing='3'
              starHoverColor='#083047'></StarRatings>
          </div>
        );
      },
      cellStyle: {
        textAlign: 'left',
        width: '15%',
        maxWidth: '15%',
        padding: 15,
        paddingRight: 5,
        paddingLeft: 22,
        paddingTop: 12,
        paddingBottom: 12
      },
      headerStyle: {
        padding: 20,
        textAlign: 'left',
        width: '15%',
        maxWidth: '15%',
        paddingTop: 10,
        paddingBottom: 5
      }
    }
  ];

  return (
    <div className='report mt-2'>
      <div className='card-title pl-3 h4 mb-3 font-weight-bold text-nowrap'>REPORTS</div>
      <div className='container-fluid'>
        <div className='row'>
          <SummaryCard
            title='Resolved'
            Logo={Resolved}
            currentMonthData={currentMonthResolvedConversations?.count}
            previousMonthData={previousMonthResolvedConversations?.count}
          />
          <SummaryCard
            title='Transfers'
            Logo={Transfer}
            currentMonthData={currentMonthTransfers?.count}
            previousMonthData={previousMonthTransfers?.count}
          />
          <SummaryCard
            title='Unresolved'
            Logo={RedPhone}
            currentMonthData={currentMonthUnResolvedConversations?.count}
            previousMonthData={previousMonthUnResolvedConversations?.count || 0}
          />
          <SummaryCard
            title='Total Countries'
            Logo={Countries}
            currentMonthData={locationResponse?.locationsCount[0]?.total_countries}
            previousMonthData={locationResponse?.locationsCount[0]?.prev_month_total_countries}
          />
          <SummaryCard
            title='Total Cities'
            Logo={Cities}
            currentMonthData={locationResponse?.locationsCount[0]?.total_locations}
            previousMonthData={locationResponse?.locationsCount[0]?.prev_month_total_locations}
          />
        </div>
      </div>

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-6 mt-4 p-0 report-mid map'>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
              constructorType={'mapChart'}
            />
          </div>
          <div className='col-3 mt-4 p-0 report-mid'>
            <MuiTableReport
              columns={locationColumn}
              data={locationinfo?.locations}
              title={'Locations'}
            />
          </div>
          <QueryWrapper
            classNames='col-3 mt-4 p-0 report-mid last-col'
            title='History'
            subHeading='Call Queries'>
            <Doughnut className='doughnut-chart' data={doughnutData} options={chartConfigOptions} />
          </QueryWrapper>
        </div>
      </div>

      {/* <div className='container-fluid'>
        <QueryWrapper
          title='Calls Comparison'
          className='col-12 calls-chart'
          subHeading='Daily Calls vs Daily Call Requests'>
          <Line className='line-chart' options={chartConfigOptions} data={lineChartData} />
        </QueryWrapper>
      </div> */}

      <div className='container-fluid'>
        <div className='col-12 mt-4 p-0 report-bottom'>
          <MuiTableAllAgentsReport columns={AllAgentsColumn} data={agentsReport} title={''} />
        </div>
      </div>
    </div>
  );
};

export default Reports;
