import { capitalize } from "lodash";
import React, { memo, useCallback } from "react";
import { Button, CardHeader, CardText, Col, Spinner } from "reactstrap";
import { agents } from "../../utils/api/user";
import { useRequest } from "../../utils/hooks";
import useState from "use-react-state";
import { transferWait } from "../../utils/api/wait";
import { useEmitter } from "../../utils/react-listeners";

const Agents = memo(({ setSession, waitId, hideTransfer }) => {
  const [{ loading }, setState] = useState({ loading: false });
  const {
    state: { data: { data = [] } = {}, isLoading },
  } = useRequest(
    {
      asyncRequest: agents,
      params: [{ limit: 20, query: { is_online: 1 }, excludeUser: true }],
    },
    []
  );

  const emitter = useEmitter();

  const transfer = useCallback(
    async (user_id) => {
      try {
        setState({ loading: true });
        await transferWait(waitId, { user_id });
        setState({ loading: false });
        emitter.emit("waits.connected.refresh");
        setSession(() => ({}));
      } catch (error) {
        setState({ loading: false });
        console.log({ error });
      }
    },
    [waitId]
  );

  return (
    <div className='bg-white mb-2 d-flex-column'>
      <CardHeader>
        <h5>Online Agents</h5>
        <div className='agents-head  font-weight-bold'>
          <span>Name</span>
          <span>Team</span>
          {!hideTransfer && <span>Action</span>}
        </div>
      </CardHeader>
      <CardText className='scroller p-2'>
        {isLoading && (
          <Col className='d-flex align-items-center justify-content-center'>
            <Spinner />
          </Col>
        )}
        {!isLoading && !data.length && <Col>No agent online</Col>}
        {data.map(({ id, first_name, last_name, team, is_online }) => (
          <div className={`agents-row hover-overlay`}>
            <h6 className='d-flex align-items-center'>
              {first_name} {last_name}
              <div class='ml-1 bg-success p-2 rounded-circle' />
            </h6>
            <p>{capitalize(team?.name)}</p>
            {!hideTransfer && (
              <Button
                disabled={loading || !waitId}
                onClick={() => transfer(id)}
              >
                {loading ? <Spinner /> : "Transfer"}
              </Button>
            )}
          </div>
        ))}
      </CardText>
    </div>
  );
});

export default Agents;
