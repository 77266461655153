import React, { useEffect, useState } from "react";
import "./style.css";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import api from "../../api";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useQuery } from "react-query";
import MuiTable from "../CustomTable/MuiTable";
const initialState = {
  messageCode: "",
  details: "",
  id: "",
};
const Triggers = () => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [addTrigger, setAddTrigger] = useState(initialState);
  const [, setGetAllTrigger] = useState([]);
  const [, setTriggerID] = useState("");
  const [, setLoading] = useState(false);
  const [search] = React.useState("");
  const [, setFilterData] = useState(null);
  useEffect(() => {
    getAllTriggers().then((r) => r);
  }, []);

  const toggle = () => {
    console.log("clicked");
    setModal(!modal);
  };
  const toggleEdit = async (item) => {
    if (item._id) {
      await getSingleTrigger(item._id);
      setEditModal(true);
      setTriggerID(item._id);
    } else {
      setEditModal(false);
    }
  };
  const getSingleTrigger = async (id) => {
    if (id) {
      let response = await api.get(`trigger/edit/${id}`);
      console.log(response);
      if (response.status === 200) {
        let { trigger } = response.data;
        console.log({ trigger });
        setAddTrigger({
          messageCode: trigger.shortMessage,
          details: trigger.longMessage,
          id: trigger._id,
        });
      }
    }
  };
  const allTriggers = useQuery("allTriggers", () => api.get("trigger/list"), {
    refetchOnWindowFocus: false,
  });
  console.log({ allTriggers });
  const getAllTriggers = async () => {
    setLoading(true);
    let response = await api.get("trigger/list");
    setGetAllTrigger(response.data.data);
    setLoading(false);
  };
  const validateAddTrigger = () => {
    let messageValidation = {
      message: "Message is required",
      status: false,
    };
    let detailsValidation = {
      message: "Message details is required",
      status: false,
    };
    let passed = {
      status: true,
    };
    return addTrigger.messageCode !== ""
      ? addTrigger.details === ""
        ? detailsValidation
        : passed
      : messageValidation;
  };
  const addTriggerInputsChange = ({ target: { value, name } }) => {
    setAddTrigger((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const updateTrigger = async (e) => {
    e.preventDefault();
    let validate = validateAddTrigger();
    if (validate.status) {
      const data = {
        shortMessage: addTrigger.messageCode,
        longMessage: addTrigger.details,
        trigger_id: addTrigger.id,
      };

      let response = await api.post("/trigger/single/update", data);

      if (response.status === 200) {
        setAddTrigger(initialState);
        await getAllTriggers();
        setEditModal(false);
        toast.success("Trigger updated successfully", { autoClose: 2000 });
      }
    } else {
      toast.error(`'Sorry trigger couldn't be updated'`, { autoClose: 2000 });
    }
  };
  const saveTrigger = async (e) => {
    e.preventDefault();
    let validate = validateAddTrigger();
    if (validate.status) {
      const data = {
        shortMessage: addTrigger.messageCode,
        longMessage: addTrigger.details,
      };

      let response = await api.post("/trigger/save", data);

      if (response.status === 200) {
        setAddTrigger(initialState);
        getAllTriggers();
        setModal(false);
        toast.success("Trigger added successfully", { autoClose: 2000 });
      }
    } else {
      toast.error(`'Sorry trigger couldn't be created'`, { autoClose: 2000 });
    }
  };
  const removeTrigger = async (id) => {
    confirmAlert({
      title: "Confirmation!",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmChangeStatus(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const confirmChangeStatus = async (id) => {
    let response = await api.delete(`trigger/delete/${id}`);
    if (response.status === 200) {
      await getAllTriggers();
      return toast.success("Trigger deleted successfully", { autoClose: 2000 });
    }
  };

  useEffect(() => {
    const Data = allTriggers.data?.data?.data;
    if (Data) {
      const filterData = Data.filter(
        (data) => data.shortMessage.toLowerCase().includes(search) && data
      );
      setFilterData([...filterData]);
    }
    return () => {
      setFilterData("");
    };
  }, [search]);

  const columns = [
    {
      title: "#",
      field: "",
      align: "center",
      width: "10%",
      render: (rowData, index) => rowData.tableData.id + 1,
      //         cellStyle: {
      //     width: 10,
      //         maxWidth: 10,
      //
      // },
      // headerStyle: {
      //     width:10,
      //         maxWidth: 10,
      //
      // },
    },
    { title: "Code Message", field: "shortMessage", width: "40%" },
    { title: "Full Message", field: "longMessage", width: "40%" },
    {
      title: "Actions",
      field: "",
      headerSort: false,
      width: "10%",
      render: (rowData) => {
        return (
          <>
            {/*<div className='flex justify-content-between align-items-center w-100'>*/}
            <button
              data-toggle='modal'
              onClick={() => toggleEdit(rowData)}
              data-target='#edit-modal'
              className='btn btn-primary btn-sm'
            >
              <i className='fas fa-edit text-white' />
            </button>
            {"   "}
            <button
              onClick={() => removeTrigger(rowData._id)}
              className='btn btn-danger btn-sm'
            >
              <i className='fas fa-trash-alt text-white' />
            </button>
            {/*</div>*/}
          </>
        );
      },
      cellStyle: {
        width: "10",
        // maxWidth: 20,
      },
      headerStyle: {
        width: "10",
        // maxWidth: 20,
        // whiteSpace: 'nowrap'
      },
    },
    // {title: 'Delete',field:'',render:(rowData)=>{
    //         return (
    //             <button onClick={()=>removeTrigger(rowData._id)} className="btn btn-danger btn-sm">
    //                 <i className="fas fa-trash-alt text-white"/>
    //             </button>
    //         )
    //     },cellStyle: {
    //         width: 10,
    //         maxWidth: 10,
    //
    //     },
    //     headerStyle: {
    //         width:10,
    //         maxWidth: 10,
    //         textAlign: "center"
    //     },
    //     },
  ];
  return (
    <>
      <div className='card mt-4 p-4 overflow-auto table-responsive-sm'>
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <div className='card-title h4 pl-3 mb-0 font-weight-bold text-nowrap'>
            Triggers
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            {/*<nav className="navbar navbar-light" style={{ width: "290px", paddingRight: "1px" }}>*/}
            {/*    <form className="form-inline position-relative w-100">*/}

            {/*        <input className="form-control mr-sm-2 w-100" type="search" value={search} onChange={searchData} placeholder="Search by code"*/}
            {/*            aria-label="Search" />*/}
            {/*        <i className="fa fa-search position-absolute" style={{right: "25px"}}/>*/}
            {/*    </form>*/}
            {/*</nav>*/}
            <button
              type='button'
              onClick={toggle}
              className='btn add-btn ml-2 text-nowrap'
            >
              Add New
            </button>
          </div>
        </div>
        {allTriggers.isLoading ? (
          <SkeletonTheme color='#eee'>
            <Skeleton width={700} height={10} count={5} className='ml-2' />
          </SkeletonTheme>
        ) : (
          // <Table
          //     title="Chat history with visitors"
          //     styles='Edit'
          //     classes='Delete'
          //     edit='Edit'
          //     headerData={tableHeader}
          //     tableData={allTriggers.data?.data?.data}
          //     rowComponent={TableRow}
          //     pagination
          //     search
          //     searchKey={'shortMessage'}
          // />
          // <div style={{ width:'700px'}}>
          <div className='mt-3 text-center'>
            <MuiTable
              title={"All Records"}
              data={allTriggers.data?.data?.data}
              columns={columns}
              // actions={[
              //         {
              //             icon: "add_box",
              //             toolTip:'Add trigger',
              //             isFreeAction: true,
              //             onClick:{toggle},
              //         }
              //         ]}
            />
          </div>
        )}
      </div>
      {/*  Modal  */}
      {modal && (
        <Modal
          style={{ maxWidth: "500px", width: "100%" }}
          isOpen={modal}
          toggle={toggle}
        >
          <ModalHeader toggle={toggle}>Add Trigger</ModalHeader>
          <ModalBody className='p-4'>
            <form id='add-trigger' onSubmit={saveTrigger}>
              <div className='form-group'>
                <label
                  htmlFor='exampleFormControlInput1'
                  style={{ color: "#43425D" }}
                >
                  Code Message
                  <span className='text-danger'>*</span>
                </label>
                <input
                  name='messageCode'
                  value={addTrigger.messageCode}
                  type='text'
                  className='form-control'
                  style={{ borderRadius: "8px" }}
                  onChange={addTriggerInputsChange}
                  placeholder='Code Message'
                />
              </div>
              <div className='form-group'>
                <label
                  htmlFor='exampleFormControlTextarea1'
                  style={{ color: "#43425D" }}
                >
                  Details
                  <span className='text-danger'>*</span>
                </label>
                <textarea
                  name='details'
                  value={addTrigger.details}
                  className='form-control'
                  style={{ borderRadius: "8px" }}
                  onChange={addTriggerInputsChange}
                  placeholder='Details'
                />
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type='submit'
              form='add-trigger'
              style={{
                width: "200px",
                backgroundColor: "#43425D",
                borderRadius: "8px",
              }}
              className='btn text-white'
            >
              Add Trigger
            </button>
          </ModalFooter>
        </Modal>
      )}
      {/*  Edit  */}
      {editModal && (
        <Modal isOpen={editModal} toggle={toggleEdit}>
          <ModalHeader toggle={toggleEdit}>Edit Trigger</ModalHeader>
          <ModalBody className='p-4'>
            <form id='edit-trigger' onSubmit={updateTrigger}>
              <div className='form-group'>
                <label
                  htmlFor='exampleFormControlInput1'
                  style={{ color: "#43425D" }}
                >
                  Code Message
                  <span className='text-danger'>*</span>
                </label>
                <input
                  name='messageCode'
                  value={addTrigger.messageCode}
                  type='text'
                  className='form-control'
                  style={{ borderRadius: "8px" }}
                  onChange={addTriggerInputsChange}
                  placeholder='Code Message'
                />
              </div>
              <div className='form-group'>
                <label
                  htmlFor='exampleFormControlTextarea1'
                  style={{ color: "#43425D" }}
                >
                  Details
                  <span className='text-danger'>*</span>
                </label>
                <textarea
                  name='details'
                  value={addTrigger.details}
                  className='form-control'
                  style={{ borderRadius: "8px" }}
                  onChange={addTriggerInputsChange}
                  placeholder='Details'
                />
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type='submit'
              form='edit-trigger'
              style={{
                width: "200px",
                backgroundColor: "#43425D",
                borderRadius: "8px",
              }}
              className='btn text-white'
            >
              Update Trigger
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default Triggers;
